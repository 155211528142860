import React from 'react';
// import ReactDOM from 'react-dom'
// import mediumZoom from 'medium-zoom';
import {shirt_data} from '../data/shirt_data';
import {collar_data} from '../data/collar_data';
import {placket_data} from '../data/placket_data';
import {sleeve_data} from '../data/sleeve_data';
import {default_shirt_data} from '../data/default_shirt_data';
import {base_shirt_data} from '../data/base_shirt_data';
import {pocket_data} from '../data/pocket_data';
// import texture from '../texture'
import Shirt from './design/Shirt';
import Collar from './design/Collar';
import Placket from './design/Placket';
import Sleeve from './design/Sleeve';
import Pocket from './design/Pocket';
import Texture from './design/Texture';
// import ClipArt from './design/ClipArt'
import UploadImage from './design/UploadImage'
import {SketchPicker} from 'react-color';
// import {Modal} from 'react-spaceship-web';

export default class ShirtDesign extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dev_mode: window.Global.getAllQueryStrings().dev_mode || false,
			font_family: [
				{text: 'Kanit', value: 'Kanit'},
				{text: 'Sriracha', value: 'Sriracha'},
				{text: 'Sarabun', value: 'Sarabun'},
				{text: 'Srisakdi', value: 'Srisakdi'},
			],
			confirm_canvas: false,
			page_component: {...props.page_component},
			before_page_component: {...props.page_component},
			selected: {
				shirt_side: 'front',
				product_type_id: 1,
				texture_id: 1,
				text: [],
				upload_images: [],
				customization: {
					base_color: 1,
					shirt_style: {
						shirt_style_id: "default",
						type: [{type_id: "default", color_id: 1, selected: true}],
					},
					collar_style: {
						collar_style_id: false,
						type: []
					},
					placket_style: {
						placket_style_id: false,
						type: []
					},
					sleeve_style: {
						sleeve_style_id: false,
						type: []
					},
					pocket_style: {
						pocket_style_id: false,
						type: []
					}
				},
			},
			edit_mode: 'product',
			edit_product_mode: 'shirt',// shirt,collar,bottom_collar,sleeve,pocket || false
			product_type: [
				{
					id: 1,
					type: 'polo',
					text: 'POLO',
					url: './images/home_product_polo.png',
					texture: [],
					color: [
						// {id: 1, color: '#ffffff'},
						// {id: 2, color: '#fffc00'},
						// {id: 3, color: '#f2470f'},
						// {id: 4, color: '#d20900'},
						// {id: 5, color: '#f54b98'},
						// {id: 6, color: '#000000'}
					],
					base_shirt: base_shirt_data,
					shirt_style: shirt_data,
					collar_style: collar_data,
					placket_style: placket_data,
					sleeve_style: sleeve_data,
					pocket_style: pocket_data,
				},
			],

		}

		this.ShirtDesignView = React.createRef();
	}

	componentDidMount() {
		window.Global.ajax({
			method: 'GET',
			url: process.env.REACT_APP_DOMAIN + '/api/v1/fabric_texture',
			data: {},
			done: (response) => {
				if (response.fabric_texture) {
					response.fabric_texture.forEach((texture, texture_i) => {
						texture.fabric_texture_colors.forEach((fabric_texture_colors, fabric_texture_colors_i) => {
							response.fabric_texture[texture_i].fabric_texture_colors[fabric_texture_colors_i] = {
								...fabric_texture_colors,
								is_delete: 0
							};
						});
					});
				}
				let _selected = {...this.state.selected};
				_selected.texture_id = response.fabric_texture[0].id;
				_selected.customization.shirt_style.type[0].color = response.fabric_texture[0].fabric_texture_colors[0];
				_selected.customization.shirt_style.type[0].color_id = response.fabric_texture[0].fabric_texture_colors[0].id;
				let _product_type = [...this.state.product_type];
				_product_type[0].texture = response.fabric_texture;
				_product_type[0].color = response.fabric_texture[0].fabric_texture_colors;
				this.setState({
					product_type: _product_type,
					selected: _selected,
				})
			},
			fail: () => {

			}
		});
	}

	productSelected() {
		let product = this.state.product_type.filter((product_type) => {
			return product_type.id === this.state.selected.product_type_id
		});
		return product[0];
	}

	// textureSelected() {
	// 	let texture = this.productSelected().texture.filter((_texture) => {
	// 		return _texture.id === this.state.selected.texture_id
	// 	});
	// 	return texture[0];
	// }
	setUpUploadFile = () => {
		window.$(this.$parent).find('form').form2('setup');
	};

	selectedTextIndex() {
		let selected_index = undefined;
		this.state.selected.text.forEach((text, text_i) => {
			if (text.selected === 1) {
				selected_index = text_i;
			}
		});
		return selected_index;
	}

	reportSelectedStyle() {
		let shirt_style = false;
		if (this.state.selected.customization.shirt_style.type.length > 1) {
			shirt_style = this.state.selected.customization.shirt_style.shirt_style_title;
		}
		let collar_style = false;
		if (this.state.selected.customization.collar_style.type.length > 0) {
			collar_style = this.state.selected.customization.collar_style.collar_style_title;
		}
		let placket_style = false;
		if (this.state.selected.customization.placket_style.type.length > 0) {
			placket_style = this.state.selected.customization.placket_style.placket_style_title;
		}
		let sleeve_style = false;
		if (this.state.selected.customization.sleeve_style.type.length > 0) {
			sleeve_style = this.state.selected.customization.sleeve_style.sleeve_style_title;
		}
		if (shirt_style || collar_style || placket_style || sleeve_style) {
			return (
				<div style={{marginTop: '1em'}}>
					<h3 className="ui header" style={{marginBottom: 10}}>
						รูปแบบที่เลือก
					</h3>
					<ul style={{margin: 0}} className={"custom-ul"}>
						{
							shirt_style ?
								<li>แบบเสื้อ : {shirt_style}</li>
								: null
						}
						{
							collar_style ?
								<li>ปกเสื้อ : {collar_style}</li>
								: null
						}
						{
							placket_style ?
								<li>สาบเสื้อ : {placket_style}</li>
								: null
						}
						{
							sleeve_style ?
								<li>แขนเสื้อ : {sleeve_style}</li>
								: null
						}
					</ul>
				</div>
			);
		}
		return false;
	}

	responsiveMenuTitle() {
		if (this.state.edit_mode === 'product' && !this.state.edit_product_mode) {
			return "ปรับแต่งแบบเสื้อ";
		}
		if (this.state.edit_mode === 'product' && this.state.edit_product_mode === 'shirt') {
			return "แบบเสื้อ";
		}
		if (this.state.edit_mode === 'product' && this.state.edit_product_mode === 'collar') {
			return "ปกเสื้อ";
		}
		if (this.state.edit_mode === 'product' && this.state.edit_product_mode === 'placket') {
			return "สาบเสื้อ";
		}
		if (this.state.edit_mode === 'product' && this.state.edit_product_mode === 'sleeve') {
			return "แขนเสื้อ";
		}
		if (this.state.edit_mode === 'texture') {
			return "เนื้อผ้า";
		}
		if (this.state.edit_mode === 'logo') {
			return "เพิ่มรูป";
		}
		if (this.state.edit_mode === 'text') {
			return "เพิ่มข้อความ";
		}
	}

	renderView() {
		let slide_settings = {
			dots: false,
			infinite: false,
			speed: 300,
			slidesToShow: 4,
			slidesToScroll: 4,
			arrows: true,
			responsive: [
				{
					breakpoint: 1030,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						infinite: false,
						dots: true
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						infinite: false,
						dots: true
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: false,
						dots: true
					}
				}
			]
		};

		return (
			<>
				{
					!this.state.confirm_canvas ?
						<div className={"flex row two column tablet two column computer wrap resp-flex-reverse"}
						     style={{paddingTop: '15px'}}>
							<ShirtDesignView
								ref={this.ShirtDesignView}
								selected={this.state.selected}
								edit_mode={this.state.edit_mode}
								product={this.productSelected()}
								onChange={(selected) => {
									this.setState({
										selected: selected
									});
								}}
								clearTextSelect={() => {
									let selected = {...this.state.selected};
									this.state.selected.text.forEach((text, text_i) => {
										if (text.selected === 1) {
											text.selected = 0;
											selected.text[text_i] = text;
										}
									});
									this.setState({
										selected: selected
									})
								}}
								clearImageSelect={(index) => {
									let selected = {...this.state.selected};
									selected.upload_images = this.state.selected.upload_images.filter((upload_image, upload_images_i) => {
										return upload_images_i !== index;
									});
									this.setState({
										selected: selected
									})
								}}
								selectedText={(index) => {
									let selected = {...this.state.selected};
									this.state.selected.text.forEach((text, text_i) => {
										if (text.selected === 1) {
											text.selected = 0;
											selected.text[text_i] = text;
										}
									});
									selected.text[index].selected = 1;
									this.setState({
										edit_mode: 'text',
										selected: selected
									})
								}}
								removeText={(index) => {
									let selected = {...this.state.selected};
									selected.text.splice(index, 1);
									this.setState({
										edit_mode: 'text',
										selected: selected
									});
								}}
								removeLogo={(index) => {
									let selected = {...this.state.selected};
									selected.upload_images.splice(index, 1);
									this.setState({
										selected: selected
									});
								}}
								onConfirm={(canvas) => {
									this.setState({
										confirm_canvas: canvas
									})
								}}
							/>
							<div className="ui form custom-ui field column">
								<div className="field">
									<h2 className={"ui header dividing resp-hide"}>
										{this.responsiveMenuTitle()}
									</h2>
									<button
										className={"ui button fluid resp-show"}
										style={{marginBottom: 15, position: 'relative'}}
										type="button"
										onClick={() => {
											this.mainDesignMenu.addClass('active');
										}}
									>
										{this.responsiveMenuTitle()}
										<div style={{
											position: 'absolute',
											right: 10,
											top: 0,
											height: '100%',
											justifyContent: 'center',
											alignItems: 'center',
											display: 'flex'
										}}>
											<i className={"chevron down circle icon"}
											   style={{fontSize: 14, marginRight: 0}}/>
										</div>
									</button>
									<div className="buttons ui basic fluid four column wrap resp-design-menu"
									     ref={(ref) => {
										     this.mainDesignMenu = window.$(ref)
									     }}
									>
										<button
											className={this.state.edit_mode === 'product' ? "ui button active" : "ui button"}
											type="button"
											onClick={() => {
												this.setState({
													edit_mode: 'product',
													edit_product_mode: 'shirt'
												}, () => {
													this.designMenu.removeClass('active');
													this.mainDesignMenu.removeClass('active');
												})
											}}
										>
											ปรับแต่ง
										</button>
										<button
											className={this.state.edit_mode === 'texture' ? "ui button active" : "ui button"}
											type="button"
											onClick={() => {
												this.setState({
													edit_mode: 'texture',
												}, () => {
													this.designMenu.removeClass('active');
													this.mainDesignMenu.removeClass('active');
												})
											}}
										>
											เนื้อผ้า
										</button>
										<button
											className={this.state.edit_mode === 'logo' ? "ui button active" : "ui button"}
											type="button"
											onClick={() => {
												this.setState({
													edit_mode: 'logo',
													edit_product_mode: 'image_upload'
												}, () => {
													this.designMenu.removeClass('active');
													this.mainDesignMenu.removeClass('active');
													this.setUpUploadFile()
												});
											}}
										>
											เพิ่มรูป
										</button>
										<button
											className={this.state.edit_mode === 'text' ? "ui button active" : "ui button"}
											type="button"
											onClick={() => {
												this.setState({
													edit_mode: 'text',
													edit_product_mode: false
												}, () => {
													this.designMenu.removeClass('active');
													this.mainDesignMenu.removeClass('active');
												});
											}}
										>
											เพิ่มข้อความ
										</button>
									</div>

								</div>
								<div className={"field"}>
									{
										this.state.edit_mode === 'product' ?
											<div className="buttons ui basic fluid five column resp-button"
											     ref={(ref) => {
												     this.designMenu = window.$(ref)
											     }}
											>
												<button
													className={this.state.edit_product_mode === 'shirt' ? "ui button active" : "ui button"}
													type="button"
													onClick={() => {
														this.setState({
															edit_mode: 'product',
															edit_product_mode: 'shirt'
														}, () => {
															this.designMenu.removeClass('active');
														})
													}}
												>
													แบบเสื้อ
												</button>
												<button
													className={this.state.edit_product_mode === 'collar' ? "ui button active" : "ui button"}
													type="button"
													onClick={() => {
														this.setState({
															edit_mode: 'product',
															edit_product_mode: 'collar'
														}, () => {
															this.designMenu.removeClass('active');
														})
													}}
												>
													ปกเสื้อ
												</button>
												<button
													className={this.state.edit_product_mode === 'placket' ? "ui button active" : "ui button"}
													type="button"
													onClick={() => {
														this.setState({
															edit_mode: 'product',
															edit_product_mode: 'placket'
														}, () => {
															this.designMenu.removeClass('active');
														})
													}}
												>
													สาบเสื้อ
												</button>
												<button
													className={this.state.edit_product_mode === 'sleeve' ? "ui button active" : "ui button"}
													type="button"
													onClick={() => {
														this.setState({
															edit_mode: 'product',
															edit_product_mode: 'sleeve'
														}, () => {
															this.designMenu.removeClass('active');
														})
													}}
												>
													แขนเสื้อ
												</button>
												<button
													className={this.state.edit_product_mode === 'pocket' ? "ui button active" : "ui button"}
													type="button"
													onClick={() => {
														this.setState({
															edit_mode: 'product',
															edit_product_mode: 'pocket'
														}, () => {
															this.designMenu.removeClass('active');
														})
													}}
												>
													กระเป๋าเสื้อ
												</button>
											</div>
											: null
									}
								</div>
								{
									this.state.edit_mode === 'product' ?

										<>
											{
												this.state.edit_product_mode === 'shirt' ?
													<Shirt product={this.productSelected()}
													       slide_settings={slide_settings}
													       selected={this.state.selected}
													       onUpdateSelected={(selected) => {
														       this.setState({
															       selected: selected
														       })
													       }}
													/>
													: null
											}
											{
												this.state.edit_product_mode === 'collar' ?
													<Collar product={this.productSelected()}
													        slide_settings={slide_settings}
													        selected={this.state.selected}
													        onUpdateSelected={(selected) => {
														        this.setState({
															        selected: selected
														        })
													        }}
													/>
													: null
											}
											{
												this.state.edit_product_mode === 'placket' ?
													<Placket product={this.productSelected()}
													         slide_settings={slide_settings}
													         selected={this.state.selected}
													         onUpdateSelected={(selected) => {
														         this.setState({
															         selected: selected
														         })
													         }}
													/>
													: null
											}
											{
												this.state.edit_product_mode === 'sleeve' ?
													<Sleeve product={this.productSelected()}
													        slide_settings={slide_settings}
													        selected={this.state.selected}
													        onUpdateSelected={(selected) => {
														        this.setState({
															        selected: selected
														        })
													        }}
													/>
													: null
											}
											{
												this.state.edit_product_mode === 'pocket' ?
													<Pocket product={this.productSelected()}
													        slide_settings={slide_settings}
													        selected={this.state.selected}
													        onUpdateSelected={(selected) => {
														        this.setState({
															        selected: selected
														        })
													        }}
													/>
													: null
											}
										</>
										: null
								}
								{
									this.state.edit_mode === 'texture' ?
										<>
											<Texture
												dev_mode={this.state.dev_mode}
												texture={this.state.product_type[0].texture}
												selected={this.state.selected}
												product_type={this.state.product_type}
												onUpdateSelected={(selected, product_type) => {
													this.setState({
														product_type: product_type,
														selected: selected
													})
												}}
											/>
										</>
										: null
								}
								{
									this.state.edit_mode === 'logo' ?
										<>
											{
												this.state.edit_product_mode === 'image_upload' ?
													<UploadImage selected={this.state.selected}
													             onUpdateSelected={(selected) => {
														             this.setState({
															             selected: selected
														             })
													             }}/>
													: null
											}

										</>
										: null
								}
								{
									this.state.edit_mode === 'text' ?
										<>
											<div className="field">
												<label>
													พิมพ์ข้อความด้านล่าง :
												</label>
												<textarea name="text"
												          id="text"
												          cols="30"
												          rows="2"
												          placeholder={"เพิ่มข้อความ"}
												          value={this.selectedTextIndex() !== undefined ? this.state.selected.text[this.selectedTextIndex()].description : ''}
												          onChange={(e) => {
													          let selected = {...this.state.selected};
													          if (this.selectedTextIndex() === undefined) {
														          selected.text.push({
															          description: e.target.value,
															          text_color: '#000',
															          font_family: "Sriracha",
															          text_shadow_color: '#000',
															          text_shadow_size: false,
															          selected: 1
														          })
													          }
													          if (this.selectedTextIndex() !== undefined) {
														          selected.text[this.selectedTextIndex()].description = e.target.value;
														          selected.text[this.selectedTextIndex()].shirt_side = this.state.selected.shirt_side;
													          }
													          this.setState({
														          selected: selected,
													          })
												          }}
												/>
											</div>
											{
												this.selectedTextIndex() !== undefined
												&& this.state.selected.text[this.selectedTextIndex()].description ?
													<>
														<div className="fields two">
															<div className="field">
																<label>
																	เลือกแบบอักษร :
																</label>
																<div className="ui left action input input-color">
																	<div
																		className="ui icon top left pointing dropdown button basic"
																		ref={(ref) => {
																			this.textColor = window.$(ref);
																			this.textColor.dropdown({
																				onChange: (value, text, $selectedItem) => {
																					this.textColor.popup('show')
																				}
																			});
																		}}
																	>
																		<i className="square icon"
																		   style={this.state.selected.text[this.selectedTextIndex()] !== undefined ?
																			   {color: this.state.selected.text[this.selectedTextIndex()].text_color}
																			   :
																			   {color: '#000000'}}
																		/>
																		<div className="menu">
																			<div className="header" id={"color-picker"}
																			     style={{
																				     padding: '0px !important',
																				     border: 0
																			     }}
																			>
																				<SketchPicker
																					color={this.state.selected.text[this.selectedTextIndex()] !== undefined ? this.state.selected.text[this.selectedTextIndex()].text_color : '#000000'}
																					onChangeComplete={(color) => {
																						let selected = {...this.state.selected};
																						if (this.selectedTextIndex() !== undefined) {
																							selected.text[this.selectedTextIndex()].text_color = color.hex;
																						}
																						this.setState({
																							selected: selected
																						}, () => {
																							this.textColor.dropdown('hide');
																						})
																					}}
																				/>
																			</div>
																			<div className="item"
																			     style={{
																				     padding: '0px !important',
																				     border: 0
																			     }}
																			     id={"text-color-menu"}>
																				<button
																					className={"ui button blue fluid"}
																					type={"button"}>ปิด
																				</button>
																			</div>
																		</div>
																	</div>
																	<select
																		ref={(ref) => {
																			window.$(ref).dropdown()
																		}}
																		className={"ui dropdown fluid"}
																		onChange={(e) => {
																			let selected = {...this.state.selected};
																			if (this.selectedTextIndex() !== undefined) {
																				selected.text[this.selectedTextIndex()].font_family = e.target.value;
																			}
																			this.setState({
																				selected: selected
																			})
																		}}
																		value={this.state.selected.text[this.selectedTextIndex()].font_family}
																	>
																		{
																			this.state.font_family.map((font_family, font_family_i) => {
																				return (
																					<option value={font_family.value}
																					        selected={this.state.selected.text[this.selectedTextIndex()] && this.state.selected.text[this.selectedTextIndex()].font_family === font_family.value}
																					>
																						{font_family.text}
																					</option>
																				);
																			})
																		}
																	</select>
																</div>
															</div>
															<div className="field">
																<label>
																	สีและขอบ :
																</label>
																<div className="ui left action input input-color">
																	<div
																		className="ui icon top left pointing dropdown button basic"
																		ref={(ref) => {
																			this.borderColor = window.$(ref);
																			this.borderColor.dropdown({
																				onChange: (value, text, $selectedItem) => {
																					this.borderColor.popup('show')
																				}
																			});
																		}}
																	>
																		<i className="square icon"
																		   style={this.state.selected.text[this.selectedTextIndex()] !== undefined ?
																			   {color: this.state.selected.text[this.selectedTextIndex()].text_shadow_color}
																			   :
																			   {color: '#000000'}}
																		/>
																		<div className="menu">
																			<div className="header" id={"color-picker"}
																			     style={{
																				     padding: '0px !important',
																				     border: 0
																			     }}
																			>
																				<SketchPicker
																					color={this.state.selected.text[this.selectedTextIndex()] !== undefined ? this.state.selected.text[this.selectedTextIndex()].text_shadow_color : '#000000'}
																					onChangeComplete={(color) => {
																						let selected = {...this.state.selected};
																						if (this.selectedTextIndex() !== undefined) {
																							selected.text[this.selectedTextIndex()].text_shadow_color = color.hex;
																						}
																						this.setState({
																							selected: selected
																						}, () => {
																							this.borderColor.dropdown('hide');
																						})
																					}}
																				/>
																			</div>
																			<div className="item"
																			     style={{
																				     padding: '0px !important',
																				     border: 0
																			     }}
																			     id={"text-color-menu"}>
																				<button
																					className={"ui button blue fluid"}
																					type={"button"}>ปิด
																				</button>
																			</div>
																		</div>
																	</div>
																	<select
																		ref={(ref) => {
																			window.$(ref).dropdown()
																		}}
																		className={"ui dropdown fluid"}
																		onChange={(e) => {
																			let selected = {...this.state.selected};
																			if (this.selectedTextIndex() !== undefined) {
																				selected.text[this.selectedTextIndex()].text_shadow_size = e.target.value;
																			}
																			this.setState({
																				selected: selected
																			})
																		}}
																	>
																		<option value={false}
																		        selected={this.state.selected.text[this.selectedTextIndex()] && this.state.selected.text[this.selectedTextIndex()].text_shadow_size === false}>ไม่มีขอบ
																		</option>
																		<option value={"1"}
																		        selected={this.state.selected.text[this.selectedTextIndex()] && this.state.selected.text[this.selectedTextIndex()].text_shadow_size === "1"}>ขอบบาง
																		</option>
																		<option value={"2"}
																		        selected={this.state.selected.text[this.selectedTextIndex()] && this.state.selected.text[this.selectedTextIndex()].text_shadow_size === "2"}>ขอบหนาปานกลาง
																		</option>
																		<option value={"3"}
																		        selected={this.state.selected.text[this.selectedTextIndex()] && this.state.selected.text[this.selectedTextIndex()].text_shadow_size === "3"}>ขอบหนา
																		</option>
																	</select>
																</div>
															</div>
														</div>
														<button className={"ui button basic"}
														        type={"button"}
														        onClick={() => {
															        this.ShirtDesignView.current.setOcjectCenterClock();
														        }}
														>
															จัดให้อยู่ตรงกลางกลาง
														</button>
													</>
													: null
											}

										</>
										: null
								}
								{
									!this.state.dev_mode ?
										<>
											<div className={"ui divider"}/>
											{
												this.reportSelectedStyle()
											}
											<div className={"flex row wrap"} style={{marginTop: '1em'}}>
												<div className={"right floated"}>
													<button className={"ui button green"}
													        type={"button"}
													        onClick={() => {
														        this.ShirtDesignView.current.confirmDesign();
													        }}
													>
														<i className="shopping basket icon"/> สั่งซื้อ
													</button>
												</div>
											</div>
										</>
										: null
								}
							</div>
						</div>
						: null
				}

				{
					this.state.confirm_canvas ?
						<ShirtDesignConfirmOrder
							canvas={this.state.confirm_canvas}
							selectedStyle={this.reportSelectedStyle()}
							onCancel={() => {
								this.setState({
									confirm_canvas: false,
								})
							}}
						/>
						: null
				}
			</>
		);
	}

	render() {
		if (this.props.mode === 'view') {
			return this.renderView();
		}

		return (
			<>
				<div className="edit-title flex row middle-xs">
					<div>แก้ไข ShirtDesign</div>
					<div className="right floated">
						<button className={"ui button mini"}
						        onClick={() => {
							        let _confirm = window.confirm("ยืนยันการลบข้อมูล");
							        if (_confirm) {
								        let page_component = {...this.state.before_page_component};
								        page_component.mode = 'card';
								        this.props.onChange(page_component);
								        this.props.removePageComponent();
								        return true;
							        }
							        return false
						        }}
						>
							ลบ
						</button>
					</div>
				</div>
				<div className="padded" ref={(ref) => {
					this.$parent = ref;
				}}>
					<form className="ui form">
						<div className="field" style={{textAlign: 'right'}}>
							<button className="ui button red"
							        type="button"
							        onClick={() => {
								        let page_component = {...this.state.before_page_component};
								        page_component.mode = 'card';
								        this.props.onChange(page_component);
								        this.props.showForm();
							        }}
							>
								ยกเลิก
							</button>
							<button className="ui button blue"
							        type="button"
							        onClick={() => {
								        let page_component = {...this.state.page_component};
								        page_component.mode = 'card';
								        this.props.onChange(page_component);
								        this.props.showForm();
							        }}
							>
								บันทึก
							</button>
						</div>
					</form>
				</div>
			</>
		);
	}
}

class ShirtDesignView extends React.Component {
	constructor() {
		super();
		this.state = {
			canvas_height: 600,
			canvas_width: 500,
			polygon_opacity: 0.8,
			options: {
				left: 122,
				top: 100,
				width: 260,
				height: 450,
			}
		}
	}

	componentDidMount() {
		this.CanvasRender();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		console.log(this.props.selected);
		this.renderUpdateFullCanvas(this.__canvas_front, 'front');
		this.renderUpdateFullCanvas(this.__canvas_back, 'back');
		this.renderUpdateFullCanvas(this.__canvas_left, 'left');
		this.renderUpdateFullCanvas(this.__canvas_right, 'right');
		// ภ้าด้านของเลื้อ เหมือนเดิม (เสื้อด้านหน้า / หลัง / ซ้าย / ขวา)
		if (this.props.selected.shirt_side === 'front') {
			this.updateLogo(this.__canvas_front, 'front');
			this.updateText(this.__canvas_front, 'front');
		}
		if (this.props.selected.shirt_side === 'back') {
			this.updateLogo(this.__canvas_back, 'back');
			this.updateText(this.__canvas_back, 'back');
		}
		if (this.props.selected.shirt_side === 'left') {
			this.updateLogo(this.__canvas_left, 'left');
			this.updateText(this.__canvas_left, 'left');
		}
		if (this.props.selected.shirt_side === 'right') {
			this.updateLogo(this.__canvas_right, 'right');
			this.updateText(this.__canvas_right, 'right');
		}


	}


	CanvasRender() {
		this.__canvas_front = new window.fabric.Canvas(window.$('#front').get(0), {
			preserveObjectStacking: true,
		});
		new window.fabric.Image.fromURL('./images/lannapolo_shirt/front.png', (img) => {
			img.set({
				top: 0,
				left: 0,
				cacheKey: 'default',
				draggable: false,
				lockMovementY: true,
				lockRotation: true,
				lockMovementX: true,
				lockScalingX: true,
				lockScalingY: true,
				lockSkewingX: true,
				lockSkewingY: true,
				lockUniScaling: true,
				selectable: false,
				evented: false,
				transparentCorners: false,
				preserveObjectStacking: true
			});
			img.scaleToHeight(this.state.canvas_height);
			img.scaleToWidth(this.state.canvas_width);
			// this.__canvas_front.backgroundImage = img;
			this.addCanvas(this.__canvas_front, img, {send_to_back: true});
		});

		this.__canvas_back = new window.fabric.Canvas(window.$('#back').get(0), {
			preserveObjectStacking: true,
		});
		new window.fabric.Image.fromURL('./images/lannapolo_shirt/back.png', (img) => {
			img.set({
				top: 0,
				left: 0,
				cacheKey: 'default',
				draggable: false,
				lockMovementY: true,
				lockRotation: true,
				lockMovementX: true,
				lockScalingX: true,
				lockScalingY: true,
				lockSkewingX: true,
				lockSkewingY: true,
				lockUniScaling: true,
				selectable: false,
				evented: false,
				transparentCorners: false,
				preserveObjectStacking: true
			});
			img.scaleToHeight(this.state.canvas_height);
			img.scaleToWidth(this.state.canvas_width);
			// this.__canvas_back.backgroundImage = img;
			this.addCanvas(this.__canvas_back, img, {send_to_back: true});
		});


		this.__canvas_left = new window.fabric.Canvas(window.$('#left').get(0), {
			preserveObjectStacking: true,
		});
		new window.fabric.Image.fromURL('./images/lannapolo_shirt/left.png', (img) => {
			img.set({
				top: 0,
				left: 0,
				cacheKey: 'default',
				draggable: false,
				lockMovementY: true,
				lockRotation: true,
				lockMovementX: true,
				lockScalingX: true,
				lockScalingY: true,
				lockSkewingX: true,
				lockSkewingY: true,
				lockUniScaling: true,
				selectable: false,
				evented: false,
				transparentCorners: false,
				preserveObjectStacking: true
			});
			img.scaleToHeight(this.state.canvas_height);
			img.scaleToWidth(this.state.canvas_width);
			// this.__canvas_back.backgroundImage = img;
			this.addCanvas(this.__canvas_left, img, {send_to_back: true});
		});
		this.__canvas_right = new window.fabric.Canvas(window.$('#right').get(0), {
			preserveObjectStacking: true,
		});
		new window.fabric.Image.fromURL('./images/lannapolo_shirt/right.png', (img) => {
			img.set({
				top: 0,
				left: 0,
				cacheKey: 'default',
				draggable: false,
				lockMovementY: true,
				lockRotation: true,
				lockMovementX: true,
				lockScalingX: true,
				lockScalingY: true,
				lockSkewingX: true,
				lockSkewingY: true,
				lockUniScaling: true,
				selectable: false,
				evented: false,
				transparentCorners: false,
				preserveObjectStacking: true
			});
			img.scaleToHeight(this.state.canvas_height);
			img.scaleToWidth(this.state.canvas_width);
			// this.__canvas_back.backgroundImage = img;
			this.addCanvas(this.__canvas_right, img, {send_to_back: true});
		});
		this.renderUpdateFullCanvas(this.__canvas_front, 'front');
		this.renderUpdateFullCanvas(this.__canvas_back, 'back');
		this.renderUpdateFullCanvas(this.__canvas_left, 'left');
		this.renderUpdateFullCanvas(this.__canvas_right, 'right');
		// ภ้าด้านของเลื้อ เหมือนเดิม (เสื้อด้านหน้า / หลัง / ซ้าย / ขวา)
		this.addText(this.__canvas_front, 'front');
		this.addText(this.__canvas_back, 'back');
		this.addText(this.__canvas_left, 'left');
		this.addText(this.__canvas_right, 'right');

		this.addImages(this.__canvas_front, 'front');
		this.addImages(this.__canvas_back, 'back');
		this.addImages(this.__canvas_left, 'left');
		this.addImages(this.__canvas_right, 'right');

		this.optionSelection(this.__canvas_front, 'front');
		this.optionSelection(this.__canvas_back, 'back');
		this.optionSelection(this.__canvas_left, 'left');
		this.optionSelection(this.__canvas_right, 'right');
		let window_width = window.$(window).width();
		if (window_width > 767 && window_width < 1025) {
			this.__canvas_front.setZoom(0.85);
			this.__canvas_back.setZoom(0.85);
			this.__canvas_left.setZoom(0.85);
			this.__canvas_right.setZoom(0.85);
		}
		if (window_width < 767) {
			this.__canvas_front.setZoom(0.6);
			this.__canvas_back.setZoom(0.6);
			this.__canvas_left.setZoom(0.6);
			this.__canvas_right.setZoom(0.6);
		}
	}

	createMainShirtPolygon(canvas, points) {
		canvas.getObjects().forEach((obj) => {
			if (obj.cacheKey && obj.cacheKey.indexOf('default_polygon') !== -1) {
				canvas.remove(obj);
			}
		});
		let shirt_color = '#ffffff';
		if (this.props.selected.customization.shirt_style.type[0].color) {
			shirt_color = this.props.selected.customization.shirt_style.type[0].color.hex_code;
		}
		this.addCanvas(canvas, this.createPolygonFormPoint(points.point,
			{
				stroke: '#333333',
				strokeWidth: 0,
				fill: shirt_color,
				opacity: this.state.polygon_opacity,
				hasBorders: false,
				hasControls: false,
				top: points.top,
				left: points.left,
				cacheKey: 'default_polygon',
				draggable: false,
				lockMovementY: true,
				lockRotation: true,
				lockMovementX: true,
				lockScalingX: true,
				lockScalingY: true,
				lockSkewingX: true,
				lockSkewingY: true,
				lockUniScaling: true,
				selectable: false,
				evented: false,
			}), {send_to_back: true});
		canvas.getObjects().forEach((obj) => {
			if (obj.cacheKey && obj.cacheKey === 'default') {
				canvas.sendToBack(obj);
			}
		});
	}

	_setScalingProperties(left, top, scale) {
		if (this.scalingProperties == null
			|| this.scalingProperties['scale'] > scale) {
			this.scalingProperties = {
				'left': left,
				'top': top,
				'scale': scale
			};
		}
		this.setState({
			scalingProperties: this.scalingProperties,
		})
	}

	createPolygonFormPoint(points, options) {
		return new window.fabric.Polygon(points, options);
	}

	addCanvas(canvas, object, options) {
		canvas.add(object);
		if (options && options.active_object) {
			canvas.setActiveObject(object);
		}
		if (options && options.send_to_back) {
			canvas.sendToBack(object);
		}
		if (options && options.bring_to_front) {
			canvas.bringToFront(object);
		}
		if(options && options.onSuccess){
			options.onSuccess(canvas.getActiveObject());
		}
		// console.log(this.__canvas_front.getObjects());
	}

	defaultCanvasRender(canvas, shirt_side) {
		let points = default_shirt_data[shirt_side];
		this.createMainShirtPolygon(canvas, points);
	}

	setOcjectCenterClock() {
		if (this.props.selected.shirt_side === 'front') {
			this.setOcjectCenter(this.__canvas_front, 'front');
		}
		if (this.props.selected.shirt_side === 'back') {
			this.setOcjectCenter(this.__canvas_back, 'back');
		}
		if (this.props.selected.shirt_side === 'left') {
			this.setOcjectCenter(this.__canvas_left, 'left');
		}
		if (this.props.selected.shirt_side === 'right') {
			this.setOcjectCenter(this.__canvas_right, 'right');
		}
	}

	setOcjectCenter(canvas, shirt_side) {
		let options = {...this.state.options};
		if (shirt_side === 'left') {
			options.left = 140;
			options.top = 120;
			options.width = 75;
			options.height = 100;
		}
		if (shirt_side === 'right') {
			options.left = 125;
			options.top = 120;
			options.width = 75;
			options.height = 100;
		}
		let obj = canvas.getActiveObject();

		let objTop = options.top + (options.height / 2) - ((obj.height * obj.scaleY) / 2);
		let objLeft = (options.left + (options.width / 2)) - ((obj.width * obj.scaleX) / 2);
		obj.set({top: objTop, left: objLeft});

		let obj_selected_index = obj.cacheKey.replace('text_' + this.props.selected.shirt_side + '_index_', '');
		let __selected = {...this.props.selected};
		__selected.text[obj_selected_index].top = obj.top || 0;
		__selected.text[obj_selected_index].left = obj.left || 0;
		__selected.text[obj_selected_index].scaleX = obj.scaleX || 1;
		__selected.text[obj_selected_index].scaleY = obj.scaleY || 1;
		__selected.text[obj_selected_index].angle = obj.angle || 0;
		this.props.onChange(__selected);

		canvas.requestRenderAll();
	}

	createFakeBox(canvas, shirt_side) {
		let options = {...this.state.options};
		if (shirt_side === 'left') {
			options.left = 210;
			options.top = 140;
			options.width = 100;
			options.height = 130;
		}
		if (shirt_side === 'right') {
			options.left = 167;
			options.top = 140;
			options.width = 100;
			options.height = 130;
		}
		let rect = new window.fabric.Rect({
			angel: 15,
			left: options.left,
			top: options.top,
			fill: 'rgba(0,0,0,0)',
			cacheKey: 'fake_box',
			width: options.width,
			height: options.height,
			objectCaching: false,
			stroke: 'rgba(0,0,0,0.3)',
			strokeWidth: 2,
			draggable: false,
			lockMovementY: true,
			lockRotation: true,
			lockMovementX: true,
			lockScalingX: true,
			lockScalingY: true,
			lockSkewingX: true,
			lockSkewingY: true,
			lockUniScaling: true,
			selectable: false,
			evented: false,
			transparentCorners: false,
			preserveObjectStacking: true
		});
		if (rect) {
			canvas.add(rect);
		}
		return true;
	}

	clearFakeBox(canvas) {
		canvas.getObjects().forEach((obj, obj_i) => {
			if (obj.cacheKey && obj.cacheKey === 'fake_box') {
				canvas.remove(obj);
			}
		});
	}

	optionSelection(canvas, shirt_side) {
		canvas.off('mouse:up').on('mouse:up', (e) => {
			let obj = e.target;
			// อัพเดทข้อมูล Text
			if (canvas.getActiveObject() && obj && obj.cacheKey && obj.cacheKey === canvas.getActiveObject().cacheKey) {
				canvas.discardActiveObject();
				canvas.setActiveObject(obj);
			}
			let __selected = {...this.props.selected};
			if (canvas.getActiveObject() && obj && obj.cacheKey.indexOf('text_' + this.props.selected.shirt_side) !== -1) {
				let obj_selected_index = obj.cacheKey.replace('text_' + this.props.selected.shirt_side + '_index_', '');
				__selected.text[obj_selected_index].top = obj.top || 0;
				__selected.text[obj_selected_index].left = obj.left || 0;
				__selected.text[obj_selected_index].scaleX = obj.scaleX || 1;
				__selected.text[obj_selected_index].scaleY = obj.scaleY || 1;
				__selected.text[obj_selected_index].angle = obj.angle || 0;
			}
			if (canvas.getActiveObject() && obj && obj.cacheKey.indexOf('logo_' + this.props.selected.shirt_side) !== -1) {
				let obj_selected_index = obj.cacheKey.replace('logo_' + this.props.selected.shirt_side + '_index_', '');
				__selected.upload_images[obj_selected_index].top = obj.top || 0;
				__selected.upload_images[obj_selected_index].left = obj.left || 0;
				__selected.upload_images[obj_selected_index].scaleX = obj.scaleX || 1;
				__selected.upload_images[obj_selected_index].scaleY = obj.scaleY || 1;
				__selected.upload_images[obj_selected_index].translateX = obj.translateX || 1;
				__selected.upload_images[obj_selected_index].translateY = obj.translateY || 1;
				__selected.upload_images[obj_selected_index].angle = obj.angle || 0;
				__selected.upload_images[obj_selected_index].angle = obj.height;
				__selected.upload_images[obj_selected_index].angle = obj.width;
			}

			this.props.onChange(__selected);
			return false;
		});
		canvas.off('selection:cleared').on('selection:cleared', (e) => {
			this.clearFakeBox(canvas);
			if (this.props.edit_mode === 'text') {
				this.props.clearTextSelect();
			}
		});
		canvas.off('selection:created').on('selection:created', (e) => {
			if (e.selected.length > 0) {
				canvas.setActiveObject(e.selected[0]);
			}
			if (e.target && e.target.cacheKey.indexOf("text_") !== -1) {
				let text_i = e.target.cacheKey.replace('text_' + this.props.selected.shirt_side + '_index_', '');
				this.props.selectedText(text_i);
			}

			this.createFakeBox(canvas, this.props.selected.shirt_side);
		});
		canvas.off('selection:updated').on('selection:updated', () => {
			canvas.renderAll();
			canvas.requestRenderAll();
		});
		canvas.on('object:moving', function (e) {
			let obj = e.target;
			// if object is too big ignore asdasd
			if (obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width) {
				return;
			}
			obj.setCoords();

			let maxTop = 102; //ระยะห่างจากขอบด้านบน
			let maxLeft = 124; //ระยะห่างจากขอบด้านซ้าย
			let maxBottom = 50; //ระยะห่างจากขอบด้านล่าง
			let maxRight = 118; //ระยะห่างจากขอบด้านขวา
			if (shirt_side === 'left') {
				maxTop = 142; //ระยะห่างจากขอบด้านบน
				maxLeft = 212; //ระยะห่างจากขอบด้านซ้าย
				maxBottom = 330; //ระยะห่างจากขอบด้านล่าง
				maxRight = 190; //ระยะห่างจากขอบด้านขวา
			}
			if (shirt_side === 'right') {
				maxTop = 142; //ระยะห่างจากขอบด้านบน
				maxLeft = 168; //ระยะห่างจากขอบด้านซ้าย
				maxBottom = 330; //ระยะห่างจากขอบด้านล่าง
				maxRight = 233; //ระยะห่างจากขอบด้านขวา
			}


			// console.log(obj.getBoundingRect());
			// top-left  corner
			if (obj.getBoundingRect().top < maxTop) {
				obj.top = maxTop;
			}
			if (obj.getBoundingRect().left < maxLeft) {
				obj.left = maxLeft;
			}
			if (obj.getBoundingRect().top > (obj.canvas.height - maxBottom) - obj.getBoundingRect().height) {
				obj.top = Math.min(obj.top, obj.canvas.height - obj.getBoundingRect().height + obj.top - obj.getBoundingRect().top - (maxBottom));
			}
			// bot-right corner
			if (obj.getBoundingRect().left > (obj.canvas.width - maxRight) - obj.getBoundingRect().width) {
				obj.left = Math.min(obj.left, obj.canvas.width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left - (maxRight));
			}
		});
		canvas.on('object:modified', (e) => {
			let obj = e.target;
			let brOld = obj.getBoundingRect();
			obj.setCoords();
			let brNew = obj.getBoundingRect();
			// left border
			// 1. compute the scale that sets obj.left equal 0
			// 2. compute height if the same scale is applied to Y (we do not allow non-uniform scaling)
			// 3. compute obj.top based on new height
			if (brOld.left >= 0 && brNew.left < 0) {
				let scale = (brOld.width + brOld.left) / obj.width;
				let height = obj.height * scale;
				let top = ((brNew.top - brOld.top) / (brNew.height - brOld.height) *
					(height - brOld.height)) + brOld.top;
				this._setScalingProperties(0, top, scale);
				// top border
				if (brOld.top >= 0 && brNew.top < 0) {
					let scale = (brOld.height + brOld.top) / obj.height;
					let width = obj.width * scale;
					let left = ((brNew.left - brOld.left) / (brNew.width - brOld.width) *
						(width - brOld.width)) + brOld.left;
					this._setScalingProperties(left, 0, scale);
				}
				// right border
				if (brOld.left + brOld.width <= obj.canvas.width
					&& brNew.left + brNew.width > obj.canvas.width) {
					let scale = (obj.canvas.width - brOld.left) / obj.width;
					let height = obj.height * scale;
					let top = ((brNew.top - brOld.top) / (brNew.height - brOld.height) *
						(height - brOld.height)) + brOld.top;
					this._setScalingProperties(brNew.left, top, scale);
				}
				// bottom border
				if (brOld.top + brOld.height <= obj.canvas.height
					&& brNew.top + brNew.height > obj.canvas.height) {
					let scale = (obj.canvas.height - brOld.top) / obj.height;
					let width = obj.width * scale;
					let left = ((brNew.left - brOld.left) / (brNew.width - brOld.width) *
						(width - brOld.width)) + brOld.left;
					this._setScalingProperties(left, brNew.top, scale);
				}
				if (brNew.left < 0
					|| brNew.top < 0
					|| brNew.left + brNew.width > obj.canvas.width
					|| brNew.top + brNew.height > obj.canvas.height) {
					obj.left = this.state.scalingProperties['left'];
					obj.top = this.state.scalingProperties['top'];
					obj.scaleX = this.state.scalingProperties['scale'];
					obj.scaleY = this.state.scalingProperties['scale'];
					obj.setCoords();
				} else {
					this.setState({
						scalingProperties: null
					})
				}
			}
		});

		let _this = this;
		let deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
		let delete_img = document.createElement('img');
		delete_img.src = deleteIcon;


		function renderIcon(ctx, left, top, styleOverride, fabricObject) {
			let size = this.cornerSize;
			ctx.save();
			ctx.translate(left, top);
			ctx.rotate(window.fabric.util.degreesToRadians(fabricObject.angle));
			ctx.drawImage(delete_img, -size / 2, -size / 2, size, size);
			ctx.restore();
		}

		window.fabric.Object.prototype.transparentCorners = true;
		window.fabric.Object.prototype.cornerColor = 'rgba(0,0,0,0.5)';
		window.fabric.Object.prototype.borderColor = 'rgba(0,0,0,0)';
		window.fabric.Object.prototype.cornerStyle = 'circle';
		window.fabric.Object.prototype.border = 0;
		window.fabric.Object.prototype.cornerSize = 10;
		if (shirt_side === 'left' || shirt_side === 'right') {
			window.fabric.Object.prototype.cornerSize = 5;
		}


		window.fabric.Object.prototype.controls.deleteControl = new window.fabric.Control({
			x: -0.5,
			y: -0.5,
			offsetY: -16,
			offsetX: -16,
			cursorStyle: 'pointer',
			mouseUpHandler: deleteObject,
			render: renderIcon,
			cornerSize: 14
		});

		function deleteObject(eventData, target) {

			let canvas = target.canvas;
			canvas.discardActiveObject();
			canvas.remove(target);
			if (target.cacheKey) {
				if (target.cacheKey.indexOf('text_') > -1) {
					let text_i = target.cacheKey.replace('text_' + _this.props.selected.shirt_side + '_index_', '');
					_this.props.removeText(text_i);
					_this.resetText(_this.__canvas_front, 'front');
					_this.resetText(_this.__canvas_back, 'back');
					_this.resetText(_this.__canvas_left, 'left');
					_this.resetText(_this.__canvas_right, 'right');
				}
				if (target.cacheKey.indexOf('logo_') > -1) {
					let logo_i = target.cacheKey.replace('logo_' + _this.props.selected.shirt_side + '_index_', '');
					_this.props.removeLogo(logo_i);
				}
			}
		}


	}

	resetText(canvas, shirt_side) {
		canvas.getObjects().forEach((object) => {
			// นับจำนวน logo ที่อยู่ใน canvas
			if (object.cacheKey && object.cacheKey.indexOf('text_' + shirt_side + '_index_') !== -1) {
				canvas.remove(object);
			}
		});
		this.addText(canvas, shirt_side);
	}

	setDefaultTextPosition(canvas, shirt_side) {
		let default_top = canvas.height / 2.3;
		let default_left = canvas.width / 2.8;
		let default_scaleX = 0.8;
		let default_scaleY = 0.8;

		if (shirt_side === 'left') {
			default_top = 180;
			default_left = 230;
		}
		if (shirt_side === 'right') {
			default_top = 180;
			default_left = 190;
		}
		if (shirt_side === 'left' || shirt_side === 'right') {
			default_scaleX = 0.6;
			default_scaleY = 0.6;
		}
		let _default = {};
		_default.top = default_top;
		_default.left = default_left;
		_default.scaleX = default_scaleX;
		_default.scaleY = default_scaleY;
		return _default;
	}

	addText(canvas, shirt_side) {
		this.props.selected.text.forEach((_text, _text_i) => {
			if (_text.shirt_side === shirt_side) {
				let text = new window.fabric.Text(_text.description, {
					top: _text.top || this.setDefaultTextPosition(canvas, shirt_side).top,
					left: _text.left || this.setDefaultTextPosition(canvas, shirt_side).left,
					scaleX: _text.scaleX || this.setDefaultTextPosition(canvas, shirt_side).scaleX,
					scaleY: _text.scaleY || this.setDefaultTextPosition(canvas, shirt_side).scaleY,
					strokeWidth: _text.text_shadow_size || 0,
					stroke: _text.text_shadow_color || '#000000',
					angle: _text.angle || 0,
					cacheKey: 'text_' + shirt_side + '_index_' + (_text_i),
					textAlign: 'center',
					fill: _text.text_color,
					fontSize: 50,
					fontFamily: _text.font_family,
					opacity: this.state.polygon_opacity || 1,
				});
				this.addCanvas(canvas, text, {active_object: false, bring_to_front: true});
			}
		})
	}

	updateText(canvas, shirt_side) {
		let text_count = 0; //จำนวน text ที่อยู่ใน shirt_side แต่ละด้าน
		let all_canvas_text_count = 0; //จำนวน text ที่อยู่ใน shirt_side แต่ละด้าน
		let count_text_in_canvas = 0; //นับจำนวน text ที่เราเลือกอยู่
		let canvas_selected_index = false; //index ของ text ใน canvas ที่ select อยู่
		let selected_text_index = false; // index ของ text ใน props ที่เลือกอยู่

		if (this.props.selected.text.length > 0) {
			this.props.selected.text.forEach((_text, _text_i) => {
				if (_text.selected) {
					selected_text_index = _text_i;
				}
			})
		}
		canvas.getObjects().forEach((object, index) => {
			// นับจำนวน Text ที่อยู่ใน canvas
			if (this.props.edit_mode === 'text'
				&& selected_text_index !== undefined
				&& object.cacheKey.indexOf('text_' + this.props.selected.shirt_side + '_index_' + selected_text_index) !== -1) {
				count_text_in_canvas++;
				canvas_selected_index = index;
			}
			if (object.cacheKey && object.cacheKey.indexOf('text_' + this.props.selected.shirt_side + '_index_') !== -1) {
				all_canvas_text_count++;
			}
		});
		if (this.props.selected.text.length > 0
			&& this.props.edit_mode === 'text'
			&& this.props.selected.shirt_side === shirt_side
		) {
			this.props.selected.text.forEach((text) => {
				if (text.shirt_side === shirt_side) {
					text_count++;
				}
			});
			if (text_count > all_canvas_text_count
				&& count_text_in_canvas === 0) {
				let text = new window.fabric.IText(this.props.selected.text[this.props.selected.text.length - 1].description, {
					top: this.props.selected.text[this.props.selected.text.length - 1].top || this.setDefaultTextPosition(canvas, shirt_side).top,
					left: this.props.selected.text[this.props.selected.text.length - 1].left || this.setDefaultTextPosition(canvas, shirt_side).left,
					scaleX: this.props.selected.text[this.props.selected.text.length - 1].scaleX || this.setDefaultTextPosition(canvas, shirt_side).scaleX,
					scaleY: this.props.selected.text[this.props.selected.text.length - 1].scaleY || this.setDefaultTextPosition(canvas, shirt_side).scaleY,
					strokeWidth: this.props.selected.text[this.props.selected.text.length - 1].text_shadow_size || 0,
					stroke: this.props.selected.text[this.props.selected.text.length - 1].text_shadow_color || '#000000',
					angle: this.props.selected.text[this.props.selected.text.length - 1].angle || 0,
					width: 300,
					cacheKey: 'text_' + this.props.selected.shirt_side + '_index_' + (this.props.selected.text.length - 1),
					textAlign: 'center',
					fill: this.props.selected.text[this.props.selected.text.length - 1].text_color,
					fontSize: 50,
					opacity: this.state.polygon_opacity,
					fontFamily: this.props.selected.text[this.props.selected.text.length - 1].font_family
				});

				this.addCanvas(canvas, text, {active_object: true, bring_to_front: true});
				canvas.getActiveObject().center();
			}
			if (count_text_in_canvas > 0 && selected_text_index !== undefined) {
				let change_front_family = false;
				if (canvas.getActiveObject() && this.props.selected.text[selected_text_index].font_family !== canvas.getActiveObject().fontFamily) {
					change_front_family = true;
				}

				if (this.props.selected.text[selected_text_index].description === '') {
					canvas.remove(canvas.getActiveObject());
					this.props.removeText(selected_text_index);
					this.resetText(this.__canvas_front, 'front');
					this.resetText(this.__canvas_back, 'back');
					this.resetText(this.__canvas_left, 'left');
					this.resetText(this.__canvas_right, 'right');
					return;
				}
				let text = new window.fabric.IText(this.props.selected.text[selected_text_index].description, {
					top: this.props.selected.text[selected_text_index].top || this.setDefaultTextPosition(canvas, shirt_side).top,
					left: this.props.selected.text[selected_text_index].left || this.setDefaultTextPosition(canvas, shirt_side).left,
					scaleX: this.props.selected.text[selected_text_index].scaleX || this.setDefaultTextPosition(canvas, shirt_side).scaleX,
					scaleY: this.props.selected.text[selected_text_index].scaleY || this.setDefaultTextPosition(canvas, shirt_side).scaleY,
					strokeWidth: this.props.selected.text[selected_text_index].text_shadow_size || 0,
					stroke: this.props.selected.text[selected_text_index].text_shadow_color || '#000000',
					angle: this.props.selected.text[selected_text_index].angle || 0,
					cacheKey: 'text_' + this.props.selected.shirt_side + '_index_' + selected_text_index,
					textAlign: 'center',
					fill: this.props.selected.text[selected_text_index].text_color,
					fontSize: 50,
					opacity: this.state.polygon_opacity || 1,
					fontFamily: this.props.selected.text[selected_text_index].font_family,
				});
				canvas.insertAt(text, canvas_selected_index, true).setActiveObject(text).bringToFront(text);
				if (change_front_family) {
					canvas.requestRenderAll();
				}


			}
		}
	}

	addImages(canvas, shirt_side) {
		this.props.selected.upload_images.forEach((_upload_image, _upload_image_i) => {
			if (_upload_image.shirt_side === shirt_side) {
				window.fabric.Image.fromURL(_upload_image.url, (img) => {
					img.set({
						top: _upload_image.top || this.setDefaultTextPosition(canvas, shirt_side).top,
						left: _upload_image.left || this.setDefaultTextPosition(canvas, shirt_side).left,
						scaleX: _upload_image.scaleX || 1,
						scaleY: _upload_image.scaleY || 1,
						translateX: _upload_image.translateX || 1,
						translateY: _upload_image.translateY || 1,
						cacheKey: 'logo_' + shirt_side + '_index_' + _upload_image_i,
						// draggable: true,
						// selectable: true,
					});
					// img.scaleToHeight(150);
					// img.scaleToHeight(_upload_image.height || 150);
					// 	img.scaleToWidth(_upload_image.width);
					// img.hasControls = img.hasBorders = false;
					// img.scale(window.fabric.util.getRandomInt(50, 100) / 100);
					this.addCanvas(canvas, img, {
						active_object: true,
						bring_to_front: true,
					});
				}, process.env.REACT_APP_MODE === 'production' ? {crossOrigin: 'Anonymous'} : null);
				// } );
			}
		})
	}

	updateLogo(canvas, shirt_side) {
		let count_logo_canvas = 0;
		canvas.getObjects().forEach((object) => {
			// นับจำนวน logo ที่อยู่ใน canvas
			if (object.cacheKey && object.cacheKey.indexOf('logo_' + shirt_side + '_index_') !== -1) {
				count_logo_canvas++;
			}
		});
		let count_logo_side = 0;
		this.props.selected.upload_images.forEach((upload_image, upload_image_i) => {
			if (upload_image.shirt_side === this.props.selected.shirt_side) {
				count_logo_side++;
			}
		});
		// นับจำนวน logo, หากมีเพิ่มขึ้น ก็จะทำการเพิ่ม logo เข้าไป
		if (this.props.selected.upload_images.length > 0
			&& shirt_side === this.props.selected.shirt_side
			&& count_logo_side > count_logo_canvas //ถ้ารูปใน canvas มีน้อยกว่า
			&& this.props.edit_mode === 'logo' //ถ้าตอนนี้กำลังเลือกโลหด clip_art หรือ Logo อยู่
		) {
			let scaleToHeight = 150;
			if (this.props.selected.shirt_side === 'left'
				|| this.props.selected.shirt_side === 'right') {
				scaleToHeight = 50;
			}
			if (this.props.selected.upload_images[this.props.selected.upload_images.length - 1].shirt_side === shirt_side) {
				window.fabric.Image.fromURL(this.props.selected.upload_images[this.props.selected.upload_images.length - 1].url, (img) => {
					img.set({
						top: this.props.selected.upload_images[this.props.selected.upload_images.length - 1].top || this.setDefaultTextPosition(canvas, shirt_side).top,
						left: this.props.selected.upload_images[this.props.selected.upload_images.length - 1].left || this.setDefaultTextPosition(canvas, shirt_side).left,
						scaleX: this.props.selected.upload_images[this.props.selected.upload_images.length - 1].scaleX || 1,
						scaleY: this.props.selected.upload_images[this.props.selected.upload_images.length - 1].scaleY || 1,
						translateX: this.props.selected.upload_images[this.props.selected.upload_images.length - 1].translateX || 1,
						translateY: this.props.selected.upload_images[this.props.selected.upload_images.length - 1].translateY || 1,
						cacheKey: 'logo_' + this.props.selected.shirt_side + '_index_' + (this.props.selected.upload_images.length - 1),
						// draggable: true,
						// selectable: true,
					});
					img.scaleToHeight(scaleToHeight);
					// img.scaleToWidth(50);
					// img.hasControls = img.hasBorders = false;
					// img.scale(window.fabric.util.getRandomInt(50, 100) / 100);
					this.addCanvas(canvas, img, {
						active_object: true,
						bring_to_front: true,
						onSuccess: (obj) => {
							let __selected = {...this.props.selected};
							let obj_selected_index = obj.cacheKey.replace('logo_' + this.props.selected.shirt_side + '_index_', '');
							__selected.upload_images[obj_selected_index].top = obj.top || 0;
							__selected.upload_images[obj_selected_index].left = obj.left || 0;
							__selected.upload_images[obj_selected_index].scaleX = obj.scaleX || 1;
							__selected.upload_images[obj_selected_index].scaleY = obj.scaleY || 1;
							__selected.upload_images[obj_selected_index].translateX = obj.translateX || 1;
							__selected.upload_images[obj_selected_index].translateY = obj.translateY || 1;
							__selected.upload_images[obj_selected_index].angle = obj.angle || 0;
							this.props.onChange(__selected);
						}
					});
				}, process.env.REACT_APP_MODE === 'production' ? {crossOrigin: 'Anonymous'} : null);
				// } );
			}
		}
	}

	renderUpdateFullCanvas(canvas, shirt_side) {

		this.updatePolygon(canvas, shirt_side,
			{
				style_type: 'collar_style',
				cacheKey_type: 'collar_',
			}
		);
		this.updatePolygon(canvas, shirt_side,
			{
				style_type: 'placket_style',
				cacheKey_type: 'placket_',
			}
		);
		this.updatePolygon(canvas, shirt_side,
			{
				style_type: 'sleeve_style',
				cacheKey_type: 'sleeve_',
			}
		);
		this.updatePolygon(canvas, shirt_side,
			{
				style_type: 'pocket_style',
				cacheKey_type: 'pocket_',
			}
		);
		this.updateShirtType(canvas, shirt_side);
		this.defaultCanvasRender(canvas, shirt_side);
	}

	updatePolygon(canvas, shirt_side, options) {
		if (!canvas) {
			canvas = this.__canvas_front;
		}
		if (!shirt_side) {
			shirt_side = 'front';
		}
		canvas.getObjects().forEach((obj) => {
			if (obj.cacheKey && obj.cacheKey.indexOf(options.cacheKey_type) !== -1) {
				canvas.remove(obj);
			}
		});
		if (this.props.selected.customization[options.style_type].type.length > 0) {
			for (let i = 0; i < this.props.selected.customization[options.style_type].type.length; i++) {
				this.props.selected.customization[options.style_type].type[i].canvas_obj[shirt_side].forEach((canv) => {
					if (canv.point) {
						this.addCanvas(canvas, this.createPolygonFormPoint(canv.point,
							{
								stroke: '#333333',
								strokeWidth: 0,
								fill: this.props.selected.customization[options.style_type].type[i].color.hex_code || '#ffffff',
								opacity: this.state.polygon_opacity,
								hasBorders: false,
								hasControls: false,
								top: canv.top || 0,
								left: canv.left || 0,
								cacheKey: 'collar_' + this.props.selected.customization[options.style_type].type[i].type_id,
								draggable: false,
								lockMovementY: true,
								lockRotation: true,
								lockMovementX: true,
								lockScalingX: true,
								lockScalingY: true,
								lockSkewingX: true,
								lockSkewingY: true,
								lockUniScaling: true,
								selectable: false,
								evented: false,
							}
						), {send_to_back: true});
					}
				})
			}

		}
	}

	updateShirtType(canvas, shirt_side) {
		if (!canvas) {
			canvas = this.__canvas_front;
		}
		if (!shirt_side) {
			shirt_side = 'front';
		}
		// this.props.selected.customization.shirt_style.type[0] คือเสื้อ default shirt_ / shirt_style
		if (this.props.selected.customization.shirt_style.type.length === 1) {
			canvas.getObjects().forEach((obj) => {
				if (obj.cacheKey && obj.cacheKey.indexOf('shirt_') !== -1) {
					canvas.remove(obj);
				}
			});
		}
		if (this.props.selected.customization.shirt_style.type.length > 1) {
			// ทำการเพิ่มลายเสื้อ (อันนี้ลอง โดยไม่ได้ forEach)
			canvas.getObjects().forEach((obj) => {
				if (obj.cacheKey && obj.cacheKey.indexOf('shirt_') !== -1) {
					canvas.remove(obj);
				}
			});
			for (let i = 0; i < this.props.selected.customization.shirt_style.type.length; i++) {
				if (this.props.selected.customization.shirt_style.type[i].type_id !== 'default') {
					this.props.selected.customization.shirt_style.type[i].canvas_obj[shirt_side].forEach((canv) => {
						if (canv.point) {
							this.addCanvas(canvas, this.createPolygonFormPoint(canv.point,
								{
									stroke: '#333333',
									strokeWidth: 0,
									fill: this.props.selected.customization.shirt_style.type[i].color.hex_code || '#ffffff',
									opacity: this.state.polygon_opacity,
									hasBorders: false,
									hasControls: false,
									top: canv.top || 0,
									left: canv.left || 0,
									cacheKey: 'shirt_polygon_' + this.props.selected.customization.shirt_style.type[i].type_id,
									draggable: false,
									lockMovementY: true,
									lockRotation: true,
									lockMovementX: true,
									lockScalingX: true,
									lockScalingY: true,
									lockSkewingX: true,
									lockSkewingY: true,
									lockUniScaling: true,
									selectable: false,
									evented: false,
								}
							), {send_to_back: true});
						}
					})
				}
			}
		}
	}


	onChangeSide(side) {
		let selected = {...this.props.selected};
		this.props.selected.text.forEach((text, text_i) => {
			if (text.selected === 1) {
				text.selected = 0;
				selected.text[text_i] = text;
			}
		});
		selected.shirt_side = side;
		this.props.clearTextSelect();
		this.props.onChange(selected);
	}

	confirmDesign() {
		this.clearFakeBox(this.__canvas_front);
		this.clearFakeBox(this.__canvas_back);
		this.clearFakeBox(this.__canvas_left);
		this.clearFakeBox(this.__canvas_right);
		let canvas = {};
		canvas.front = this.__canvas_front;
		canvas.back = this.__canvas_back;
		canvas.left = this.__canvas_left;
		canvas.right = this.__canvas_right;
		this.props.onConfirm(canvas);
	}

	render() {
		return (
			<div className={"field column"}>
				<div className={"flex row center-xs"} style={{marginBottom: 15}}>
					<div className={"ui buttons basic"}>
						<button
							className={this.props.selected.shirt_side === 'front' ? "active ui button" : "ui button"}
							onClick={() => {
								if (this.props.selected.shirt_side === 'front') {
									return false;
								}
								this.onChangeSide('front');
								this.__canvas_front.discardActiveObject();
							}}>หน้า
						</button>
						<button
							className={this.props.selected.shirt_side === 'back' ? "active ui button" : "ui button"}
							onClick={() => {
								if (this.props.selected.shirt_side === 'back') {
									return false;
								}
								this.onChangeSide('back');
								this.__canvas_back.discardActiveObject();
							}}>หลัง
						</button>
						<button
							className={this.props.selected.shirt_side === 'left' ? "active ui button" : "ui button"}
							onClick={() => {
								if (this.props.selected.shirt_side === 'left') {
									return false;
								}
								this.onChangeSide('left');
								this.__canvas_left.discardActiveObject();
							}}>ซ้าย
						</button>
						<button
							className={this.props.selected.shirt_side === 'right' ? "active ui button" : "ui button"}
							onClick={() => {
								if (this.props.selected.shirt_side === 'right') {
									return false;
								}
								this.onChangeSide('right');
								this.__canvas_right.discardActiveObject();
							}}
						>ขวา
						</button>
					</div>
				</div>
				<div style={{marginBottom: 15}}>
					<div className={"ui segment preview-image flex row center-xs"}
					     style={this.props.selected.shirt_side === 'front' ? {display: 'flex'} : {display: 'none'}}>
						<canvas id="front" width={this.state.canvas_width}
						        height={this.state.canvas_height}/>
					</div>
					<div className={"ui segment preview-image flex row center-xs"}
					     style={this.props.selected.shirt_side === 'back' ? {display: 'flex'} : {display: 'none'}}>
						<canvas id="back" width={this.state.canvas_width} height={this.state.canvas_height}/>
					</div>
					<div className={"ui segment preview-image flex row center-xs"}
					     style={this.props.selected.shirt_side === 'left' ? {display: 'flex'} : {display: 'none'}}>
						<canvas id="left" width={this.state.canvas_width} height={this.state.canvas_height}/>
					</div>
					<div className={"ui segment preview-image flex row center-xs"}
					     style={this.props.selected.shirt_side === 'right' ? {display: 'flex'} : {display: 'none'}}>
						<canvas id="right" width={this.state.canvas_width} height={this.state.canvas_height}/>
					</div>
				</div>
				{/*<div className={"flex row wrap"} style={{marginBottom: 15}}>*/}
				{/*	<div className={"right floated"}>*/}
				{/*		<button*/}
				{/*			className={this.state.is_order_loading ? "ui button green loading" : "ui button green"}*/}
				{/*			type="button"*/}
				{/*			onClick={() => {*/}
				{/*				this.confirmDesign();*/}
				{/*			}}*/}
				{/*		>*/}
				{/*			<i className="shopping basket icon"/> สั่งซื้อ*/}
				{/*		</button>*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>
		);
	}


}

class ShirtDesignConfirmOrder extends React.Component {
	render() {
		return (
			<div style={{paddingTop: 15}}>
				{/*<Modal className="ui modal small"*/}
				{/*       onShowToSetupForm={($modal) => {*/}
				{/*	       $modal.find('form').form2('setup');*/}
				{/*	       // $modal.find('form').form2('submit', {*/}
				{/*	       //     method: 'POST',*/}
				{/*	       //     url: process.env.REACT_APP_DOMAIN + '/api/product-display',*/}
				{/*	       //     beforeSent: function (object) {*/}
				{/*	       //        return object;*/}
				{/*	       //     },*/}
				{/*	       //     loadingButton: $modal.find('.button.approve'),*/}
				{/*	       //     onSuccess: (response) => {*/}
				{/*	       //        $modal.modal('hide');*/}
				{/*	       //        window.Global.messageTop({*/}
				{/*	       // 	       type: 'positive',*/}
				{/*	       // 	       message: 'บันทึกข้อมูลเรียบร้อย'*/}
				{/*	       //        });*/}
				{/*	       //        window.router.navigate('/product-display/' + response['product_display']['id'], true);*/}
				{/*	       //     }*/}
				{/*	       // });*/}
				{/*       }}>*/}
				<h2 className={"ui header dividing"}>
					ยืนยันการสั่งซื้อ
				</h2>
				<div>
					{
						process.env.REACT_APP_MODE === 'production' ?
							<div className={"flex row two column mobile four column computer padded"}>
								<div className={"column padded"}>
									<img src={this.props.canvas.front.toDataURL('image/png')}
									     alt={"FrontShirtDesign"}
									     crossOrigin="anonymous"
									     style={{maxWidth: '100%'}}/>
								</div>
								<div className={"column padded"}>
									<img src={this.props.canvas.back.toDataURL('image/png')}
									     alt={"BackShirtDesign"}
									     crossOrigin="anonymous"
									     style={{maxWidth: '100%'}}/>
								</div>
								<div className={"column padded"}>
									<img src={this.props.canvas.left.toDataURL('image/png')}
									     alt={"LeftShirtDesign"}
									     crossOrigin="anonymous"
									     style={{maxWidth: '100%'}}/>
								</div>
								<div className={"column padded"}>
									<img src={this.props.canvas.right.toDataURL('image/png')}
									     alt={"RightShirtDesign"}
									     crossOrigin="anonymous"
									     style={{maxWidth: '100%'}}/>
								</div>
							</div>
							: null
					}
					<form className="ui form">
						<div className="flex row wrap column four column computer padded">
							<div className="column field required">
								<label htmlFor="">ชื่อผู้ติดต่อ</label>
								<input type="text"
								       name="name"
								       defaultValue={""}/>
							</div>
							<div className="column field required">
								<label htmlFor="">อีเมล์</label>
								<input type="text"
								       name="email"
								       defaultValue={""}/>
							</div>
							<div className="column field required">
								<label htmlFor="">เบอร์โทรศัพท์</label>
								<input type="text"
								       name="phone_number"
								       defaultValue={""}/>
							</div>
							<div className="column field required">
								<label htmlFor="">LINE ID</label>
								<input type="text"
								       name="line_id"
								       defaultValue={""}/>
							</div>

						</div>
						<div className="ui error message"/>
					</form>
				</div>
				<div className={"ui divider"}/>
				{/*{this.props.selectedStyle}*/}
				<div className="actions">
					<div className="flex row">
						<div className="right floated">
							<div className="ui deny button"
							     style={{marginRight: 10}}
							     onClick={() => {
								     this.props.onCancel()
							     }}
							>ยกเลิก
							</div>
							<div className="ui approve green right button">สั่งซื้อ</div>
						</div>
					</div>
				</div>
				{/*</Modal>*/}
			</div>
		);
	}
}