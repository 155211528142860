import React from 'react';

export default class Header extends React.Component {
	render() {
		return (
			<>
				<header>
					<div className={"ui container"}>
						<div className={"flex row wrap middle-xs"}>
							<div>
								<a href={window.location.origin} className={"logo"}>
									LOGO
								</a>
							</div>
							<div className="right floated">
								{
									this.props.mode !== 'edit_table' ?
										<>
											{/*<a href={window.location.origin + '?mode=edit'} className={"ui button icon basic circular small"}>*/}
												{/*<i className="icon search"/>*/}
											{/*</a>*/}
											{/*<a href={window.location.origin + '?mode=edit'} className={"ui button icon basic circular small"}>*/}
												{/*<i className="icon bookmark alternate"/>*/}
											{/*</a>*/}
											{/*<a href={window.location.origin + '?mode=edit'} className={"ui button icon basic circular small"}>*/}
												{/*<i className="icon bell"/>*/}
											{/*</a>*/}
											<a href={window.location.origin + '?mode=list'} className={"ui button basic black small"}>
												ไปยังโหมดแก้ไข
											</a>
										</>
										: null
								}
								{
									this.props.mode === 'edit_table' ?
										<>
											<a href={window.location.origin} className={"ui button basic black small"}>
												ออกจากโหมดแก้ไข
											</a>
										</>
										: null
								}

							</div>
						</div>
					</div>
				</header>
			</>
		);
	}
}