import React from 'react';
import HeaderComponent from './page_component/Header';
import Content from './page_component/Content';
import Gallery from './page_component/Gallery';
import ShirtDesign from "./page_component/ShirtDesign";
import CreatePolygon from "./page_component/CreatePolygon";
import Login from "./Login";
import Header from "./Header";
import Menu from "./Menu";
import List from './page_component/List'

let url= window.location.pathname;
window.segment = url.split('/');
export default class Page extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mode: window.Global.getAllQueryStrings().mode || 'view',
			edit_title: "Edit Title",
			page_components: [],
			show_sub_page: false,
			qs: window.Global.getAllQueryStrings() || false,
			pages: [
				{
					id: 1,
					name: 'หน้าซัพ 1',
					list_parent_page: [
						{
							id: 1,
							name: 'หน้าแรก',
						}
					],
				},
				{id: 2, name: 'หน้าซัพ 2'},
				{id: 3, name: 'หน้าซัพ 3'},
			],
			// parent_page: {
			// 	id: 1,
			// 	name: 'หน้าแรก',
			// 	list_parent_page: [],
			// },
			hide_form: false,
			hide_page_details_form: window.Global.getAllQueryStrings().mode === 'edit',
			hide_page_component: window.Global.getAllQueryStrings().mode === 'add',
			is_component_selected: false,
		}
	}

	componentDidMount() {
		window.Global.ajax({
			method: 'GET',
			url: process.env.REACT_APP_DOMAIN +  '/api/page',
			data: {},
			done: (response) => {
				console.log(response);
			},
			fail: () => {

			}
		});
	}

	addPageComponent(type) {
		let page_components = [...this.state.page_components];
		let page_component = {
			type: type,
			mode: type !== 'List' ? 'add' : 'card',
		};
		if (type === 'Header') {
			page_component.title = 'หัวข้อ';
			page_component.font_type = 'h1';
			page_component.alignment = 'left';
		}
		if (type === 'Content') {
			page_component.image_type = 'none';
			page_component.image_position = 'left';
			page_component.is_content = 'none';
			page_component.alignment = 'left';
			page_component.title = '';
			page_component.image = {url: './images/image.png'};
		}
		if (type === 'Gallery') {
			page_component.images = [
				{url: './images/image.png', checked: false},
				{url: './logo192.png', checked: false},
				{url: './logo512.png', checked: false},
				{url: './images/image.png', checked: false},
			];
		}
		this.state.page_components.map((page_component, page_component_i) => {
			return page_components[page_component_i].mode = type !== 'List' ? false : 'card';
		});
		page_components.unshift(page_component);
		this.setState({
			page_components: page_components,
			hide_form: type !== "List",
			more_breadcrumb: {
				header: type,
				type: 'เพิ่ม'
			},
			is_component_selected: type !== "List"
		});

	}

	isShowPlusButton() {
		let count = this.state.page_components.filter((page_component) => {
			return page_component.mode === 'edit' || page_component.mode === 'add'
		}).length;
		return count === 0;
	}

	switchPosition = (index, type) => {
		let page_components = [...this.state.page_components];
		let current_page_component = page_components[index];
		let switch_page_component = {};
		if (type === 'pullUp') {
			switch_page_component = page_components[index - 1];
			page_components[index - 1] = current_page_component;
		}
		if (type === 'pullDown') {
			switch_page_component = page_components[index + 1];
			page_components[index + 1] = current_page_component;
		}
		page_components[index] = switch_page_component;
		// console.log(page_components);
		return page_components;
	};


	breadCrumb() {
		return (
			<div className="ui breadcrumb">
				<a href={window.location.origin + '?mode=list'} className="section"><i className={"home icon"}/>รายการหน้าทั้งหมด</a>
				{
					this.state.parent_page
					&& this.state.parent_page.list_parent_page.length > 0 ?
						this.state.parent_page.list_parent_page.map((list_parent_page, list_parent_page_index) => {
							return (
								<a href={'/?mode=list&parent_page_id=' + list_parent_page.id}
								   className="item section"
								   key={list_parent_page_index}>
									<i className="right angle icon divider"/> {list_parent_page.name}
								</a>
							)
						})
						: null
				}
				{
					this.state.parent_page
					&& !this.state.more_breadcrumb ?
						<>
							<i className="right angle icon divider"/>
							<div
								className="active section">{this.state.parent_page.name}</div>
						</>
						: null
					// <>
					// 	<i className="right angle icon divider"/>
					// 	<a href={window.location.href} className="section">
					// 		{this.state.parent_page.name}
					// 	</a>
					// </>
				}
				{
					this.state.more_breadcrumb
					&& this.state.more_breadcrumb.header ?
						<>
							<i className="right angle icon divider"/>
							<div className="section" style={{fontWeight: 400}}>{this.state.more_breadcrumb.header}</div>
						</>
						: null
				}
				{
					this.state.more_breadcrumb
					&& this.state.more_breadcrumb.type ?
						<>
							<i className="right angle icon divider"/>
							<div
								className="active section">{this.state.more_breadcrumb.type}</div>
						</>
						: null
				}

			</div>
		);
	}

	renderView() {
		return (
			<>
				<Header/>
				<Menu/>
				<div className="ui container">
					{
						this.state.page_components.length > 0 ?
							this.state.page_components.map((page_component, page_component_i) =>
								<PageComponent key={page_component_i}
								               mode="view"
								               content_node={this.content_node}
								               page_component={page_component}
								/>
							)
							: null
					}
				</div>
			</>
		);
	}

	renderList() {
		return (
			<>
				<Header mode={"edit_table"}/>
				<div className="ui container" style={{paddingTop: 15}}>
					{this.breadCrumb()}
					<table className="ui selectable compact table">
						<thead>
						<tr>
							<th>
								<div className="table-middle">
									<button className="ui small primary icon button"
									        onClick={() => {
										        window.$(this.add_modal).modal('show');
									        }}
									>
										<i className="plus icon"/> เพิ่มหน้า
									</button>
									<div className="ui modal mini" ref={(ref) => {
										this.add_modal = ref
									}}>
										<div className="header">เพิ่มหน้า</div>
										<div className="scrolling content">
											<form className="ui form">
												<div className="field">
													<label>ชื่อ</label>
													<input type="text" name={"name"} autoComplete={'off'}/>
												</div>
											</form>
										</div>
										<div className="actions">
											<button type="button"
											        className="ui button red"
											        onClick={() => {
												        window.$(this.add_modal).modal('hide')
											        }}
											>
												ยกเลิก
											</button>
											<button type="button"
											        className="ui button blue"
											>
												เพิ่ม
											</button>
										</div>
									</div>
								</div>
							</th>
						</tr>
						</thead>
						<tbody>
						{
							this.state.pages.length > 0 ?
								this.state.pages.map((page, page_i) => {
									return (
										<tr key={page_i} valign="middle">
											<td>
												<div className="table-middle">
													<div className="table-link"
													     onClick={() => {
														     window.location.href = window.location.origin + '?mode=list&parent_page_id=' + page.id;
													     }}
													>
														<i className="folder icon"/><i
														className="folder open icon"/> {page.name}
													</div>
													<button className="ui small icon button"
													        onClick={() => {
														        window.location.href = window.location.origin + '?mode=edit&page_id=' + page.id;
													        }}
													>
														<i className="pencil alternate icon"/> แก้ไข
													</button>
												</div>
											</td>
										</tr>
									);
								})
								:
								<tr valign="middle">
									<td>
										<div className="table-middle">
											<div className="table-link center-xs">
												<strong>ไม่พบข้อมูล</strong>
											</div>
										</div>
									</td>
								</tr>
						}
						</tbody>
					</table>
				</div>
			</>
		);
	}

	render() {
		if (this.state.mode === 'view') {
			if (window.segment[1] && window.segment[1] === 'login') {
				return <Login qs={this.state.qs}/>
			}
			if (window.segment[1] && window.segment[1] === 'shirt-design') {
				return (
					<>
						<Header/>
						<Menu/>
						<div className="container ui">
							<ShirtDesign mode={"view"}/>
						</div>
					</>
				);
			}
			if (window.segment[1] && window.segment[1] === 'create-polygon') {
				return (
					<>
						<Header/>
						<Menu/>
						<div className="container ui">
							<CreatePolygon mode={"view"}/>
						</div>
					</>
				);
			}
			return this.renderView()
		}
		if (this.state.mode === 'list') {
			return this.renderList()
		}
		if (this.state.mode === 'edit' || this.state.mode === 'add') {
			return (
				<>
					<div id="container">
						<div id="edit_container">
							{
								<>
									<div className="edit-title">
										{this.breadCrumb()}
									</div>
									{
										!this.state.hide_form ?
											<>

												<div className="sidebar-padded">
													<h3 className={"ui header dividing flex row middle-xs"}>
														ข้อมูลหน้า
														<div className={"right floated"}>
															<div className="ui toggle checkbox"
															     ref={(ref) => {
																     window.$(ref).checkbox();
																     window.$(ref).popup();
															     }}
															     onClick={() => {
																     this.setState({
																	     hide_page_details_form: !this.state.hide_page_details_form,
																	     hide_page_component: true,
																     })
															     }}
															     data-content={!this.state.hide_page_details_form ? 'ปิด' : 'แก้ไข'}
															>
																<input type="checkbox"
																       name="hide_page_details_form"
																       checked={!this.state.hide_page_details_form}
																       onChange={() => {
																	       this.setState({
																		       hide_page_details_form: !this.state.hide_page_details_form,
																		       hide_page_component: true,
																	       })
																       }}
																/>
																<label/>
															</div>
														</div>
													</h3>
													{
														!this.state.hide_page_details_form ?
															<form className={"ui form"} ref={(ref) => {
																this.form = ref;
																window.$(ref).form2('setup');
															}}
															>
																<div className="field">
																	<label htmlFor="title">หัวข้อ</label>
																	<input type="text" name={"title"}
																	       autoComplete={"off"}/>
																</div>
																<div className="field">
																	<label htmlFor="title">รูปประจำหน้า</label>
																	<div id="Upload_coverImage"
																	     data-form2_setup="true"
																	     data-form2_type="upload"
																	     data-upload_type="single_image"
																	     data-input_name="image_cover_filename"
																	     data-width="100%"
																	     data-height="100px"
																	/>
																</div>
																<div className="field">
																	<div className="ui checkbox">
																		<input type="checkbox" name="is_main"
																		       id={"is_main"}/>
																		<label htmlFor={"is_main"}
																		       style={{cursor: 'pointer'}}>แสดงหน้านี้เป็นหน้าแรก</label>
																	</div>
																</div>
																<div className="field">
																	<div className={"flex row middle-xs"}>
																		<div className={"right floated"}>
																			<button className={"ui button blue"}>
																				บันทึก
																			</button>
																		</div>
																	</div>
																</div>
															</form>
															: null
													}
													<h3 className={"ui header dividing flex row middle-xs"}>
														รายการส่วนประกอบ
														<div className={"right floated"}>
															<div className="ui toggle checkbox"
															     ref={(ref) => {
																     window.$(ref).checkbox();
																     window.$(ref).popup();
															     }}
															     onClick={() => {
																     this.setState({
																	     hide_page_component: !this.state.hide_page_component,
																	     hide_page_details_form: true,
																     })
															     }}
															     data-content={!this.state.hide_page_component ? 'ปิด' : 'แก้ไข'}
															>
																<input type="checkbox"
																       name="hide_page_component"
																       checked={!this.state.hide_page_component}
																       onChange={() => {
																	       this.setState({
																		       hide_page_details_form: !this.state.hide_page_details_form,
																		       hide_page_component: true,
																	       })
																       }}
																/>
																<label/>
															</div>
														</div>
													</h3>
												</div>
											</>
											: null
									}
									<div className={"page-component-scroll"}>
										{
											!this.state.hide_page_component
											&& this.state.page_components.length > 0 ?
												this.state.page_components.map((page_component, page_component_i) =>
													<PageComponent key={page_component_i}
													               index={page_component_i}
													               page_component={page_component}
													               is_component_selected={this.state.is_component_selected}
													               mode={"edit"}
													               onChange={(_page_component) => {
														               let page_components = [...this.state.page_components];
														               this.state.page_components.map((page_com, page_com_i) => {
															               if (_page_component.mode === 'edit' || _page_component.mode === 'add') {
																               return page_components[page_com_i].mode = false;
															               } else {
																               return page_components[page_com_i].mode = 'card';
															               }
														               });
														               let count_selected_component = page_components.filter((_component) => {
															               return _component.mode === false
														               }).length;

														               page_components[page_component_i] = _page_component;
														               this.setState({
															               page_components: page_components,
															               is_component_selected: count_selected_component > 0,
														               }, () => {
															               console.log(this.state);
														               });
														               return null;
													               }}
													               removePageComponent={(index) => {
														               let page_components = [];
														               this.state.page_components.forEach((page_component, page_component_index) => {
															               if (page_component_index !== index) {
																               page_component.mode = 'card';
																               page_components.push(page_component);
															               }
														               });
														               console.log(page_components);
														               this.setState({page_components: page_components});
														               return true;
													               }}
													               onSwitchUp={page_component_i > 0 ?
														               () => {
															               this.setState({
																               page_components: this.switchPosition(page_component_i, 'pullUp')
															               });
														               } : false
													               }
													               onSwitchDown={page_component_i < this.state.page_components.length - 1 ?
														               () => {
															               this.setState({
																               page_components: this.switchPosition(page_component_i, 'pullDown')
															               });
														               } : false
													               }
													               hide_form={this.state.hide_form}
													               HideForm={(hide_status, options) => {
														               this.setState({
															               hide_form: hide_status,
															               more_breadcrumb: options,
														               })
													               }}

													/>
												)
												: null
										}
										{
											this.isShowPlusButton()
											&& !this.state.hide_page_component ?
												<div className="sidebar-padded"
												     style={{display: 'flex', justifyContent: 'space-between'}}>
													<div ref={(ref) => {
														window.$(ref).dropdown();
													}}
													     className="ui icon dropdown pointing left button">
														<i className="plus icon"/> เพิ่มส่วนประกอบ
														<div className="menu">
															<div className="header">เลือกประเภท</div>
															<div className="item"
															     onClick={() => {
																     this.addPageComponent('Header');
															     }}
															>
																Header
															</div>
															<div className="item"
															     onClick={() => {
																     this.addPageComponent('Content');
															     }}
															>
																Content
															</div>
															<div className="item"
															     onClick={() => {
																     this.addPageComponent('Gallery');
															     }}
															>
																Gallery
															</div>
															<div className="item"
															     onClick={() => {
																     this.addPageComponent('List');
															     }}
															>
																List
															</div>
														</div>
													</div>

												</div>
												: null
										}
									</div>

									{
										this.isShowPlusButton()
										&& !this.state.hide_page_component ?
											<div className="sidebar-padded"
											     style={{display: 'flex', justifyContent: 'space-between'}}>
												<button className="ui button"
												        onClick={() => {
													        window.location.href = window.location.origin + '?mode=list';
												        }}
												>
													กลับ
												</button>
												{/*<button className="ui button blue"*/}
												{/*        onClick={() => {*/}
												{/*	        window.location.href = window.location.origin + '?mode=list';*/}
												{/*        }}*/}
												{/*>*/}
												{/*	บันทึก*/}
												{/*</button>*/}
											</div>
											: null
									}
								</>
							}
						</div>
						<div id="content"
						     ref={(ref) => this.content_node = ref}
						>
							{
								this.state.page_components.filter((component) => {
									return component.mode === 'edit' || component.mode === 'add'
								}).length > 0 ?
									<div className={"toggle-opacity"}>
										<button ref={(ref) => {
											window.$(ref).popup()
										}}
										        className={"ui button icon circular small"}
										        onClick={() => {
											        this.setState({
												        is_component_selected: !this.state.is_component_selected
											        })
										        }}
										        data-content={this.state.is_component_selected ? "ดูภาพรวม" : 'ปิด'}
										>
											{
												this.state.is_component_selected ?
													<i className="eye icon" style={{width: '100%'}}/>
													:
													<i className="eye slash icon" style={{width: '100%'}}/>
											}

										</button>
									</div>
									: null
							}

							{
								this.state.page_components.length > 0 ?
									this.state.page_components.map((page_component, page_component_i) =>
										<PageComponent key={page_component_i}
										               mode="view"
										               content_node={this.content_node}
										               page_component={page_component}
										               is_component_selected={this.state.is_component_selected}
										/>
									)
									: null
							}
						</div>
					</div>
				</>
			);
		}
	}
}

class PageComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_loading: false,
			page_component: {...this.props.page_component},
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.page_component !== this.state.page_component) {
			this.setState({
				is_loading: false,
				page_component: props.page_component,
			})
		}
	}

	scroll = (node) => {
		let scroll_to = 0;
		for (let i = 0; i < window.$(node).prevAll().length; i++) {
			let _node = window.$(node).prevAll()[i];
			scroll_to = scroll_to + window.$(_node).height();
		}
		window.$(this.props.content_node).animate({scrollTop: scroll_to}, 500);
	};

	render() {
		if (this.props.mode === 'edit') {
			if (!this.state.page_component.mode) {
				return null
			}
			if (this.state.page_component.mode === 'card') {
				return (
					<div className="sidebar-padded">
						<div className="ui card fluid "
						     style={{cursor: 'pointer', overflow: 'hidden'}}
						>
							{
								this.state.is_loading ?
									<div className="ui active inverted dimmer">
										<div className="ui text loader" style={{paddingTop: '2.3rem'}}/>
									</div>
									: null
							}

							<div className="edit-card-col">
								<div className="details"
								     onClick={() => {
									     if (this.state.page_component.type !== 'List') {
										     let page_component = {...this.state.page_component};
										     page_component.mode = 'edit';
										     this.props.onChange(page_component);
										     this.props.HideForm(true, {
											     header: this.state.page_component.type,
											     type: 'แก้ไข'
										     });
									     }
								     }}
								>
									<strong>{this.state.page_component.type}</strong>
								</div>
								<div className={"trash-icon"}>
									<button className={"ui button icon trash-link"}
									        onClick={() => {
										        let _confirm = window.confirm('ยืนยันลบส่วนประกอบ');
										        if (_confirm) {
											        this.props.removePageComponent(this.props.index);
											        this.props.HideForm(false);
										        }
									        }}
									>
										<i className="trash alternate icon"/>
									</button>
								</div>
								<div className="priority-icon">
									<div
										onClick={
											this.props.onSwitchUp ? () => {
												this.setState({
													is_loading: true,
												}, () => {
													this.props.onSwitchUp();
												});
											} : null
										}
									>
										<i className="caret up icon"
										   style={!this.props.onSwitchUp ? {opacity: 0.1} : null}/>
									</div>
									<div
										onClick={
											this.props.onSwitchDown ? () => {
												this.setState({
													is_loading: true,
												}, () => {
													this.props.onSwitchDown();
												});
											} : null
										}
									>
										<i className="caret down icon"
										   style={!this.props.onSwitchDown ? {opacity: 0.1} : null}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
			if (this.state.page_component.mode === 'edit'
				|| this.state.page_component.mode === 'add'
			) {
				if (this.state.page_component.type === 'Header') {
					return (
						<HeaderComponent mode='edit'
						                 page_component={this.state.page_component}
						                 onChange={(page_component) => this.props.onChange(page_component)}
						                 removePageComponent={() => {
							                 this.props.removePageComponent(this.props.index);
							                 this.props.HideForm(false);
						                 }}
						                 showForm={() => {
							                 this.props.HideForm(false);
						                 }}
						/>
					);
				}
				if (this.state.page_component.type === 'Content') {
					return (
						<Content mode='edit'
						         page_component={this.state.page_component}
						         onChange={(page_component) => this.props.onChange(page_component)}
						         removePageComponent={() => {
							         this.props.removePageComponent(this.props.index);
							         this.props.HideForm(false);
						         }}
						         showForm={() => {
							         this.props.HideForm(false);
						         }}
						/>
					);
				}
				if (this.state.page_component.type === 'Gallery') {
					return (
						<Gallery mode='edit'
						         page_component={this.state.page_component}
						         onChange={(page_component) => this.props.onChange(page_component)}
						         removePageComponent={() => {
							         this.props.removePageComponent(this.props.index);
							         this.props.HideForm(false);
						         }}
						         showForm={() => {
							         this.props.HideForm(false);
						         }}
						/>
					);
				}
				if (this.state.page_component.type === 'ShirtDesign') {
					return (
						<ShirtDesign mode='edit'
						             page_component={this.state.page_component}
						             onChange={(page_component) => this.props.onChange(page_component)}
						             removePageComponent={() => {
							             this.props.removePageComponent(this.props.index);
							             this.props.HideForm(false);
						             }}
						             showForm={() => {
							             this.props.HideForm(false);
						             }}
						/>
					);
				}
				if (this.state.page_component.type === 'List') {
					return (
						<List mode='edit'
						      page_component={this.state.page_component}
						      onChange={(page_component) => this.props.onChange(page_component)}
						      removePageComponent={() => {
							      this.props.removePageComponent(this.props.index);
							      this.props.HideForm(false);
						      }}
						      showForm={() => {
							      this.props.HideForm(false);
						      }}
						/>
					);
				}
			}
		}
		if (this.props.mode === 'view') {
			if (this.state.page_component.type === 'Header') {
				return (
					<HeaderComponent mode="view"
					                 scroll={this.state.page_component.mode === 'edit' ? (node) => {
						                 this.scroll(node);
					                 } : false}
					                 page_component={this.state.page_component}
					                 is_component_selected={this.props.is_component_selected}
					/>
				);
			}
			if (this.state.page_component.type === 'Content') {
				return (
					<Content mode="view"
					         scroll={this.state.page_component.mode === 'edit' ? (node) => {
						         this.scroll(node);
					         } : false}
					         page_component={this.state.page_component}
					         is_component_selected={this.props.is_component_selected}
					/>
				);
			}
			if (this.state.page_component.type === 'Gallery') {
				return (
					<Gallery mode="view"
					         scroll={this.state.page_component.mode === 'edit' ? (node) => {
						         this.scroll(node);
					         } : false}
					         page_component={this.state.page_component}
					         is_component_selected={this.props.is_component_selected}
					/>
				);
			}
			if (this.state.page_component.type === 'ShirtDesign') {
				return (
					<ShirtDesign mode="view"
					             scroll={this.state.page_component.mode === 'edit' ? (node) => {
						             this.scroll(node);
					             } : false}
					             page_component={this.state.page_component}
					             is_component_selected={this.props.is_component_selected}
					/>
				);
			}
			if (this.state.page_component.type === 'List') {
				return (
					<List mode="view"
					      scroll={this.state.page_component.mode === 'edit' ? (node) => {
						      this.scroll(node);
					      } : false}
					      page_component={this.state.page_component}
					      is_component_selected={this.props.is_component_selected}
					/>
				);
			}
		}
		return null;
	}
}
