export const shirt_data = [
	{
		id: 1,
		title: "รูปแบบ 1",
		image: {
			url: "./images/shirt_type/shirt_style/1-1.png"
		},
		type: [
			{
				id: '1-1',
				title: "รูปแบบ 1 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/1-1.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":198.5,"y":189.5},{"x":275.5,"y":160.5},{"x":274.5,"y":164.5},{"x":198.5,"y":193.5}],
							top:72,
							left:80,
							fill:'red'
						},
						{point:[{"x":718.5,"y":144.5},{"x":799.5,"y":174.5},{"x":798.5,"y":177.5},{"x":720.5,"y":148.5}],
							top:64,
							left:339,
							fill: 'red'
						}
					],
					back: [],
					left: [
						{point:[{"x":447.5,"y":206},{"x":441.5,"y":175},{"x":438.5,"y":177},{"x":444.5,"y":209}],
							top:79,
							left:217,
						}
					],
					right: [
						{point:[{"x":513,"y":202},{"x":520,"y":171},{"x":523,"y":174},{"x":516,"y":205}],
							top:77,
							left:254,
						}
					]
				}
			}
		]
	},
	{
		id: 2,
		title: "รูปแบบ 2",
		image: {
			url: "./images/shirt_type/shirt_style/2.png"
		},
		type: [
			{
				id: '2-1',
				title: "รูปแบบ 2 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/2-1.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":358.5,"y":477},{"x":643.5,"y":472},{"x":642.5,"y":493},{"x":360.5,"y":496}],
							top:230,
							left:108,
							fill: 'blue'
						}
					],
					back: [],
					left: [
						{point:[{"x":309.5,"y":461.5},{"x":376.5,"y":461.5},{"x":376.5,"y":476.5},{"x":310.5,"y":476.5}],
							top:227,
							left:138,
						}
					],
					right: [
						{point:[{"x":653,"y":463},{"x":583,"y":463},{"x":583,"y":477},{"x":653,"y":477}],
							top:228,
							left:274,
						}
					]
				}
			},
			{
				id: '2-2',
				title: "รูปแบบ 2 ลายที่ 2",
				image: {
					url: "./images/shirt_type/shirt_style/2-2.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":351,"y":429},{"x":641,"y":425},{"x":641,"y":450},{"x":356,"y":455}],
							top:205,
							left:103,
						}
					],
					back: [],
					left: [
						{point:[{"x":376.5,"y":424},{"x":309.5,"y":425},{"x":309.5,"y":444},{"x":376.5,"y":444}],
							top:207,
							left:138,
						}
					],
					right: [
						{point:[{"x":583,"y":426},{"x":653,"y":426},{"x":653,"y":446},{"x":583,"y":446}],
							top:208,
							left:274,
						}
					]
				}
			}
		]
	},
	{
		id: 3,
		title: "รูปแบบ 3",
		image: {
			url: "./images/shirt_type/shirt_style/3.png"
		},
		type: [
			{
				id: '3-1',
				title: "รูปแบบ 3 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/3-1.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":302,"y":392.5},{"x":319,"y":924.5},{"x":303,"y":925.5},{"x":289,"y":397.5}],
							top:63,
							left:137,
						}
					],
					back: [],
					left: [],
					right: [
						{point:[{"x":513.5,"y":391.5},{"x":531.5,"y":398.5},{"x":540.5,"y":402.5},{"x":548.5,"y":406.5},{"x":558.5,"y":413.5},{"x":567.5,"y":420.5},{"x":580.5,"y":433.5},{"x":591.5,"y":447.5},{"x":598.5,"y":460.5},{"x":607.5,"y":480.5},{"x":611.5,"y":499.5},{"x":613.5,"y":523.5},{"x":608.5,"y":894.5},{"x":600.5,"y":902.5},{"x":605.5,"y":524.5},{"x":603.5,"y":500.5},{"x":600.5,"y":483.5},{"x":591.5,"y":463.5},{"x":584.5,"y":451.5},{"x":572.5,"y":436.5},{"x":559.5,"y":423.5},{"x":551.5,"y":417.5},{"x":542.5,"y":411.5},{"x":535.5,"y":407.5},{"x":526.5,"y":403.5},{"x":512.5,"y":398.5}],
							top:68,
							left:231,
						}
					]
				}
			},
			{
				id: '3-2',
				title: "รูปแบบ 3 ลายที่ 2",
				image: {
					url: "./images/shirt_type/shirt_style/3-2.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":260,"y":406},{"x":271,"y":928},{"x":288,"y":928},{"x":274,"y":400}],
							top:68,
							left:123,
						}
					],
					back: [],
					left: [],
					right: [
						{point:[{"x":507,"y":414.5},{"x":519,"y":417.5},{"x":528,"y":421.5},{"x":535,"y":425.5},{"x":542,"y":430.5},{"x":549,"y":435.5},{"x":561,"y":447.5},{"x":571,"y":459.5},{"x":579,"y":473.5},{"x":587,"y":493.5},{"x":590,"y":508.5},{"x":592,"y":531.5},{"x":588,"y":916.5},{"x":596,"y":909.5},{"x":601,"y":531.5},{"x":599,"y":507.5},{"x":596,"y":490.5},{"x":587,"y":470.5},{"x":580,"y":458.5},{"x":568,"y":443.5},{"x":555,"y":430.5},{"x":547,"y":424.5},{"x":538,"y":418.5},{"x":531,"y":414.5},{"x":522,"y":410.5},{"x":508,"y":405.5}],
							top:75,
							left:230,
						}
					]
				}
			},
		]
	},
	{
		id: 4,
		title: "รูปแบบ 4",
		image: {
			url: "./images/shirt_type/shirt_style/4.png"
		},
		type: [
			{
				id: '4-1',
				title: "รูปแบบ 4 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/4-1.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":241,"y":623.5},{"x":250,"y":623.5},{"x":258,"y":624.5},{"x":267,"y":626.5},{"x":275,"y":629.5},{"x":282,"y":633.5},{"x":288,"y":638.5},{"x":294,"y":646.5},{"x":298,"y":655.5},{"x":300,"y":664.5},{"x":302,"y":674.5},{"x":303,"y":684.5},{"x":304,"y":1002.5},{"x":249,"y":1000.5},{"x":244,"y":996.5},{"x":242,"y":990.5},{"x":240,"y":954.5},{"x":249,"y":786.5},{"x":251,"y":692.5},{"x":246,"y":653.5}],
							top:217,
							left:104,
						},
						{point:[{"x":761,"y":614.5},{"x":752,"y":616.5},{"x":744,"y":618.5},{"x":736,"y":621.5},{"x":729,"y":624.5},{"x":723,"y":627.5},{"x":717,"y":632.5},{"x":712,"y":638.5},{"x":708,"y":646.5},{"x":706,"y":655.5},{"x":706,"y":665.5},{"x":706,"y":674.5},{"x":706,"y":686.5},{"x":706,"y":711.5},{"x":705,"y":1002.5},{"x":734,"y":1003.5},{"x":763,"y":997.5},{"x":767,"y":988.5},{"x":765,"y":898.5},{"x":760,"y":731.5},{"x":759,"y":679.5},{"x":760,"y":639.5}],
							top:210,
							left:337,
						}
					],
					back: [
						{point:[{"x":244,"y":620.5},{"x":251,"y":620.5},{"x":259,"y":621.5},{"x":268,"y":623.5},{"x":276,"y":626.5},{"x":283,"y":630.5},{"x":289,"y":635.5},{"x":295,"y":643.5},{"x":299,"y":652.5},{"x":301,"y":661.5},{"x":303,"y":671.5},{"x":304,"y":681.5},{"x":303,"y":993.5},{"x":285,"y":992.5},{"x":254,"y":981.5},{"x":252,"y":977.5},{"x":252,"y":894.5},{"x":254,"y":786.5},{"x":254,"y":689.5},{"x":248,"y":651.5}],
							top:217,
							left:107,
						},
						{point:[{"x":751,"y":611.5},{"x":747,"y":612.5},{"x":739,"y":614.5},{"x":731,"y":617.5},{"x":724,"y":620.5},{"x":718,"y":623.5},{"x":712,"y":628.5},{"x":707,"y":634.5},{"x":703,"y":642.5},{"x":701,"y":651.5},{"x":701,"y":661.5},{"x":701,"y":670.5},{"x":701,"y":682.5},{"x":701,"y":707.5},{"x":701,"y":990.5},{"x":728,"y":988.5},{"x":742,"y":978.5},{"x":750,"y":969.5},{"x":749,"y":849.5},{"x":746,"y":681.5},{"x":747,"y":662.5},{"x":750,"y":636.5}],
							top:211,
							left:338,
						},
					],
					left: [
						{point:[{"x":456,"y":618},{"x":446,"y":620},{"x":438,"y":624},{"x":434,"y":626},{"x":429,"y":629},{"x":425,"y":634},{"x":421,"y":638},{"x":418,"y":643},{"x":416,"y":647},{"x":413,"y":654},{"x":411,"y":660},{"x":411,"y":665},{"x":411,"y":678},{"x":414,"y":756},{"x":416,"y":859},{"x":417,"y":948},{"x":418,"y":986},{"x":432,"y":994},{"x":440,"y":996},{"x":448,"y":998},{"x":460,"y":998},{"x":473,"y":998},{"x":493,"y":997},{"x":494,"y":946},{"x":496,"y":945},{"x":501,"y":997},{"x":515,"y":997},{"x":532,"y":996},{"x":558,"y":993},{"x":571,"y":991},{"x":577,"y":984},{"x":580,"y":920},{"x":584,"y":848},{"x":593,"y":677},{"x":593,"y":645},{"x":590,"y":633},{"x":586,"y":628},{"x":583,"y":625},{"x":579,"y":623},{"x":572,"y":620},{"x":572,"y":678},{"x":571,"y":686},{"x":563,"y":691},{"x":461,"y":690},{"x":457,"y":685},{"x":456,"y":679}],
							top:214,
							left:160,
						}
					],
					right: [
						{point:[{"x":507.5,"y":606.5},{"x":516.5,"y":607.5},{"x":528.5,"y":611.5},{"x":536.5,"y":616.5},{"x":542.5,"y":621.5},{"x":547.5,"y":626.5},{"x":551.5,"y":633.5},{"x":554.5,"y":641.5},{"x":556.5,"y":650.5},{"x":556.5,"y":663.5},{"x":556.5,"y":690.5},{"x":559.5,"y":971.5},{"x":539.5,"y":987.5},{"x":528.5,"y":993.5},{"x":518.5,"y":994.5},{"x":497.5,"y":995.5},{"x":477.5,"y":994.5},{"x":473.5,"y":992.5},{"x":473.5,"y":944.5},{"x":471.5,"y":944.5},{"x":468.5,"y":993.5},{"x":433.5,"y":992.5},{"x":410.5,"y":990.5},{"x":399.5,"y":988.5},{"x":392.5,"y":984.5},{"x":391.5,"y":978.5},{"x":382.5,"y":818.5},{"x":374.5,"y":646.5},{"x":374.5,"y":637.5},{"x":376.5,"y":630.5},{"x":379.5,"y":625.5},{"x":381.5,"y":622.5},{"x":383.5,"y":619.5},{"x":387.5,"y":616.5},{"x":391.5,"y":615.5},{"x":395.5,"y":614.5},{"x":395.5,"y":683.5},{"x":398.5,"y":687.5},{"x":404.5,"y":689.5},{"x":500.5,"y":686.5},{"x":504.5,"y":683.5},{"x":507.5,"y":678.5}],
							top:206,
							left:141,
						}
					]
				}
			},
			{
				id: '4-2',
				title: "รูปแบบ 4 ลายที่ 2",
				image: {
					url: "./images/shirt_type/shirt_style/4-2.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":244.5,"y":621},{"x":243.5,"y":616},{"x":257.5,"y":617},{"x":269.5,"y":619},{"x":278.5,"y":622},{"x":288.5,"y":627},{"x":294.5,"y":633},{"x":299.5,"y":639},{"x":303.5,"y":646},{"x":306.5,"y":654},{"x":309.5,"y":670},{"x":310.5,"y":679},{"x":310.5,"y":691},{"x":314.5,"y":1000},{"x":307.5,"y":1000},{"x":306.5,"y":690},{"x":306.5,"y":683},{"x":305.5,"y":671},{"x":301.5,"y":653},{"x":297.5,"y":644},{"x":294.5,"y":640},{"x":290.5,"y":635},{"x":284.5,"y":630},{"x":278.5,"y":627},{"x":266.5,"y":623},{"x":255.5,"y":621}],
							top:212,
							left:104,
						},
						{point:[{"x":756,"y":611.5},{"x":744,"y":614.5},{"x":730,"y":618.5},{"x":719,"y":624.5},{"x":712,"y":629.5},{"x":707,"y":635.5},{"x":704,"y":641.5},{"x":702,"y":646.5},{"x":701,"y":651.5},{"x":701,"y":744.5},{"x":700,"y":830.5},{"x":700,"y":999.5},{"x":695,"y":999.5},{"x":695,"y":744.5},{"x":695,"y":652.5},{"x":696,"y":644.5},{"x":698,"y":638.5},{"x":701,"y":633.5},{"x":707,"y":626.5},{"x":714,"y":620.5},{"x":726,"y":614.5},{"x":742,"y":609.5},{"x":757,"y":606.5}],
							top:205,
							left:332,
						}
					],
					back: [
						{point:[{"x":247.5,"y":618},{"x":247.5,"y":613},{"x":258.5,"y":614},{"x":270.5,"y":616},{"x":279.5,"y":619},{"x":289.5,"y":624},{"x":295.5,"y":630},{"x":300.5,"y":636},{"x":304.5,"y":643},{"x":307.5,"y":651},{"x":310.5,"y":667},{"x":311.5,"y":676},{"x":311.5,"y":688},{"x":314.5,"y":991},{"x":306.5,"y":991},{"x":307.5,"y":687},{"x":307.5,"y":680},{"x":306.5,"y":668},{"x":302.5,"y":650},{"x":298.5,"y":641},{"x":295.5,"y":637},{"x":291.5,"y":632},{"x":285.5,"y":627},{"x":279.5,"y":624},{"x":267.5,"y":620},{"x":256.5,"y":618}],
							top:212,
							left:107,
						},
						{point:[{"x":748.5,"y":609},{"x":741.5,"y":611},{"x":727.5,"y":615},{"x":716.5,"y":621},{"x":709.5,"y":626},{"x":704.5,"y":632},{"x":701.5,"y":638},{"x":699.5,"y":643},{"x":698.5,"y":648},{"x":698.5,"y":741},{"x":698.5,"y":827},{"x":698.5,"y":988},{"x":692.5,"y":988},{"x":692.5,"y":741},{"x":692.5,"y":649},{"x":693.5,"y":641},{"x":695.5,"y":635},{"x":698.5,"y":630},{"x":704.5,"y":623},{"x":711.5,"y":617},{"x":723.5,"y":611},{"x":739.5,"y":606},{"x":749.5,"y":604}],
							top:206,
							left:332,
						}
					],
					left: [
						{point:[{"x":652.5,"y":442.5},{"x":663.5,"y":447.5},{"x":670.5,"y":455.5},{"x":673.5,"y":467.5},{"x":673.5,"y":453.5},{"x":665.5,"y":444.5},{"x":652.5,"y":438.5}],
							top:212,
							left:321,
						},
						{point:[{"x":386,"y":610.5},{"x":376,"y":612.5},{"x":368,"y":616.5},{"x":359,"y":621.5},{"x":354,"y":627.5},{"x":350,"y":631.5},{"x":347,"y":637.5},{"x":343,"y":646.5},{"x":341,"y":651.5},{"x":341,"y":674.5},{"x":344,"y":769.5},{"x":348,"y":979.5},{"x":344,"y":974.5},{"x":338,"y":686.5},{"x":338,"y":664.5},{"x":338,"y":651.5},{"x":340,"y":643.5},{"x":344,"y":635.5},{"x":348,"y":628.5},{"x":352,"y":623.5},{"x":355,"y":619.5},{"x":362,"y":615.5},{"x":375,"y":608.5},{"x":386,"y":606.5}],
							top:210,
							left:157,
						}
					],
					right: [
						{point:[{"x":313,"y":437.5},{"x":305,"y":439.5},{"x":300,"y":443.5},{"x":298,"y":447.5},{"x":295,"y":451.5},{"x":293,"y":456.5},{"x":292,"y":461.5},{"x":291,"y":449.5},{"x":293,"y":445.5},{"x":295,"y":442.5},{"x":298,"y":439.5},{"x":303,"y":435.5},{"x":307,"y":433.5},{"x":313,"y":432.5}],
							top:209,
							left:140,
						},
						{point:[{"x":576,"y":592.5},{"x":585,"y":593.5},{"x":597,"y":597.5},{"x":605,"y":602.5},{"x":613,"y":609.5},{"x":616,"y":612.5},{"x":620,"y":619.5},{"x":623,"y":627.5},{"x":625,"y":636.5},{"x":625,"y":681.5},{"x":628,"y":957.5},{"x":632,"y":953.5},{"x":630,"y":668.5},{"x":629,"y":636.5},{"x":627,"y":626.5},{"x":624,"y":617.5},{"x":619,"y":609.5},{"x":616,"y":605.5},{"x":608,"y":599.5},{"x":601,"y":594.5},{"x":586,"y":589.5},{"x":576,"y":588.5}],
							top:202,
							left:274,
						}
					]
				}
			},

		]
	},
	{
		id: 5,
		title: "รูปแบบ 5",
		image: {
			url: "./images/shirt_type/shirt_style/5.png"
		},
		type: [
			{
				id: '5-1',
				title: "รูปแบบ 5 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/5.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":240.5,"y":422},{"x":240.5,"y":407},{"x":240.5,"y":396},{"x":240.5,"y":381},{"x":241.5,"y":361},{"x":242.5,"y":349},{"x":243.5,"y":337},{"x":245.5,"y":324},{"x":248.5,"y":312},{"x":252.5,"y":300},{"x":259.5,"y":285},{"x":268.5,"y":272},{"x":277.5,"y":263},{"x":283.5,"y":258},{"x":285.5,"y":250},{"x":275.5,"y":259},{"x":264.5,"y":271},{"x":256.5,"y":283},{"x":248.5,"y":300},{"x":244.5,"y":313},{"x":241.5,"y":325},{"x":239.5,"y":339},{"x":238.5,"y":349},{"x":237.5,"y":362},{"x":236.5,"y":380},{"x":236.5,"y":393}],
							top:82,
							left:106,
						},
						{point:[{"x":762,"y":418.5},{"x":761,"y":399.5},{"x":760,"y":386.5},{"x":759,"y":367.5},{"x":757,"y":350.5},{"x":754,"y":331.5},{"x":749,"y":302.5},{"x":743,"y":283.5},{"x":735,"y":269.5},{"x":727,"y":260.5},{"x":718,"y":253.5},{"x":716,"y":247.5},{"x":730,"y":257.5},{"x":739,"y":267.5},{"x":747,"y":281.5},{"x":753,"y":301.5},{"x":759,"y":330.5},{"x":762,"y":351.5},{"x":764,"y":369.5},{"x":763,"y":378.5}],
							top:81,
							left:346,
						}
					],
					back: [
						{point:[{"x":253,"y":410},{"x":256,"y":377},{"x":259,"y":357},{"x":265,"y":326},{"x":275,"y":295},{"x":288,"y":260},{"x":301,"y":232},{"x":317,"y":206},{"x":311,"y":207},{"x":297,"y":230},{"x":283,"y":260},{"x":271,"y":293},{"x":261,"y":324},{"x":255,"y":355},{"x":252,"y":376},{"x":251,"y":384}],
							top:52,
							left:109,
						},
						{point:[{"x":735,"y":411},{"x":728,"y":373},{"x":721,"y":340},{"x":712,"y":309},{"x":698,"y":271},{"x":685,"y":240},{"x":675,"y":219},{"x":667,"y":205},{"x":674,"y":208},{"x":679,"y":217},{"x":689,"y":239},{"x":703,"y":271},{"x":716,"y":308},{"x":725,"y":339},{"x":733,"y":374},{"x":737,"y":398}],
							top:51,
							left:316,
						}
					],
					left: [
						{point:[{"x":383,"y":275.5},{"x":384,"y":283.5},{"x":384,"y":301.5},{"x":382,"y":324.5},{"x":380,"y":345.5},{"x":380,"y":364.5},{"x":383,"y":389.5},{"x":387,"y":400.5},{"x":393,"y":408.5},{"x":400,"y":417.5},{"x":400,"y":411.5},{"x":397,"y":406.5},{"x":391,"y":398.5},{"x":387,"y":387.5},{"x":384,"y":363.5},{"x":384,"y":344.5},{"x":386,"y":324.5},{"x":388,"y":300.5},{"x":388,"y":282.5},{"x":387,"y":272.5}],
							top:100,
							left:185,
						},
						{point:[{"x":616,"y":378},{"x":618,"y":371},{"x":622,"y":361},{"x":624,"y":349},{"x":624,"y":330},{"x":622,"y":311},{"x":620,"y":288},{"x":614,"y":268},{"x":606,"y":249},{"x":596,"y":237},{"x":583,"y":225},{"x":569,"y":215},{"x":561,"y":210},{"x":551,"y":204},{"x":555,"y":201},{"x":565,"y":207},{"x":573,"y":212},{"x":587,"y":222},{"x":601,"y":234},{"x":611,"y":247},{"x":618,"y":266},{"x":625,"y":288},{"x":627,"y":310},{"x":629,"y":330},{"x":629,"y":349},{"x":627,"y":362},{"x":623,"y":373},{"x":616,"y":387}],
							top:54,
							left:256,
						}
					],
					right: [
						{point:[{"x":345.5,"y":392},{"x":339.5,"y":381},{"x":335.5,"y":369},{"x":332.5,"y":351},{"x":332.5,"y":324},{"x":336.5,"y":299},{"x":342.5,"y":277},{"x":357.5,"y":251},{"x":374.5,"y":231},{"x":390.5,"y":218},{"x":406.5,"y":207},{"x":401.5,"y":204},{"x":386.5,"y":215},{"x":370.5,"y":229},{"x":353.5,"y":249},{"x":338.5,"y":276},{"x":331.5,"y":300},{"x":327.5,"y":324},{"x":327.5,"y":351},{"x":330.5,"y":372},{"x":334.5,"y":385},{"x":345.5,"y":404}],
							top:52,
							left:144,
						},
						{point:[{"x":556,"y":401.5},{"x":562,"y":387.5},{"x":567,"y":367.5},{"x":569,"y":347.5},{"x":569,"y":326.5},{"x":568,"y":308.5},{"x":566,"y":292.5},{"x":568,"y":275.5},{"x":572,"y":266.5},{"x":576,"y":269.5},{"x":573,"y":278.5},{"x":571,"y":292.5},{"x":572,"y":308.5},{"x":574,"y":326.5},{"x":573,"y":349.5},{"x":571,"y":368.5},{"x":566,"y":390.5},{"x":557,"y":407.5}],
							top:98,
							left:273,
						}
					]
				}
			},


		]
	},
	{
		id: 6,
		title: "รูปแบบ 6",
		image: {
			url: "./images/shirt_type/shirt_style/6.png"
		},
		type: [
			{
				id: '6-1',
				title: "รูปแบบ 6 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/6.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":290.5,"y":397.5},{"x":291.5,"y":928.5},{"x":287.5,"y":928.5},{"x":286.5,"y":398.5}],
							top:66,
							left:142,
						},
						{point:[{"x":245,"y":408},{"x":249,"y":407},{"x":251,"y":929},{"x":247,"y":928}],
							top:73,
							left:121,
						}
					],
					back: [],
					left: [],
					right: [
						{point:[{"x":519,"y":394},{"x":538,"y":405},{"x":559,"y":419},{"x":575,"y":432},{"x":589,"y":447},{"x":599,"y":462},{"x":609,"y":487},{"x":612,"y":506},{"x":614,"y":886},{"x":611,"y":890},{"x":609,"y":505},{"x":606,"y":487},{"x":596,"y":464},{"x":587,"y":449},{"x":573,"y":434},{"x":555,"y":420},{"x":536,"y":407},{"x":518,"y":397}],
							top:73,
							left:235,
						},
						{point:[{"x":507,"y":414},{"x":528,"y":426},{"x":541,"y":435},{"x":563,"y":452},{"x":577,"y":467},{"x":588,"y":485},{"x":594,"y":504},{"x":596,"y":521},{"x":598,"y":907},{"x":595,"y":910},{"x":593,"y":521},{"x":591,"y":504},{"x":585,"y":486},{"x":575,"y":469},{"x":561,"y":454},{"x":539,"y":437},{"x":525,"y":428},{"x":506,"y":417}],
							top:83,
							left:230,
						}
					]
				}
			},


		]
	},
	{
		id: 7,
		title: "รูปแบบ 7",
		image: {
			url: "./images/shirt_type/shirt_style/7.png"
		},
		type: [
			{
				id: '7-1',
				title: "รูปแบบ 7 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/7-1.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":351.5,"y":326},{"x":644.5,"y":323},{"x":648.5,"y":307},{"x":650.5,"y":297},{"x":660.5,"y":264},{"x":668.5,"y":234},{"x":672.5,"y":210},{"x":630.5,"y":191},{"x":597.5,"y":181},{"x":583.5,"y":178},{"x":589.5,"y":190},{"x":594.5,"y":202},{"x":600.5,"y":220},{"x":570.5,"y":214},{"x":549.5,"y":207},{"x":540.5,"y":203},{"x":535.5,"y":204},{"x":529.5,"y":208},{"x":524.5,"y":213},{"x":520.5,"y":217},{"x":515.5,"y":222},{"x":510.5,"y":231},{"x":507.5,"y":237},{"x":503.5,"y":243},{"x":499.5,"y":254},{"x":497.5,"y":262},{"x":496.5,"y":268},{"x":496.5,"y":282},{"x":496.5,"y":301},{"x":495.5,"y":303},{"x":492.5,"y":301},{"x":492.5,"y":291},{"x":492.5,"y":265},{"x":489.5,"y":251},{"x":486.5,"y":245},{"x":480.5,"y":233},{"x":473.5,"y":224},{"x":468.5,"y":219},{"x":460.5,"y":212},{"x":453.5,"y":207},{"x":449.5,"y":209},{"x":426.5,"y":217},{"x":401.5,"y":224},{"x":404.5,"y":213},{"x":407.5,"y":200},{"x":411.5,"y":186},{"x":362.5,"y":203},{"x":341.5,"y":211},{"x":329.5,"y":218},{"x":330.5,"y":234},{"x":331.5,"y":246},{"x":336.5,"y":270},{"x":343.5,"y":294},{"x":347.5,"y":311}],
							top:52,
							left:79,
						},
					],
					back: [],
					left: [
						{point:[{"x":402.5,"y":327},{"x":336.5,"y":326},{"x":338.5,"y":309},{"x":345.5,"y":283},{"x":354.5,"y":261},{"x":363.5,"y":237},{"x":365.5,"y":227},{"x":370.5,"y":240},{"x":381.5,"y":235},{"x":393.5,"y":227},{"x":416.5,"y":208},{"x":435.5,"y":196},{"x":446.5,"y":189},{"x":453.5,"y":228},{"x":448.5,"y":228},{"x":440.5,"y":230},{"x":432.5,"y":233},{"x":425.5,"y":237},{"x":418.5,"y":243},{"x":413.5,"y":250},{"x":410.5,"y":256},{"x":406.5,"y":266},{"x":404.5,"y":277},{"x":403.5,"y":281},{"x":402.5,"y":301}],
							top:60,
							left:139,
						}
					],
					right: [
						{point:[{"x":508.5,"y":227.5},{"x":519.5,"y":191.5},{"x":528.5,"y":196.5},{"x":540.5,"y":204.5},{"x":560.5,"y":218.5},{"x":567.5,"y":223.5},{"x":586.5,"y":235.5},{"x":599.5,"y":224.5},{"x":600.5,"y":227.5},{"x":599.5,"y":230.5},{"x":599.5,"y":235.5},{"x":601.5,"y":240.5},{"x":607.5,"y":255.5},{"x":619.5,"y":282.5},{"x":624.5,"y":299.5},{"x":627.5,"y":316.5},{"x":629.5,"y":326.5},{"x":558.5,"y":326.5},{"x":558.5,"y":297.5},{"x":556.5,"y":274.5},{"x":548.5,"y":250.5},{"x":540.5,"y":239.5},{"x":530.5,"y":232.5},{"x":517.5,"y":227.5}],
							top:62,
							left:224,
						}
					]
				}
			},
			{
				id: '7-2',
				title: "รูปแบบ 7 ลายที่ 2",
				image: {
					url: "./images/shirt_type/shirt_style/7-2.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":349,"y":427.5},{"x":643,"y":424.5},{"x":641,"y":429.5},{"x":640,"y":479.5},{"x":359,"y":485.5},{"x":353,"y":442.5}],
							top:197,
							left:101,
						}
					],
					back: [],
					left: [
						{point:[{"x":311,"y":418.5},{"x":377,"y":419.5},{"x":377,"y":467.5},{"x":311,"y":467.5}],
							top:197,
							left:139,
						}
					],
					right: [
						{point:[{"x":582,"y":418},{"x":654,"y":418},{"x":654,"y":464},{"x":583,"y":466}],
							top:197,
							left:273,
						}
					]
				}
			},


		]
	},
	{
		id: 8,
		title: "รูปแบบ 8",
		image: {
			url: "./images/shirt_type/shirt_style/8.png"
		},
		type: [
			{
				id: '8-1',
				title: "รูปแบบ 8 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/8-1.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":260,"y":411},{"x":283,"y":511},{"x":294,"y":575},{"x":301,"y":648},{"x":305,"y":687},{"x":306,"y":720},{"x":308,"y":838},{"x":308,"y":930},{"x":312,"y":930},{"x":312,"y":837},{"x":310,"y":720},{"x":309,"y":686},{"x":305,"y":647},{"x":298,"y":574},{"x":287,"y":510},{"x":264,"y":410}],
							top:75,
							left:117,
						}
					],
					back: [],
					left: [],
					right: [
						{point:[{"x":499.5,"y":410},{"x":513.5,"y":412},{"x":528.5,"y":418},{"x":538.5,"y":423},{"x":549.5,"y":432},{"x":556.5,"y":439},{"x":562.5,"y":450},{"x":566.5,"y":459},{"x":569.5,"y":467},{"x":572.5,"y":482},{"x":577.5,"y":918},{"x":582.5,"y":915},{"x":576.5,"y":481},{"x":573.5,"y":466},{"x":570.5,"y":457},{"x":566.5,"y":447},{"x":559.5,"y":436},{"x":549.5,"y":426},{"x":540.5,"y":419},{"x":526.5,"y":412},{"x":515.5,"y":409},{"x":501.5,"y":406}],
							top:75,
							left:229,
						}
					]
				}
			},
			{
				id: '8-2',
				title: "รูปแบบ 8 ลายที่ 2",
				image: {
					url: "./images/shirt_type/shirt_style/8-2.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":239,"y":412},{"x":262,"y":514},{"x":273,"y":577},{"x":284,"y":688},{"x":286,"y":787},{"x":287,"y":931},{"x":258,"y":932},{"x":237,"y":929},{"x":232,"y":928},{"x":230,"y":924},{"x":229,"y":915},{"x":230,"y":847},{"x":234,"y":779},{"x":237,"y":623},{"x":228,"y":557},{"x":214,"y":501},{"x":206,"y":473},{"x":202,"y":451},{"x":201,"y":428},{"x":217,"y":419}],
							top:76,
							left:79,
						}
					],
					back: [],
					left: [],
					right: [
						{point:[{"x":494,"y":416},{"x":488,"y":435},{"x":495,"y":435},{"x":506,"y":437},{"x":515,"y":442},{"x":522,"y":448},{"x":530,"y":459},{"x":534,"y":470},{"x":536,"y":477},{"x":538,"y":489},{"x":539,"y":505},{"x":539,"y":612},{"x":539,"y":617},{"x":537,"y":620},{"x":532,"y":624},{"x":500,"y":625},{"x":503,"y":880},{"x":505,"y":927},{"x":506,"y":930},{"x":511,"y":931},{"x":549,"y":932},{"x":558,"y":930},{"x":572,"y":924},{"x":568,"y":505},{"x":567,"y":487},{"x":564,"y":474},{"x":561,"y":464},{"x":557,"y":455},{"x":551,"y":445},{"x":541,"y":435},{"x":532,"y":428},{"x":519,"y":422},{"x":508,"y":418}],
							top:79,
							left:223,
						}

					]
				}
			},


		]
	},
	{
		id: 9,
		title: "รูปแบบ 9",
		image: {
			url: "./images/shirt_type/shirt_style/9.png"
		},
		type: [
			{
				id: '9-1',
				title: "รูปแบบ 9 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/9.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":238.5,"y":704.5},{"x":248.5,"y":720.5},{"x":259.5,"y":751.5},{"x":265.5,"y":786.5},{"x":268.5,"y":825.5},{"x":269.5,"y":862.5},{"x":269.5,"y":892.5},{"x":268.5,"y":936.5},{"x":269.5,"y":987.5},{"x":269.5,"y":1029.5},{"x":253.5,"y":1029.5},{"x":239.5,"y":1028.5},{"x":233.5,"y":1023.5},{"x":232.5,"y":1012.5},{"x":234.5,"y":933.5},{"x":236.5,"y":867.5},{"x":239.5,"y":771.5},{"x":240.5,"y":719.5}],
							top:271,
							left:107,
						},
						{point:[{"x":772.5,"y":687.5},{"x":760.5,"y":712.5},{"x":754.5,"y":734.5},{"x":751.5,"y":758.5},{"x":748.5,"y":783.5},{"x":747.5,"y":814.5},{"x":745.5,"y":850.5},{"x":744.5,"y":879.5},{"x":744.5,"y":910.5},{"x":744.5,"y":945.5},{"x":744.5,"y":984.5},{"x":745.5,"y":1026.5},{"x":757.5,"y":1026.5},{"x":768.5,"y":1023.5},{"x":776.5,"y":1020.5},{"x":781.5,"y":1012.5},{"x":777.5,"y":904.5},{"x":774.5,"y":821.5},{"x":772.5,"y":703.5}],
							top:259,
							left:363,
						}
					],
					back: [
						{point:[{"x":245,"y":699},{"x":253,"y":716},{"x":264,"y":747},{"x":270,"y":782},{"x":273,"y":821},{"x":274,"y":858},{"x":274,"y":888},{"x":273,"y":932},{"x":274,"y":983},{"x":275,"y":1017},{"x":262,"y":1013},{"x":255,"y":1010},{"x":248,"y":1007},{"x":246,"y":1002},{"x":245,"y":931},{"x":246,"y":864},{"x":246,"y":771},{"x":247,"y":720}],
							top:270,
							left:115,
						},
						{point:[{"x":756,"y":689},{"x":747,"y":708},{"x":739,"y":731},{"x":733,"y":758},{"x":731,"y":782},{"x":729,"y":813},{"x":729,"y":848},{"x":729,"y":877},{"x":730,"y":908},{"x":731,"y":943},{"x":732,"y":981},{"x":732,"y":1011},{"x":744,"y":1008},{"x":750,"y":1003},{"x":756,"y":997},{"x":759,"y":988},{"x":757,"y":905},{"x":757,"y":822},{"x":755,"y":716}],
							top:264,
							left:357,
						}
					],
					left: [
						{point:[{"x":459.5,"y":719.5},{"x":450.5,"y":730.5},{"x":443.5,"y":740.5},{"x":437.5,"y":754.5},{"x":429.5,"y":782.5},{"x":423.5,"y":817.5},{"x":421.5,"y":878.5},{"x":422.5,"y":943.5},{"x":424.5,"y":1022.5},{"x":441.5,"y":1031.5},{"x":452.5,"y":1034.5},{"x":496.5,"y":1032.5},{"x":496.5,"y":984.5},{"x":498.5,"y":983.5},{"x":501.5,"y":1028.5},{"x":503.5,"y":1031.5},{"x":507.5,"y":1032.5},{"x":549.5,"y":1029.5},{"x":568.5,"y":1027.5},{"x":578.5,"y":1021.5},{"x":581.5,"y":976.5},{"x":586.5,"y":883.5},{"x":592.5,"y":785.5},{"x":588.5,"y":755.5},{"x":581.5,"y":731.5},{"x":572.5,"y":719.5},{"x":565.5,"y":725.5},{"x":543.5,"y":725.5},{"x":498.5,"y":725.5},{"x":465.5,"y":725.5}],
							top:281,
							left:168,
						}
					],
					right: [
						{point:[{"x":495.5,"y":719},{"x":501.5,"y":728},{"x":506.5,"y":737},{"x":511.5,"y":752},{"x":514.5,"y":769},{"x":516.5,"y":794},{"x":518.5,"y":831},{"x":524.5,"y":1028},{"x":517.5,"y":1031},{"x":508.5,"y":1033},{"x":498.5,"y":1033},{"x":468.5,"y":1033},{"x":463.5,"y":1031},{"x":462.5,"y":1028},{"x":461.5,"y":982},{"x":459.5,"y":984},{"x":457.5,"y":1027},{"x":456.5,"y":1030},{"x":452.5,"y":1032},{"x":415.5,"y":1030},{"x":396.5,"y":1027},{"x":386.5,"y":1024},{"x":380.5,"y":1019},{"x":378.5,"y":987},{"x":373.5,"y":900},{"x":369.5,"y":835},{"x":365.5,"y":780},{"x":369.5,"y":758},{"x":373.5,"y":743},{"x":379.5,"y":730},{"x":385.5,"y":721},{"x":390.5,"y":727},{"x":409.5,"y":727},{"x":452.5,"y":725},{"x":489.5,"y":724}],
							top:281,
							left:143,
						}
					]
				}
			},


		]
	},
	{
		id: 10,
		title: "รูปแบบ 10",
		image: {
			url: "./images/shirt_type/shirt_style/10.png"
		},
		type: [
			{
				id: '10-1',
				title: "รูปแบบ 10 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/10-1.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":233,"y":436.5},{"x":253,"y":483.5},{"x":267,"y":519.5},{"x":288,"y":567.5},{"x":305,"y":600.5},{"x":330,"y":646.5},{"x":349,"y":687.5},{"x":360,"y":714.5},{"x":365,"y":731.5},{"x":375,"y":778.5},{"x":383,"y":884.5},{"x":382,"y":938.5},{"x":339,"y":939.5},{"x":301,"y":941.5},{"x":268,"y":937.5},{"x":263,"y":935.5},{"x":261,"y":931.5},{"x":261,"y":895.5},{"x":262,"y":847.5},{"x":266,"y":780.5},{"x":269,"y":663.5},{"x":269,"y":630.5},{"x":259,"y":558.5},{"x":249,"y":520.5},{"x":238,"y":481.5},{"x":235,"y":464.5}],
							top:92,
							left:79,
						}
					],
					back: [],
					left: [],
					right: [
						{point:[{"x":506.5,"y":443},{"x":513.5,"y":443},{"x":523.5,"y":444},{"x":533.5,"y":447},{"x":540.5,"y":450},{"x":545.5,"y":453},{"x":557.5,"y":468},{"x":562.5,"y":479},{"x":567.5,"y":493},{"x":573.5,"y":508},{"x":578.5,"y":520},{"x":583.5,"y":534},{"x":592.5,"y":564},{"x":603.5,"y":593},{"x":613.5,"y":619},{"x":623.5,"y":654},{"x":623.5,"y":738},{"x":623.5,"y":835},{"x":621.5,"y":890},{"x":620.5,"y":898},{"x":617.5,"y":906},{"x":611.5,"y":914},{"x":602.5,"y":924},{"x":591.5,"y":931},{"x":578.5,"y":939},{"x":569.5,"y":941},{"x":552.5,"y":940},{"x":535.5,"y":941},{"x":524.5,"y":940},{"x":522.5,"y":937},{"x":522.5,"y":926},{"x":521.5,"y":888},{"x":519.5,"y":818},{"x":519.5,"y":742},{"x":517.5,"y":634},{"x":548.5,"y":633},{"x":550.5,"y":632},{"x":553.5,"y":629},{"x":555.5,"y":626},{"x":556.5,"y":622},{"x":556.5,"y":597},{"x":556.5,"y":521},{"x":555.5,"y":500},{"x":554.5,"y":491},{"x":552.5,"y":483},{"x":549.5,"y":475},{"x":547.5,"y":469},{"x":543.5,"y":463},{"x":538.5,"y":456},{"x":530.5,"y":450},{"x":522.5,"y":446}],
							top:97,
							left:224,
						}
					]
				}
			},
			{
				id: '10-2',
				title: "รูปแบบ 10 ลายที่ 2",
				image: {
					url: "./images/shirt_type/shirt_style/10-2.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":242.5,"y":430.5},{"x":250.5,"y":448.5},{"x":260.5,"y":473.5},{"x":278.5,"y":518.5},{"x":289.5,"y":543.5},{"x":302.5,"y":572.5},{"x":314.5,"y":594.5},{"x":325.5,"y":614.5},{"x":338.5,"y":638.5},{"x":344.5,"y":651.5},{"x":353.5,"y":669.5},{"x":367.5,"y":703.5},{"x":374.5,"y":725.5},{"x":384.5,"y":775.5},{"x":389.5,"y":827.5},{"x":392.5,"y":879.5},{"x":391.5,"y":932.5},{"x":411.5,"y":932.5},{"x":410.5,"y":876.5},{"x":408.5,"y":826.5},{"x":403.5,"y":771.5},{"x":393.5,"y":720.5},{"x":386.5,"y":695.5},{"x":375.5,"y":668.5},{"x":365.5,"y":647.5},{"x":356.5,"y":630.5},{"x":344.5,"y":609.5},{"x":333.5,"y":588.5},{"x":317.5,"y":558.5},{"x":305.5,"y":535.5},{"x":295.5,"y":511.5},{"x":277.5,"y":468.5},{"x":266.5,"y":442.5},{"x":257.5,"y":421.5}],
							top:83,
							left:79,
						}
					],
					back: [],
					left: [],
					right: [
						{point:[{"x":507.5,"y":293.5},{"x":524.5,"y":294.5},{"x":534.5,"y":297.5},{"x":541.5,"y":300.5},{"x":546.5,"y":303.5},{"x":554.5,"y":313.5},{"x":558.5,"y":318.5},{"x":562.5,"y":327.5},{"x":569.5,"y":345.5},{"x":577.5,"y":365.5},{"x":583.5,"y":381.5},{"x":588.5,"y":397.5},{"x":593.5,"y":414.5},{"x":598.5,"y":427.5},{"x":606.5,"y":448.5},{"x":611.5,"y":462.5},{"x":616.5,"y":476.5},{"x":624.5,"y":504.5},{"x":626.5,"y":467.5},{"x":623.5,"y":459.5},{"x":617.5,"y":442.5},{"x":611.5,"y":422.5},{"x":605.5,"y":405.5},{"x":601.5,"y":392.5},{"x":596.5,"y":376.5},{"x":591.5,"y":361.5},{"x":584.5,"y":341.5},{"x":577.5,"y":324.5},{"x":571.5,"y":311.5},{"x":565.5,"y":302.5},{"x":559.5,"y":296.5},{"x":551.5,"y":290.5},{"x":539.5,"y":285.5},{"x":528.5,"y":283.5},{"x":511.5,"y":281.5}],
							top:85,
							left:224,
						}
					]
				}
			},


		]
	},
	{
		id: 11,
		title: "รูปแบบ 11",
		image: {
			url: "./images/shirt_type/shirt_style/11.png"
		},
		type: [
			{
				id: '11-1',
				title: "รูปแบบ 11 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/11.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":333,"y":731},{"x":340,"y":774},{"x":351,"y":810},{"x":359,"y":835},{"x":370,"y":859},{"x":383,"y":883},{"x":398,"y":907},{"x":411,"y":923},{"x":430,"y":941},{"x":453,"y":962},{"x":473,"y":981},{"x":497,"y":1003},{"x":519,"y":1021},{"x":547,"y":1041},{"x":480,"y":1039},{"x":433,"y":1037},{"x":386,"y":1039},{"x":351,"y":1040},{"x":334,"y":1039},{"x":329,"y":1037},{"x":327,"y":1033},{"x":326,"y":1028},{"x":325,"y":1017},{"x":326,"y":957},{"x":331,"y":881},{"x":332,"y":804}],
							top:288,
							left:107,
						}
					],
					back: [],
					left: [],
					right: [
						{point:[{"x":521.5,"y":730},{"x":525.5,"y":730},{"x":537.5,"y":759},{"x":551.5,"y":782},{"x":561.5,"y":796},{"x":574.5,"y":813},{"x":589.5,"y":834},{"x":603.5,"y":852},{"x":618.5,"y":867},{"x":627.5,"y":875},{"x":628.5,"y":944},{"x":627.5,"y":978},{"x":626.5,"y":990},{"x":622.5,"y":1001},{"x":615.5,"y":1011},{"x":608.5,"y":1020},{"x":599.5,"y":1027},{"x":586.5,"y":1034},{"x":576.5,"y":1037},{"x":565.5,"y":1038},{"x":546.5,"y":1038},{"x":534.5,"y":1037},{"x":528.5,"y":1035},{"x":527.5,"y":1031},{"x":526.5,"y":984},{"x":525.5,"y":936},{"x":523.5,"y":810}],
							top:288,
							left:234,
						}
					]
				}
			},


		]
	},
	{
		id: 12,
		title: "รูปแบบ 12",
		image: {
			url: "./images/shirt_type/shirt_style/12.png"
		},
		type: [
			{
				id: '12-1',
				title: "รูปแบบ 12 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/12.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":799.5,"y":161.5},{"x":778.5,"y":152.5},{"x":749.5,"y":141.5},{"x":724.5,"y":133.5},{"x":710.5,"y":129.5},{"x":715.5,"y":139.5},{"x":720.5,"y":150.5},{"x":722.5,"y":155.5},{"x":750.5,"y":162.5},{"x":768.5,"y":168.5},{"x":796.5,"y":180.5},{"x":798.5,"y":168.5}],
							top:52,
							left:333,
						},
						{point:[{"x":199,"y":178.5},{"x":209,"y":172.5},{"x":222,"y":167.5},{"x":247,"y":158.5},{"x":271,"y":149.5},{"x":281,"y":146.5},{"x":278,"y":155.5},{"x":276,"y":165.5},{"x":274,"y":171.5},{"x":247,"y":178.5},{"x":225,"y":187.5},{"x":200,"y":199.5}],
							top:60,
							left:79,
						}
					],
					back: [
						{point:[{"x":340.5,"y":234},{"x":651.5,"y":236},{"x":661.5,"y":205},{"x":666.5,"y":185},{"x":640.5,"y":175},{"x":599.5,"y":161},{"x":575.5,"y":151},{"x":563.5,"y":146},{"x":548.5,"y":144},{"x":538.5,"y":144},{"x":494.5,"y":144},{"x":441.5,"y":144},{"x":425.5,"y":146},{"x":412.5,"y":150},{"x":382.5,"y":161},{"x":348.5,"y":171},{"x":327.5,"y":180},{"x":331.5,"y":197}],
							top:49,
							left:79,
						}
					],
					left: [
						{point:[{"x":478,"y":188.5},{"x":489,"y":220.5},{"x":493,"y":213.5},{"x":498,"y":209.5},{"x":505,"y":204.5},{"x":512,"y":201.5},{"x":524,"y":198.5},{"x":530,"y":198.5},{"x":539,"y":200.5},{"x":552,"y":204.5},{"x":566,"y":213.5},{"x":572,"y":219.5},{"x":581,"y":232.5},{"x":585,"y":241.5},{"x":589,"y":252.5},{"x":610,"y":249.5},{"x":606,"y":233.5},{"x":600,"y":212.5},{"x":591,"y":196.5},{"x":582,"y":184.5},{"x":567,"y":171.5},{"x":558,"y":161.5},{"x":547,"y":145.5},{"x":542,"y":148.5},{"x":533,"y":153.5},{"x":519,"y":160.5},{"x":507,"y":168.5},{"x":491,"y":178.5}],
							top:46,
							left:206,
						}
					],
					right: [
						{point:[{"x":463.5,"y":213},{"x":475.5,"y":185},{"x":456.5,"y":172},{"x":449.5,"y":167},{"x":436.5,"y":160},{"x":425.5,"y":154},{"x":414.5,"y":146},{"x":402.5,"y":165},{"x":391.5,"y":176},{"x":381.5,"y":184},{"x":373.5,"y":193},{"x":366.5,"y":205},{"x":360.5,"y":217},{"x":355.5,"y":230},{"x":352.5,"y":244},{"x":350.5,"y":258},{"x":370.5,"y":257},{"x":372.5,"y":249},{"x":374.5,"y":241},{"x":378.5,"y":232},{"x":383.5,"y":224},{"x":391.5,"y":215},{"x":400.5,"y":209},{"x":410.5,"y":204},{"x":418.5,"y":201},{"x":424.5,"y":199},{"x":434.5,"y":199},{"x":440.5,"y":200},{"x":446.5,"y":201},{"x":455.5,"y":206},{"x":460.5,"y":210}],
							top:45,
							left:144,
						}
					]
				}
			},


		]
	},
	{
		id: 13,
		title: "รูปแบบ 13",
		image: {
			url: "./images/shirt_type/shirt_style/13.png"
		},
		type: [
			{
				id: '13-1',
				title: "รูปแบบ 13 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/13-1.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":352.5,"y":432},{"x":641.5,"y":432},{"x":641.5,"y":448},{"x":355.5,"y":448}],
							top:212,
							left:104,
						}
					],
					back: [],
					left: [
						{point:[{"x":309.5,"y":432},{"x":376.5,"y":432},{"x":376.5,"y":448},{"x":309.5,"y":448}],
							top:212,
							left:138,
						}
					],
					right: [
						{point:[{"x":583.5,"y":432},{"x":654.5,"y":432},{"x":654.5,"y":448},{"x":583.5,"y":448}],
							top:212,
							left:274,
						}
					]
				}
			},
			{
				id: '13-2',
				title: "รูปแบบ 13 ลายที่ 2",
				image: {
					url: "./images/shirt_type/shirt_style/13-2.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":359.5,"y":501.5},{"x":642.5,"y":501.5},{"x":642.5,"y":510.5},{"x":642.5,"y":520.5},{"x":361.5,"y":519.5},{"x":360.5,"y":508.5}],
							top:246,
							left:109,
						}
					],
					back: [],
					left: [
						{point:[{"x":311,"y":501},{"x":377,"y":501},{"x":377,"y":511},{"x":377,"y":519},{"x":312,"y":519},{"x":311,"y":511}],
							top:246,
							left:139,
						}
					],
					right: [
						{point:[{"x":583,"y":501.5},{"x":653,"y":501.5},{"x":653,"y":511.5},{"x":652,"y":520.5},{"x":583,"y":520.5},{"x":583,"y":510.5}],
							top:246,
							left:274,
						}
					]
				}
			},


		]
	},
	{
		id: 14,
		title: "รูปแบบ 14",
		image: {
			url: "./images/shirt_type/shirt_style/14.png"
		},
		type: [
			{
				id: '20-1',
				title: "รูปแบบ 20 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/14-1.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":223.5,"y":243},{"x":242.5,"y":244},{"x":263.5,"y":245},{"x":279.5,"y":247},{"x":293.5,"y":249},{"x":306.5,"y":253},{"x":319.5,"y":258},{"x":330.5,"y":265},{"x":341.5,"y":274},{"x":350.5,"y":285},{"x":338.5,"y":279},{"x":330.5,"y":276},{"x":318.5,"y":272},{"x":305.5,"y":269},{"x":292.5,"y":267},{"x":276.5,"y":265},{"x":260.5,"y":264},{"x":243.5,"y":264},{"x":226.5,"y":264},{"x":224.5,"y":252}],
							top:111,
							left:80,
						}
					],
					back: [],
					left: [],
					right: [
						{point:[{"x":571,"y":284},{"x":581,"y":284},{"x":591,"y":285},{"x":601,"y":286},{"x":610,"y":288},{"x":620,"y":291},{"x":627,"y":296},{"x":633,"y":303},{"x":637,"y":312},{"x":629,"y":308},{"x":618,"y":304},{"x":611,"y":303},{"x":603,"y":302},{"x":594,"y":301},{"x":585,"y":301},{"x":574,"y":301},{"x":574,"y":295},{"x":573,"y":290}],
							top:135,
							left:269,
						}
					]
				}
			},
			{
				id: '14-2',
				title: "รูปแบบ 14 ลายที่ 2",
				image: {
					url: "./images/shirt_type/shirt_style/14-2.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":246,"y":309},{"x":268,"y":309},{"x":288,"y":309},{"x":311,"y":311},{"x":333,"y":315},{"x":348,"y":320},{"x":358,"y":324},{"x":372,"y":332},{"x":388,"y":346},{"x":398,"y":359},{"x":381,"y":350},{"x":361,"y":341},{"x":347,"y":336},{"x":332,"y":332},{"x":318,"y":330},{"x":303,"y":329},{"x":289,"y":329},{"x":270,"y":330},{"x":253,"y":332},{"x":249,"y":318}],
							top:142,
							left:85,
						}
					],
					back: [],
					left: [],
					right: [
						{point:[{"x":582.5,"y":337},{"x":594.5,"y":337},{"x":611.5,"y":338},{"x":625.5,"y":340},{"x":633.5,"y":343},{"x":642.5,"y":349},{"x":650.5,"y":359},{"x":651.5,"y":371},{"x":641.5,"y":363},{"x":634.5,"y":359},{"x":620.5,"y":356},{"x":605.5,"y":355},{"x":594.5,"y":355},{"x":582.5,"y":356},{"x":582.5,"y":348}],
							top:160,
							left:274,
						}
					]
				}
			},


		]
	},
	{
		id: 15,
		title: "รูปแบบ 15",
		image: {
			url: "./images/shirt_type/shirt_style/15.png"
		},
		type: [
			{
				id: '15-1',
				title: "รูปแบบ 15 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/15-1.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":296.5,"y":472.5},{"x":305.5,"y":447.5},{"x":318.5,"y":422.5},{"x":331.5,"y":401.5},{"x":342.5,"y":390.5},{"x":362.5,"y":375.5},{"x":381.5,"y":366.5},{"x":397.5,"y":359.5},{"x":409.5,"y":355.5},{"x":418.5,"y":353.5},{"x":419.5,"y":359.5},{"x":419.5,"y":372.5},{"x":419.5,"y":396.5},{"x":403.5,"y":401.5},{"x":394.5,"y":405.5},{"x":384.5,"y":409.5},{"x":373.5,"y":415.5},{"x":363.5,"y":421.5},{"x":354.5,"y":427.5},{"x":346.5,"y":434.5},{"x":338.5,"y":443.5},{"x":332.5,"y":450.5},{"x":327.5,"y":457.5},{"x":321.5,"y":466.5},{"x":317.5,"y":474.5},{"x":311.5,"y":485.5},{"x":306.5,"y":495.5},{"x":301.5,"y":506.5},{"x":296.5,"y":519.5},{"x":292.5,"y":528.5},{"x":292.5,"y":513.5},{"x":292.5,"y":506.5},{"x":290.5,"y":492.5}],
							top:133,
							left:113,
						},
						{point:[{"x":578,"y":315.5},{"x":578,"y":279.5},{"x":580,"y":271.5},{"x":617,"y":263.5},{"x":646,"y":258.5},{"x":689,"y":252.5},{"x":722,"y":248.5},{"x":750,"y":246.5},{"x":743,"y":273.5},{"x":739,"y":284.5},{"x":736,"y":296.5},{"x":708,"y":296.5},{"x":686,"y":297.5},{"x":668,"y":298.5},{"x":648,"y":300.5},{"x":631,"y":302.5},{"x":614,"y":305.5},{"x":598,"y":309.5}],
							top:106,
							left:246,
						}
					],
					back: [],
					left: [
						{point:[{"x":393,"y":280},{"x":359,"y":293},{"x":342,"y":302},{"x":324,"y":312},{"x":319,"y":344},{"x":344,"y":332},{"x":361,"y":327},{"x":383,"y":325},{"x":385,"y":303}],
							top:124,
							left:141,
						}
					],
					right: [
						{point:[{"x":580.5,"y":383.5},{"x":591.5,"y":368.5},{"x":607.5,"y":352.5},{"x":623.5,"y":338.5},{"x":636.5,"y":330.5},{"x":640.5,"y":339.5},{"x":643.5,"y":348.5},{"x":645.5,"y":359.5},{"x":624.5,"y":374.5},{"x":603.5,"y":393.5},{"x":580.5,"y":419.5}],
							top:143,
							left:274,
							fill: 'yellow'
						}
					]
				}
			},
			{
				id: '15-2',
				title: "รูปแบบ 15 ลายที่ 2",
				image: {
					url: "./images/shirt_type/shirt_style/15-2.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":371,"y":684.5},{"x":380,"y":664.5},{"x":387,"y":648.5},{"x":395,"y":633.5},{"x":403,"y":618.5},{"x":412,"y":606.5},{"x":427,"y":589.5},{"x":439,"y":580.5},{"x":458,"y":568.5},{"x":481,"y":558.5},{"x":498,"y":553.5},{"x":499,"y":555.5},{"x":501,"y":554.5},{"x":503,"y":551.5},{"x":542,"y":541.5},{"x":571,"y":537.5},{"x":606,"y":534.5},{"x":638,"y":533.5},{"x":661,"y":533.5},{"x":655,"y":551.5},{"x":649,"y":579.5},{"x":648,"y":613.5},{"x":647,"y":654.5},{"x":647,"y":692.5},{"x":647,"y":749.5},{"x":650,"y":813.5},{"x":651,"y":845.5},{"x":654,"y":899.5},{"x":656,"y":933.5},{"x":655,"y":961.5},{"x":653,"y":968.5},{"x":647,"y":972.5},{"x":617,"y":976.5},{"x":539,"y":973.5},{"x":473,"y":971.5},{"x":440,"y":971.5},{"x":404,"y":972.5},{"x":375,"y":971.5},{"x":370,"y":969.5},{"x":367,"y":968.5},{"x":364,"y":963.5},{"x":363,"y":958.5},{"x":364,"y":913.5},{"x":368,"y":830.5},{"x":369,"y":758.5},{"x":370,"y":708.5}],
							top:156,
							left:107,
						}
					],
					back: [],
					left: [
						{point:[{"x":333.5,"y":570},{"x":357.5,"y":559},{"x":375.5,"y":553},{"x":398.5,"y":551},{"x":398.5,"y":589},{"x":398.5,"y":635},{"x":398.5,"y":659},{"x":400.5,"y":665},{"x":403.5,"y":668},{"x":406.5,"y":669},{"x":419.5,"y":669},{"x":441.5,"y":669},{"x":440.5,"y":712},{"x":440.5,"y":743},{"x":439.5,"y":776},{"x":438.5,"y":833},{"x":438.5,"y":908},{"x":437.5,"y":926},{"x":436.5,"y":928},{"x":436.5,"y":973},{"x":434.5,"y":976},{"x":429.5,"y":977},{"x":389.5,"y":977},{"x":381.5,"y":975},{"x":373.5,"y":972},{"x":366.5,"y":968},{"x":360.5,"y":963},{"x":352.5,"y":957},{"x":344.5,"y":946},{"x":338.5,"y":936},{"x":336.5,"y":930},{"x":335.5,"y":899},{"x":335.5,"y":860},{"x":335.5,"y":819},{"x":335.5,"y":752},{"x":335.5,"y":699},{"x":332.5,"y":627},{"x":332.5,"y":595}],
							top:169,
							left:139,
						}
					],
					right: [
						{point:[{"x":562.5,"y":616},{"x":575.5,"y":603},{"x":587.5,"y":589},{"x":599.5,"y":578},{"x":612.5,"y":568},{"x":621.5,"y":562},{"x":629.5,"y":556},{"x":632.5,"y":585},{"x":633.5,"y":617},{"x":631.5,"y":651},{"x":630.5,"y":697},{"x":628.5,"y":757},{"x":628.5,"y":826},{"x":629.5,"y":863},{"x":629.5,"y":896},{"x":629.5,"y":929},{"x":627.5,"y":937},{"x":622.5,"y":945},{"x":616.5,"y":954},{"x":607.5,"y":964},{"x":596.5,"y":972},{"x":584.5,"y":978},{"x":569.5,"y":980},{"x":546.5,"y":979},{"x":530.5,"y":978},{"x":528.5,"y":924},{"x":526.5,"y":859},{"x":526.5,"y":806},{"x":525.5,"y":750},{"x":525.5,"y":703},{"x":524.5,"y":672},{"x":554.5,"y":671},{"x":558.5,"y":669},{"x":561.5,"y":667},{"x":563.5,"y":664},{"x":563.5,"y":661},{"x":563.5,"y":650},{"x":563.5,"y":625}],
							top:172,
							left:235,
						}
					]
				}
			},


		]
	},
	{
		id: 16,
		title: "รูปแบบ 16",
		image: {
			url: "./images/shirt_type/shirt_style/16.png"
		},
		type: [
			{
				id: '16-1',
				title: "รูปแบบ 16 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/16-1.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":370.5,"y":708.5},{"x":384.5,"y":731.5},{"x":408.5,"y":763.5},{"x":429.5,"y":786.5},{"x":457.5,"y":812.5},{"x":478.5,"y":828.5},{"x":501.5,"y":843.5},{"x":520.5,"y":854.5},{"x":547.5,"y":866.5},{"x":578.5,"y":882.5},{"x":601.5,"y":899.5},{"x":623.5,"y":925.5},{"x":637.5,"y":952.5},{"x":647.5,"y":980.5},{"x":653.5,"y":1004.5},{"x":655.5,"y":1018.5},{"x":652.5,"y":1027.5},{"x":647.5,"y":1030.5},{"x":625.5,"y":1033.5},{"x":608.5,"y":1033.5},{"x":584.5,"y":1033.5},{"x":574.5,"y":1013.5},{"x":563.5,"y":994.5},{"x":547.5,"y":976.5},{"x":526.5,"y":959.5},{"x":504.5,"y":948.5},{"x":486.5,"y":940.5},{"x":470.5,"y":932.5},{"x":455.5,"y":925.5},{"x":436.5,"y":911.5},{"x":417.5,"y":888.5},{"x":398.5,"y":854.5},{"x":383.5,"y":815.5},{"x":373.5,"y":786.5},{"x":370.5,"y":771.5},{"x":370.5,"y":746.5},{"x":371.5,"y":722.5}],
							top:273,
							left:114,
							fill: 'pink'
						}
					],
					back: [],
					left: [
						{point:[{"x":433,"y":1081},{"x":434,"y":1069},{"x":431,"y":1049},{"x":427,"y":1032},{"x":418,"y":1003},{"x":407,"y":974},{"x":393,"y":945},{"x":379,"y":923},{"x":364,"y":902},{"x":349,"y":887},{"x":341,"y":879},{"x":335,"y":874},{"x":334,"y":951},{"x":341,"y":976},{"x":351,"y":1008},{"x":353,"y":1026},{"x":353,"y":1040},{"x":351,"y":1055},{"x":349,"y":1062},{"x":362,"y":1074},{"x":371,"y":1080},{"x":379,"y":1083},{"x":389,"y":1085},{"x":405,"y":1086},{"x":426,"y":1085},{"x":430,"y":1084}],
							top:384,
							left:142,
						}
					],
					right: [
						{point:[{"x":557.5,"y":647},{"x":573.5,"y":679},{"x":580.5,"y":693},{"x":600.5,"y":719},{"x":626.5,"y":745},{"x":627.5,"y":801},{"x":584.5,"y":761},{"x":551.5,"y":723},{"x":535.5,"y":698},{"x":523.5,"y":678},{"x":522.5,"y":648},{"x":537.5,"y":648}],
							top:285,
							left:235,
						}
					]
				}
			},
			{
				id: '16-2',
				title: "รูปแบบ 16 ลายที่ 2",
				image: {
					url: "./images/shirt_type/shirt_style/16-2.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":331.5,"y":804.5},{"x":329.5,"y":881.5},{"x":325.5,"y":992.5},{"x":324.5,"y":1022.5},{"x":324.5,"y":1049.5},{"x":325.5,"y":1053.5},{"x":327.5,"y":1057.5},{"x":331.5,"y":1060.5},{"x":341.5,"y":1061.5},{"x":364.5,"y":1063.5},{"x":426.5,"y":1061.5},{"x":491.5,"y":1063.5},{"x":545.5,"y":1065.5},{"x":533.5,"y":1041.5},{"x":524.5,"y":1026.5},{"x":510.5,"y":1010.5},{"x":504.5,"y":1005.5},{"x":486.5,"y":990.5},{"x":449.5,"y":973.5},{"x":433.5,"y":965.5},{"x":417.5,"y":958.5},{"x":408.5,"y":951.5},{"x":398.5,"y":944.5},{"x":388.5,"y":932.5},{"x":378.5,"y":920.5},{"x":369.5,"y":904.5},{"x":360.5,"y":888.5},{"x":350.5,"y":863.5},{"x":341.5,"y":838.5},{"x":336.5,"y":823.5},{"x":333.5,"y":814.5}],
							top:337,
							left:107,
						}
					],
					back: [],
					left: [
						{point:[{"x":293.5,"y":979},{"x":294.5,"y":1062},{"x":295.5,"y":1070},{"x":298.5,"y":1076},{"x":308.5,"y":1089},{"x":312.5,"y":1070},{"x":312.5,"y":1055},{"x":310.5,"y":1035},{"x":307.5,"y":1025},{"x":298.5,"y":997}],
							top:462,
							left:142,
						}
					],
					right: [
						{point:[{"x":524,"y":771.5},{"x":552,"y":816.5},{"x":584,"y":853.5},{"x":628,"y":894.5},{"x":628,"y":1001.5},{"x":624,"y":1011.5},{"x":618,"y":1023.5},{"x":607,"y":1035.5},{"x":593,"y":1044.5},{"x":578,"y":1049.5},{"x":552,"y":1050.5},{"x":530,"y":1047.5},{"x":528,"y":1046.5},{"x":528,"y":1034.5},{"x":526,"y":998.5},{"x":526,"y":916.5},{"x":525,"y":842.5}],
							top:316,
							left:236,
						}
					]
				}
			},


		]
	},
	{
		id: 17,
		title: "รูปแบบ 17",
		image: {
			url: "./images/shirt_type/shirt_style/17.png"
		},
		type: [
			{
				id: '17-1',
				title: "รูปแบบ 17 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/17-1.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":351.5,"y":326},{"x":644.5,"y":323},{"x":648.5,"y":307},{"x":650.5,"y":297},{"x":660.5,"y":264},{"x":668.5,"y":234},{"x":672.5,"y":210},{"x":630.5,"y":191},{"x":597.5,"y":181},{"x":583.5,"y":178},{"x":589.5,"y":190},{"x":594.5,"y":202},{"x":600.5,"y":220},{"x":570.5,"y":214},{"x":549.5,"y":207},{"x":540.5,"y":203},{"x":535.5,"y":204},{"x":529.5,"y":208},{"x":524.5,"y":213},{"x":520.5,"y":217},{"x":515.5,"y":222},{"x":510.5,"y":231},{"x":507.5,"y":237},{"x":503.5,"y":243},{"x":499.5,"y":254},{"x":497.5,"y":262},{"x":496.5,"y":268},{"x":496.5,"y":282},{"x":496.5,"y":301},{"x":495.5,"y":303},{"x":492.5,"y":301},{"x":492.5,"y":291},{"x":492.5,"y":265},{"x":489.5,"y":251},{"x":486.5,"y":245},{"x":480.5,"y":233},{"x":473.5,"y":224},{"x":468.5,"y":219},{"x":460.5,"y":212},{"x":453.5,"y":207},{"x":449.5,"y":209},{"x":426.5,"y":217},{"x":401.5,"y":224},{"x":404.5,"y":213},{"x":407.5,"y":200},{"x":411.5,"y":186},{"x":362.5,"y":203},{"x":341.5,"y":211},{"x":329.5,"y":218},{"x":330.5,"y":234},{"x":331.5,"y":246},{"x":336.5,"y":270},{"x":343.5,"y":294},{"x":347.5,"y":311}],
							top:52,
							left:79,
						},
					],
					back: [],
					left: [
						{point:[{"x":402.5,"y":327},{"x":336.5,"y":326},{"x":338.5,"y":309},{"x":345.5,"y":283},{"x":354.5,"y":261},{"x":363.5,"y":237},{"x":365.5,"y":227},{"x":370.5,"y":240},{"x":381.5,"y":235},{"x":393.5,"y":227},{"x":416.5,"y":208},{"x":435.5,"y":196},{"x":446.5,"y":189},{"x":453.5,"y":228},{"x":448.5,"y":228},{"x":440.5,"y":230},{"x":432.5,"y":233},{"x":425.5,"y":237},{"x":418.5,"y":243},{"x":413.5,"y":250},{"x":410.5,"y":256},{"x":406.5,"y":266},{"x":404.5,"y":277},{"x":403.5,"y":281},{"x":402.5,"y":301}],
							top:60,
							left:139,
						}
					],
					right: [
						{point:[{"x":508.5,"y":227.5},{"x":519.5,"y":191.5},{"x":528.5,"y":196.5},{"x":540.5,"y":204.5},{"x":560.5,"y":218.5},{"x":567.5,"y":223.5},{"x":586.5,"y":235.5},{"x":599.5,"y":224.5},{"x":600.5,"y":227.5},{"x":599.5,"y":230.5},{"x":599.5,"y":235.5},{"x":601.5,"y":240.5},{"x":607.5,"y":255.5},{"x":619.5,"y":282.5},{"x":624.5,"y":299.5},{"x":627.5,"y":316.5},{"x":629.5,"y":326.5},{"x":558.5,"y":326.5},{"x":558.5,"y":297.5},{"x":556.5,"y":274.5},{"x":548.5,"y":250.5},{"x":540.5,"y":239.5},{"x":530.5,"y":232.5},{"x":517.5,"y":227.5}],
							top:62,
							left:224,
						}
					]
				}
			},
			{
				id: '17-2',
				title: "รูปแบบ 17 ลายที่ 2",
				image: {
					url: "./images/shirt_type/shirt_style/17-2.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":348.5,"y":401.5},{"x":641.5,"y":398.5},{"x":640.5,"y":406.5},{"x":350.5,"y":407.5}],
							top:197,
							left:101,
						}
					],
					back: [],
					left: [
						{point:[{"x":311,"y":397.5},{"x":377,"y":398.5},{"x":377,"y":404.5},{"x":311,"y":402.5}],
							top:197,
							left:139,
						}
					],
					right: [
						{point:[{"x":654.5,"y":396.5},{"x":583.5,"y":396.5},{"x":583.5,"y":401.5},{"x":654.5,"y":401.5}],
							top:197,
							left:274,
						}
					]
				}
			},


		]
	},
	{
		id: 18,
		title: "รูปแบบ 18",
		image: {
			url: "./images/shirt_type/shirt_style/18.png"
		},
		type: [
			{
				id: '18-1',
				title: "รูปแบบ 18 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/18-1.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":305,"y":391},{"x":305,"y":417},{"x":303,"y":428},{"x":306,"y":426},{"x":317,"y":677},{"x":323,"y":924},{"x":302,"y":925},{"x":285,"y":400}],
							top:62,
							left:133,
						}
					],
					back: [],
					left: [],
					right: [
						{point:[{"x":513.5,"y":391.5},{"x":531.5,"y":398.5},{"x":540.5,"y":402.5},{"x":548.5,"y":406.5},{"x":558.5,"y":413.5},{"x":567.5,"y":420.5},{"x":580.5,"y":433.5},{"x":591.5,"y":447.5},{"x":598.5,"y":460.5},{"x":607.5,"y":480.5},{"x":611.5,"y":499.5},{"x":613.5,"y":523.5},{"x":608.5,"y":894.5},{"x":600.5,"y":902.5},{"x":605.5,"y":524.5},{"x":603.5,"y":500.5},{"x":600.5,"y":483.5},{"x":591.5,"y":463.5},{"x":584.5,"y":451.5},{"x":572.5,"y":436.5},{"x":559.5,"y":423.5},{"x":551.5,"y":417.5},{"x":542.5,"y":411.5},{"x":535.5,"y":407.5},{"x":526.5,"y":403.5},{"x":512.5,"y":398.5}],
							top:68,
							left:231,
						}
					]
				}
			},
			{
				id: '18-2',
				title: "รูปแบบ 18 ลายที่ 2",
				image: {
					url: "./images/shirt_type/shirt_style/18-2.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":256,"y":407.5},{"x":274,"y":930.5},{"x":268,"y":930.5},{"x":250,"y":409.5}],
							top:73,
							left:119,
						}
					],
					back: [],
					left: [],
					right: [
						{point:[{"x":504,"y":411},{"x":524,"y":419},{"x":548,"y":434},{"x":564,"y":450},{"x":576,"y":468},{"x":588,"y":492},{"x":591,"y":510},{"x":593,"y":526},{"x":590,"y":913},{"x":586,"y":917},{"x":588,"y":527},{"x":586,"y":510},{"x":583,"y":493},{"x":572,"y":470},{"x":560,"y":452},{"x":545,"y":437},{"x":522,"y":422},{"x":503,"y":415}],
							top:79,
							left:229,
						}
					]
				}
			},


		]
	},
	{
		id: 19,
		title: "รูปแบบ 19",
		image: {
			url: "./images/shirt_type/shirt_style/19.png"
		},
		type: [
			{
				id: '19-1',
				title: "รูปแบบ 19 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/19.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":365.5,"y":271},{"x":653.5,"y":518},{"x":653.5,"y":465},{"x":656.5,"y":390},{"x":664.5,"y":355},{"x":674.5,"y":321},{"x":681.5,"y":295},{"x":684.5,"y":274},{"x":640.5,"y":255},{"x":607.5,"y":245},{"x":595.5,"y":242},{"x":603.5,"y":258},{"x":608.5,"y":271},{"x":612.5,"y":284},{"x":602.5,"y":282},{"x":583.5,"y":278},{"x":558.5,"y":270},{"x":551.5,"y":267},{"x":546.5,"y":269},{"x":540.5,"y":273},{"x":534.5,"y":278},{"x":530.5,"y":283},{"x":525.5,"y":290},{"x":520.5,"y":299},{"x":516.5,"y":307},{"x":512.5,"y":314},{"x":510.5,"y":322},{"x":508.5,"y":329},{"x":508.5,"y":340},{"x":508.5,"y":349},{"x":508.5,"y":358},{"x":508.5,"y":365},{"x":507.5,"y":367},{"x":505.5,"y":368},{"x":504.5,"y":366},{"x":504.5,"y":353},{"x":504.5,"y":336},{"x":504.5,"y":328},{"x":502.5,"y":320},{"x":501.5,"y":314},{"x":496.5,"y":305},{"x":492.5,"y":297},{"x":485.5,"y":288},{"x":473.5,"y":276},{"x":464.5,"y":271},{"x":461.5,"y":273},{"x":442.5,"y":280},{"x":413.5,"y":288},{"x":420.5,"y":261},{"x":423.5,"y":250},{"x":394.5,"y":260}],
							top:52,
							left:103,
							fill: 'orange'
						}
					],
					back: [],
					left: [
						{point:[{"x":447,"y":288.5},{"x":458,"y":326.5},{"x":451,"y":326.5},{"x":441,"y":328.5},{"x":433,"y":332.5},{"x":427,"y":335.5},{"x":420,"y":341.5},{"x":415,"y":348.5},{"x":410,"y":360.5},{"x":407,"y":369.5},{"x":405,"y":379.5},{"x":404,"y":397.5},{"x":404,"y":433.5},{"x":404,"y":458.5},{"x":404,"y":504.5},{"x":405,"y":508.5},{"x":407,"y":511.5},{"x":410,"y":513.5},{"x":414,"y":514.5},{"x":448,"y":514.5},{"x":447,"y":586.5},{"x":446,"y":621.5},{"x":405,"y":592.5},{"x":374,"y":568.5},{"x":354,"y":550.5},{"x":340,"y":533.5},{"x":338,"y":483.5},{"x":338,"y":440.5},{"x":340,"y":414.5},{"x":346,"y":384.5},{"x":354,"y":362.5},{"x":364,"y":344.5},{"x":367,"y":325.5},{"x":372,"y":338.5},{"x":392,"y":327.5},{"x":403,"y":318.5},{"x":416,"y":308.5},{"x":428,"y":300.5},{"x":439,"y":293.5}],
							top:61,
							left:139,
						}
					],
					right: [
						{point:[{"x":522,"y":195},{"x":514,"y":216},{"x":542,"y":227},{"x":565,"y":242},{"x":589,"y":267},{"x":611,"y":296},{"x":624,"y":322},{"x":630,"y":337},{"x":627,"y":309},{"x":619,"y":282},{"x":605,"y":248},{"x":601,"y":239},{"x":602,"y":227},{"x":588,"y":239},{"x":570,"y":228},{"x":548,"y":212}],
							top:62,
							left:228,
						}
					]
				}
			},


		]
	},

	{
		id: 20,
		title: "รูปแบบ 20",
		image: {
			url: "./images/shirt_type/shirt_style/20.png"
		},
		type: [
			{
				id: '20-1',
				title: "รูปแบบ 20 ลายที่ 1",
				image: {
					url: "./images/shirt_type/shirt_style/20.png"
				},
				canvas_obj: {
					front: [
						{point:[{"x":762.5,"y":618},{"x":751.5,"y":622},{"x":741.5,"y":629},{"x":733.5,"y":636},{"x":726.5,"y":646},{"x":721.5,"y":660},{"x":718.5,"y":679},{"x":715.5,"y":712},{"x":716.5,"y":745},{"x":717.5,"y":774},{"x":718.5,"y":790},{"x":724.5,"y":805},{"x":733.5,"y":816},{"x":741.5,"y":823},{"x":753.5,"y":829},{"x":766.5,"y":834},{"x":763.5,"y":789},{"x":761.5,"y":694},{"x":761.5,"y":637}],
							top:255,
							left:345,
						},
						{point:[{"x":247,"y":629.5},{"x":261,"y":634.5},{"x":270,"y":639.5},{"x":278,"y":646.5},{"x":285,"y":654.5},{"x":290,"y":664.5},{"x":295,"y":684.5},{"x":298,"y":711.5},{"x":298,"y":736.5},{"x":297,"y":769.5},{"x":294,"y":791.5},{"x":291,"y":804.5},{"x":285,"y":815.5},{"x":277,"y":823.5},{"x":269,"y":829.5},{"x":256,"y":836.5},{"x":246,"y":840.5},{"x":250,"y":754.5},{"x":251,"y":671.5},{"x":251,"y":654.5}],
							top:262,
							left:110,
						}
					],
					back: [
						{point:[{"x":747.5,"y":624},{"x":738.5,"y":627},{"x":730.5,"y":632},{"x":723.5,"y":638},{"x":717.5,"y":646},{"x":711.5,"y":657},{"x":706.5,"y":684},{"x":705.5,"y":711},{"x":704.5,"y":745},{"x":706.5,"y":772},{"x":709.5,"y":790},{"x":713.5,"y":801},{"x":720.5,"y":813},{"x":730.5,"y":822},{"x":741.5,"y":828},{"x":748.5,"y":832},{"x":749.5,"y":790},{"x":747.5,"y":695},{"x":746.5,"y":645}],
							top:260,
							left:341,
						},
						{point:[{"x":250.5,"y":627.5},{"x":262.5,"y":632.5},{"x":271.5,"y":637.5},{"x":279.5,"y":644.5},{"x":286.5,"y":652.5},{"x":291.5,"y":662.5},{"x":296.5,"y":682.5},{"x":299.5,"y":709.5},{"x":299.5,"y":734.5},{"x":298.5,"y":767.5},{"x":295.5,"y":789.5},{"x":292.5,"y":802.5},{"x":286.5,"y":813.5},{"x":278.5,"y":821.5},{"x":270.5,"y":827.5},{"x":263.5,"y":831.5},{"x":253.5,"y":834.5},{"x":254.5,"y":754.5},{"x":254.5,"y":704.5},{"x":254.5,"y":654.5}],
							top:262,
							left:113,
						}
					],
					left: [
						{point:[{"x":466,"y":653},{"x":458,"y":660},{"x":453,"y":667},{"x":450,"y":678},{"x":448,"y":707},{"x":449,"y":757},{"x":452,"y":800},{"x":457,"y":824},{"x":465,"y":839},{"x":471,"y":847},{"x":480,"y":852},{"x":494,"y":859},{"x":509,"y":862},{"x":525,"y":864},{"x":541,"y":861},{"x":554,"y":855},{"x":565,"y":849},{"x":575,"y":841},{"x":583,"y":831},{"x":590,"y":818},{"x":593,"y":798},{"x":596,"y":767},{"x":598,"y":736},{"x":600,"y":703},{"x":600,"y":679},{"x":599,"y":671},{"x":596,"y":663},{"x":593,"y":658},{"x":589,"y":655},{"x":583,"y":652},{"x":582,"y":661},{"x":574,"y":666},{"x":567,"y":666},{"x":538,"y":666},{"x":494,"y":666},{"x":475,"y":666},{"x":471,"y":665},{"x":468,"y":660},{"x":467,"y":656}],
							top:273,
							left:186,
						}
					],
					right: [
						{point:[{"x":498,"y":644},{"x":504,"y":646},{"x":510,"y":650},{"x":515,"y":655},{"x":518,"y":660},{"x":521,"y":668},{"x":523,"y":676},{"x":526,"y":696},{"x":527,"y":718},{"x":527,"y":744},{"x":526,"y":775},{"x":523,"y":798},{"x":518,"y":810},{"x":514,"y":818},{"x":508,"y":827},{"x":503,"y":832},{"x":496,"y":838},{"x":487,"y":844},{"x":478,"y":848},{"x":469,"y":851},{"x":461,"y":852},{"x":449,"y":851},{"x":436,"y":849},{"x":427,"y":847},{"x":418,"y":843},{"x":408,"y":837},{"x":400,"y":831},{"x":393,"y":824},{"x":386,"y":816},{"x":379,"y":802},{"x":376,"y":788},{"x":375,"y":772},{"x":374,"y":739},{"x":373,"y":699},{"x":373,"y":672},{"x":374,"y":664},{"x":377,"y":656},{"x":380,"y":651},{"x":384,"y":648},{"x":388,"y":646},{"x":388,"y":656},{"x":389,"y":659},{"x":392,"y":662},{"x":396,"y":663},{"x":440,"y":662},{"x":475,"y":660},{"x":491,"y":660},{"x":494,"y":659},{"x":498,"y":656},{"x":499,"y":652}],
							top:270,
							left:148,
						}
					]
				}
			},

		]
	},

]