import React from 'react';
// import texture from './../../texture'
// import {SketchPicker} from "react-color";
import FabricTextureColors from './FabricTextureColors';
export default class Texture extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			texture: props.texture || [],
			mode: 'list',
			fabric_texture: {},
		}
	}

	renderView() {
		return (
			<>
				{
					this.props.dev_mode && this.state.mode === 'list' ?
						<div style={{marginBottom: 15}}>
							<button
								className={"ui button blue"}
								type={"button"}
								onClick={() => {
									this.setState({
										mode: 'create'
									})
								}}
							>
								เพิ่มเนื้อผ้า
							</button>
						</div>
						: null
				}
				<div className={"flex row two column computer wrap padded"}>
					{
						this.state.texture.map((texture, texture_i) =>
							<div key={texture_i}
							     className={"column padded"}
							     style={{marginTop: texture_i > 1 ? 15 : 0}}
							>
								<div
									className={this.props.selected.texture_id && this.props.selected.texture_id === texture.id && !this.props.dev_mode ? "green ui centered link card" : "ui centered link card"}
									style={{height: '100%', overflow: 'hidden'}}
									onClick={() => {
										if (this.props.dev_mode) {
											this.setState({
												mode: 'edit',
												fabric_texture: {...texture},
												edit_index: texture_i,
											});
											return
										}
										let selected = {...this.props.selected};
										let product_type = [...this.props.product_type];
										selected.texture_id = texture.id;
										selected.customization.shirt_style.type[0].color = texture.fabric_texture_colors[0];
										product_type[0].color = texture.fabric_texture_colors;
										this.props.onUpdateSelected(selected, product_type);
									}}
								>
									{
										this.props.dev_mode ?
											<>
												<div className={"icon-absolute"}
												     style={{zIndex: 10, top: 5, left: 5}}>
													<div
														className={"ui label black small"}
														style={{margin: 0}}
													>
														กดเพื่อแก้ไข
													</div>
												</div>
											</>
											: null
									}
									{
										this.props.selected.texture_id
										&& !this.props.dev_mode
										&& this.props.selected.texture_id === texture.id ?
											<div
												className={"icon-absolute top right animate__animated animate__bounceIn"}
												style={{zIndex: 10, top: 5, right: 5}}>
												<div className={"icon-frame"}>
													<i className="check icon"/>
												</div>
											</div>
											: null
									}

									<div className={"fabric_texture image"}>
										{
											texture.cover_image ?
												<img
													src={process.env.REACT_APP_DOMAIN + '/storage/' + texture.cover_image}
													alt={"LANNA POLO TEXTURE"}/>

												: null
										}
									</div>

									<div className={"content"}>
										<h3 className={"ui header"} style={{marginBottom: 0}}>{texture.title}</h3>
										{
											texture.description ?
												<p style={{
													fontSize: 12,
													color: '#555',
													marginTop: 5
												}}>{texture.description}</p>
												: null
										}
									</div>
								</div>
							</div>
						)
					}

				</div>
			</>
		)
	}

	render() {
		if (this.state.mode === 'list') {
			return this.renderView();
		}
		return (
			<>
				<form className={"ui form"}
				      ref={(ref) => {
					      window.$(ref).form2('setup');
					      window.$(ref).form2('submit', {
						      method: this.state.mode === 'create' ? 'POST' : 'PUT',
						      url: process.env.REACT_APP_DOMAIN + '/api/v1/fabric_texture',
						      // submitButton: $(ref).find('.approve.button'),
						      loadingButton: window.$(ref).find('.submit'),
						      beforeSent: (data) => {
							      return data;
						      },
						      onSuccess: (response) => {
							      window.Global.messageTop({
								      type: 'positive',
								      message: 'เพิ่มข้อมูลสำเร็จ'
							      });
							      let _texture = [...this.state.texture];
							      if (this.state.mode === 'create') {
								      _texture.unshift(response.fabric_texture);
							      }
							      if (this.state.mode === 'edit') {
								      _texture[this.state.edit_index] = response.fabric_texture;
							      }
							      this.setState({
								      mode: 'list',
								      fabric_texture: {},
								      texture: _texture,
								      edit_index: false
							      })
						      }
					      });
				      }}
				>
					<div className={"ui header dividing"}>
						{this.state.mode === 'create' ? "เพิ่มเนื้อผ้า" : null}
						{this.state.mode === 'edit' ? "แก้ไขเนื้อผ้า" : null}
					</div>
					<div className={"flex row two column computer wrap padded "} style={{marginBottom: 15}}>
						<input type={'hidden'} name={"fabric_texture_id"} value={this.state.fabric_texture.id}/>
						<div className="column padded">
							<div className="field">
								<label>รูปเนื้อผ้า</label>
								<div
									ref={(ref) => {
										window.Global.makeUploadButton({
											singleImage: {
												css: {
													width: "100%",
													height: '230px'
												},
												input: {
													name: 'cover_image',
													url: process.env.REACT_APP_DOMAIN + '/storage/' + this.state.fabric_texture.cover_image,
													value: this.state.fabric_texture.cover_image
												},
												isGallery: false,
											},
											element: window.$(ref),
											url: process.env.REACT_APP_DOMAIN + "/api/file/upload"
										})
									}}

								>&nbsp;</div>
							</div>
						</div>
						<div className="column padded">
							<div className={"field"}>
								<label>หัวข้อ</label>
								<input type={"text"} name={"title"}
								       defaultValue={this.state.fabric_texture.title ? this.state.fabric_texture.title : ""}/>
							</div>
							<div className={"field"}>
								<label>คำอธิบายอย่างย่อ</label>
								<textarea name="description"
								          defaultValue={this.state.fabric_texture.description ? this.state.fabric_texture.description : ""}/>
							</div>
						</div>
					</div>
					<div className={"field"}>
						<FabricTextureColors
							fabric_texture_colors={this.state.fabric_texture.fabric_texture_colors}
							hideSubmitButton={() => {
								this.actionsButton.addClass("hide");
							}}
							showSubmitButton={() => {
								this.actionsButton.removeClass("hide");
							}}
						/>
					</div>
					<div className={"ui divider"}/>
					<div className={"ui message error"}/>
					<div className={"field row actions_btn"}
					     ref={(ref) => this.actionsButton = window.$(ref)}
					>
						<div>
							<button className={"ui button basic"}
							        type={"button"}
							        onClick={() => {
								        this.setState({
									        mode: 'list',
									        fabric_texture: {},
									        edit_index: false
								        })
							        }}
							>
								กลับ
							</button>
							<button className={"ui button red basic"}
							        type={"button"}
							        onClick={() => {
								        this.void({fabric_texture_id: this.state.fabric_texture.id}, {
									        onSuccess: () => {
										        let _texture = this.state.texture.filter((texture) => {
											        return texture.id !== this.state.fabric_texture.id
										        });
										        this.setState({
											        mode: 'list',
											        fabric_texture: {},
											        texture: _texture,
											        edit_index: false
										        });
									        }
								        })
							        }}
							>
								ลบ
							</button>
						</div>
						<div className={"right floated"}>
							<button className={"ui button blue"}
							        type={"submit"}
							>
								{this.state.mode === 'create' ? "เพิ่ม" : null}
								{this.state.mode === 'edit' ? "บันทึก" : null}
							</button>
						</div>
					</div>
				</form>
			</>
		)
	}

	void(_data, options) {
		window.Global.confirm({
			title: options && options.title ? options.title : 'ยืนยันลบข้อมูล',
			content: '<form class="ui form">\n\t<p>โปรดยืนยันลบข้อมูล การกระทำนี้ไม่สามารถกู้ข้อมูลคืนได้</p>\n\t<div class="ui error message"></div>\n</form>',
			cancel_text: 'ไม่ดำเนินการ',
			approve_text: 'ลบข้อมูล',
			onShowToSetupForm: ($modal) => {
				$modal.find('form').form2('setup');
				$modal.find('form').form2('submit', {
					method: 'DELETE',
					url: process.env.REACT_APP_DOMAIN + '/api/v1/fabric_texture',
					loadingButton: $modal.find('.button.approve'),
					beforeSent: (data) => {
						data = _data;
						return data;
					},
					onSuccess: (response) => {
						$modal.modal('hide');
						window.Global.messageTop({
							type: 'positive',
							message: 'ลบข้อมูลสำเร็จ'
						});
						if (options.onSuccess) {
							options.onSuccess(response);
						}
						//this.props.onEditedSuccess(response);
						// this.setState({
						// 	product_display: {...response.product_display}
						// });
					}
				});
			}
		});
	}
}