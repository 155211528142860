import React from 'react';
// import {default_shirt_data} from '../data/default_shirt_data';

export default class CreatePolygon extends React.Component {
	constructor() {
		super();
		this.state = {
			canvas_height: 600,
			canvas_width: 500,
			translatedPoints: false,
			qs: window.Global.getAllQueryStrings() || false
		}
	}

	CanvasRender() {
		this.__canvas = new window.fabric.Canvas(window.$('#c').get(0), {
			preserveObjectStacking: true,
		});
		let side = 'front';
		if(this.state.qs.side) {
			side = this.state.qs.side;
		}
		let image = {};
		image.front = './images/lannapolo_shirt/front.png';
		image.back = './images/lannapolo_shirt/back.png';
		image.left = './images/lannapolo_shirt/left.png';
		image.right = './images/lannapolo_shirt/right.png';

		new window.fabric.Image.fromURL(image[side], (img) => {
			img.set({
				top: 0,
				left: 0,
				cacheKey: 'default',
				draggable: false,
				lockMovementY: true,
				lockRotation: true,
				lockMovementX: true,
				lockScalingX: true,
				lockScalingY: true,
				lockSkewingX: true,
				lockSkewingY: true,
				lockUniScaling: true,
				selectable: false,
				evented: false,
				transparentCorners: false,
				preserveObjectStacking: true
			});
			img.scaleToHeight(this.state.canvas_height);
			img.scaleToWidth(this.state.canvas_width);
			this.addCanvas(img, {send_to_back: true});
		});

// SUCCESS

// let edit = {add_point: true};
// 		this.createMainShirtPolygon(this.__canvas,default_polygon);
		this.createMainShirtPolygon(this.__canvas,{point:[{"x":372.5,"y":373},{"x":388.5,"y":369},{"x":411.5,"y":361},{"x":419.5,"y":353},{"x":422.5,"y":349},{"x":426.5,"y":343},{"x":428.5,"y":335},{"x":429.5,"y":332},{"x":431.5,"y":330},{"x":435.5,"y":326},{"x":440.5,"y":323},{"x":452.5,"y":322},{"x":537.5,"y":322},{"x":543.5,"y":323},{"x":548.5,"y":326},{"x":555.5,"y":329},{"x":558.5,"y":334},{"x":560.5,"y":341},{"x":563.5,"y":348},{"x":568.5,"y":355},{"x":573.5,"y":360},{"x":583.5,"y":365},{"x":601.5,"y":371},{"x":619.5,"y":377},{"x":635.5,"y":383},{"x":655.5,"y":389},{"x":670.5,"y":397},{"x":677.5,"y":404},{"x":683.5,"y":411},{"x":690.5,"y":422},{"x":700.5,"y":444},{"x":707.5,"y":461},{"x":712.5,"y":480},{"x":720.5,"y":503},{"x":726.5,"y":524},{"x":733.5,"y":543},{"x":741.5,"y":566},{"x":719.5,"y":578},{"x":702.5,"y":588},{"x":690.5,"y":593},{"x":679.5,"y":597},{"x":671.5,"y":599},{"x":662.5,"y":601},{"x":654.5,"y":601},{"x":647.5,"y":600},{"x":640.5,"y":598},{"x":635.5,"y":594},{"x":632.5,"y":590},{"x":633.5,"y":688},{"x":635.5,"y":738},{"x":634.5,"y":787},{"x":636.5,"y":822},{"x":636.5,"y":845},{"x":634.5,"y":871},{"x":632.5,"y":876},{"x":630.5,"y":881},{"x":625.5,"y":885},{"x":620.5,"y":889},{"x":614.5,"y":892},{"x":608.5,"y":894},{"x":569.5,"y":897},{"x":519.5,"y":901},{"x":481.5,"y":902},{"x":439.5,"y":897},{"x":411.5,"y":896},{"x":398.5,"y":896},{"x":371.5,"y":887},{"x":364.5,"y":885},{"x":362.5,"y":847},{"x":363.5,"y":715},{"x":364.5,"y":635},{"x":365.5,"y":593},{"x":363.5,"y":585},{"x":358.5,"y":592},{"x":353.5,"y":594},{"x":348.5,"y":596},{"x":342.5,"y":597},{"x":334.5,"y":597},{"x":327.5,"y":596},{"x":319.5,"y":595},{"x":309.5,"y":591},{"x":283.5,"y":580},{"x":260.5,"y":567},{"x":254.5,"y":563},{"x":252.5,"y":561},{"x":258.5,"y":548},{"x":269.5,"y":516},{"x":289.5,"y":451},{"x":298.5,"y":428},{"x":305.5,"y":412},{"x":313.5,"y":402},{"x":323.5,"y":393},{"x":333.5,"y":386},{"x":348.5,"y":380}],
			top:15.75,
			left:3.75,
		}, {add_point: true});

		this.setSleevePolygon(side);
		this.setCollarPolygon(side);



	}

	setCollarPolygon (side) {
		let fill = 'blue';
		let opacity = 0.3;
		if(side === 'front'){
			this.createMainShirtPolygon(this.__canvas, {point:[{"x":600.875,"y":151.375},{"x":570.875,"y":145.375},{"x":546.875,"y":137.375},{"x":535.625,"y":132.125},{"x":531.875,"y":128.375},{"x":539.875,"y":117.375},{"x":545.875,"y":107.375},{"x":453.875,"y":107.375},{"x":460.875,"y":118.375},{"x":468.625,"y":129.125},{"x":449.625,"y":140.125},{"x":427.625,"y":148.125},{"x":401.625,"y":155.125},{"x":406.625,"y":135.125},{"x":408.625,"y":126.125},{"x":411.625,"y":117.125},{"x":416.625,"y":113.125},{"x":428.875,"y":109.375},{"x":435.625,"y":104.125},{"x":439.875,"y":97.375},{"x":441.625,"y":91.125},{"x":443.625,"y":86.125},{"x":447.625,"y":81.125},{"x":453.875,"y":78.375},{"x":462.875,"y":76.375},{"x":467.875,"y":75.375},{"x":475.875,"y":74.375},{"x":483.875,"y":73.375},{"x":489.875,"y":73.375},{"x":496.875,"y":73.375},{"x":502.875,"y":73.375},{"x":510.875,"y":73.375},{"x":522.625,"y":74.125},{"x":527.625,"y":74.125},{"x":534.625,"y":75.125},{"x":541.625,"y":76.125},{"x":547.625,"y":78.125},{"x":552.625,"y":80.125},{"x":557.625,"y":84.125},{"x":560.625,"y":88.125},{"x":562.625,"y":92.125},{"x":564.625,"y":97.125},{"x":568.625,"y":100.125},{"x":573.625,"y":103.125},{"x":579.875,"y":106.375},{"x":583.875,"y":109.375},{"x":590.875,"y":123.375},{"x":596.875,"y":138.375}],
				top:16.25,
				left:151,
				fill: fill,
				opacity: opacity
			});
		}
		if(side === 'back'){
			this.createMainShirtPolygon(this.__canvas, {point:[{"x":409.875,"y":91.375},{"x":415.875,"y":84.375},{"x":421.875,"y":77.375},{"x":425.875,"y":69.375},{"x":428.625,"y":63.125},{"x":430.625,"y":60.125},{"x":433.625,"y":57.125},{"x":437.625,"y":55.125},{"x":442.875,"y":53.375},{"x":528.875,"y":52.375},{"x":543.625,"y":54.125},{"x":548.625,"y":56.125},{"x":552.875,"y":60.375},{"x":555.625,"y":64.125},{"x":558.875,"y":72.375},{"x":561.625,"y":79.125},{"x":564.625,"y":83.125},{"x":568.875,"y":87.375},{"x":572.625,"y":92.125},{"x":562.625,"y":88.125},{"x":552.625,"y":86.125},{"x":542.625,"y":85.125},{"x":439.875,"y":85.375},{"x":429.875,"y":86.375},{"x":421.875,"y":87.375},{"x":415.875,"y":89.375}],
				top:16.25,
				left:164.25,
				fill: fill,
				opacity: opacity
			});
		}
		if(side === 'left'){
			this.createMainShirtPolygon(this.__canvas, {point:[{"x":394.5,"y":176},{"x":405.5,"y":171},{"x":417.5,"y":163},{"x":424.5,"y":157},{"x":434.5,"y":149},{"x":441.5,"y":144},{"x":455.5,"y":135},{"x":469.5,"y":126},{"x":479.5,"y":121},{"x":490.5,"y":115},{"x":497.5,"y":111},{"x":497.5,"y":108},{"x":492.5,"y":99},{"x":491.5,"y":97},{"x":491.5,"y":93},{"x":491.5,"y":89},{"x":489.5,"y":85},{"x":486.5,"y":84},{"x":481.5,"y":85},{"x":472.5,"y":88},{"x":451.5,"y":99},{"x":435.5,"y":110},{"x":408.5,"y":131},{"x":391.5,"y":150},{"x":389.5,"y":154},{"x":388.5,"y":159}],
				top:18.75,
				left:166.75,
				fill: fill,
				opacity: opacity
			});
		}
		if(side === 'right'){
			this.createMainShirtPolygon(this.__canvas, {point:[{"x":563.5,"y":168},{"x":545.5,"y":157},{"x":527.5,"y":144},{"x":505.5,"y":129},{"x":480.5,"y":115},{"x":468.5,"y":107},{"x":468.5,"y":104},{"x":473.5,"y":95},{"x":474.5,"y":87},{"x":475.5,"y":83},{"x":477.5,"y":81},{"x":481.5,"y":80},{"x":484.5,"y":81},{"x":498.5,"y":87},{"x":519.5,"y":100},{"x":536.5,"y":111},{"x":554.5,"y":126},{"x":563.5,"y":135},{"x":573.5,"y":147},{"x":576.5,"y":152},{"x":577.5,"y":157}],
				top:17.75,
				left:206.75,
				fill: fill,
				opacity: opacity
			});
		}
	}
	setSleevePolygon (side) {

		let fill = 'blue';
		let opacity = 0.3;
		//แขนเสื้อด้านหน้า
		if(side === 'front'){
			this.createMainShirtPolygon(this.__canvas, {point:[{"x":140.25,"y":281.625},{"x":141.25,"y":298.625},{"x":142.25,"y":309.625},{"x":144.25,"y":320.625},{"x":147.25,"y":333.625},{"x":151.25,"y":347.625},{"x":155.25,"y":361.625},{"x":159.25,"y":377.625},{"x":163.5,"y":392.875},{"x":166.5,"y":406.875},{"x":170.5,"y":433.875},{"x":172.5,"y":451.875},{"x":174.5,"y":462.875},{"x":176.5,"y":476.875},{"x":168.5,"y":472.875},{"x":160.5,"y":471.875},{"x":149.5,"y":470.875},{"x":137.5,"y":469.875},{"x":128.5,"y":467.875},{"x":119.5,"y":465.875},{"x":111.5,"y":462.875},{"x":101.5,"y":459.875},{"x":93.5,"y":455.875},{"x":83.5,"y":451.875},{"x":75.5,"y":447.875},{"x":68.5,"y":443.875},{"x":68.5,"y":441.875},{"x":76.5,"y":422.875},{"x":88.5,"y":378.875},{"x":95.5,"y":352.875},{"x":103.5,"y":328.875},{"x":109.5,"y":314.875},{"x":115.5,"y":303.875},{"x":124.5,"y":291.875}],
				top:92,
				left:7.25,
				fill: fill,
				opacity: opacity
			});
			this.createMainShirtPolygon(this.__canvas,{point:[{"x":863.75,"y":264.125},{"x":861.75,"y":277.125},{"x":859.75,"y":287.125},{"x":857.75,"y":295.125},{"x":855.75,"y":303.125},{"x":852.75,"y":314.125},{"x":848.75,"y":326.125},{"x":845.75,"y":338.125},{"x":841.75,"y":351.125},{"x":837.75,"y":368.125},{"x":835,"y":382.375},{"x":833,"y":456.375},{"x":838,"y":454.375},{"x":842,"y":453.375},{"x":847,"y":453.375},{"x":854,"y":452.375},{"x":859,"y":452.375},{"x":868,"y":451.375},{"x":876,"y":450.375},{"x":883,"y":448.375},{"x":894,"y":445.375},{"x":901,"y":442.375},{"x":908,"y":439.375},{"x":914,"y":436.375},{"x":923,"y":432.375},{"x":930,"y":428.375},{"x":934,"y":425.375},{"x":929,"y":414.375},{"x":924,"y":400.375},{"x":919,"y":381.375},{"x":912,"y":353.375},{"x":908,"y":339.375},{"x":902,"y":321.375},{"x":897,"y":307.375},{"x":892,"y":295.375},{"x":887,"y":286.375},{"x":881,"y":278.375}],
				top:84,
				left:391.25,
				fill: fill,
				opacity: opacity
			},);
		}
//แขนเสื้อด้านหลัง
		if(side === 'back'){
			this.createMainShirtPolygon(this.__canvas,{point:[{"x":65.5,"y":442},{"x":70.5,"y":429},{"x":74.5,"y":420},{"x":83.5,"y":395},{"x":98.5,"y":344},{"x":109.5,"y":314},{"x":121.5,"y":291},{"x":126.5,"y":284},{"x":131.5,"y":279},{"x":139.5,"y":273},{"x":143.5,"y":290},{"x":148.5,"y":310},{"x":152.5,"y":326},{"x":157.5,"y":342},{"x":163.5,"y":361},{"x":165.5,"y":380},{"x":169.5,"y":418},{"x":172.5,"y":444},{"x":176.5,"y":466},{"x":171.5,"y":471},{"x":165.5,"y":475},{"x":159.5,"y":478},{"x":153.5,"y":479},{"x":145.5,"y":479},{"x":138.5,"y":478},{"x":130.5,"y":476},{"x":120.5,"y":472},{"x":108.5,"y":468},{"x":94.5,"y":461},{"x":79.5,"y":452}],
				top:85,
				left:5,
				fill: fill,
				opacity: opacity
			},);
			this.createMainShirtPolygon(this.__canvas,{point:[{"x":929.5,"y":450.5},{"x":924.5,"y":436.5},{"x":920.5,"y":423.5},{"x":906.5,"y":382.5},{"x":894.5,"y":345.5},{"x":884.5,"y":317.5},{"x":875.5,"y":301.5},{"x":865.5,"y":289.5},{"x":855.5,"y":282.5},{"x":851.5,"y":298.5},{"x":846.5,"y":314.5},{"x":841.5,"y":330.5},{"x":836.5,"y":345.5},{"x":831.5,"y":360.5},{"x":828.5,"y":378.5},{"x":825.5,"y":405.5},{"x":824.5,"y":424.5},{"x":821.5,"y":452.5},{"x":820.5,"y":473.5},{"x":824.5,"y":478.5},{"x":830.5,"y":482.5},{"x":836.5,"y":485.5},{"x":843.5,"y":487.5},{"x":849.5,"y":487.5},{"x":857.5,"y":486.5},{"x":864.5,"y":484.5},{"x":874.5,"y":480.5},{"x":890.5,"y":474.5},{"x":904.5,"y":466.5},{"x":919.5,"y":457.5}],
				top:90,
				left:383,
				fill: fill,
				opacity: opacity
			},);
		}
//แขนเสื้อด้านซ้าย
		if(side === 'left'){
			this.createMainShirtPolygon(this.__canvas,{point:[{"x":468,"y":423},{"x":468,"y":394},{"x":468,"y":367},{"x":469,"y":345},{"x":472,"y":331},{"x":476,"y":321},{"x":480,"y":313},{"x":484,"y":307},{"x":490,"y":302},{"x":496,"y":298},{"x":503,"y":295},{"x":510,"y":293},{"x":515,"y":292},{"x":521,"y":292},{"x":526,"y":293},{"x":533,"y":295},{"x":540,"y":297},{"x":545,"y":299},{"x":551,"y":303},{"x":557,"y":307},{"x":563,"y":313},{"x":568,"y":320},{"x":572,"y":326},{"x":575,"y":333},{"x":578,"y":340},{"x":581,"y":349},{"x":583,"y":358},{"x":584,"y":369},{"x":584,"y":386},{"x":584,"y":399},{"x":584,"y":411},{"x":584,"y":421},{"x":584,"y":432},{"x":584,"y":443},{"x":584,"y":452},{"x":584,"y":466},{"x":583,"y":472},{"x":582,"y":475},{"x":578,"y":478},{"x":575,"y":480},{"x":569,"y":480},{"x":545,"y":480},{"x":514,"y":480},{"x":490,"y":480},{"x":474,"y":479},{"x":470,"y":476},{"x":468,"y":470},{"x":468,"y":452}],
				top:99,
				left:205,
				fill: fill,
				opacity: opacity
			});
			this.createMainShirtPolygon(this.__canvas,{point:[{"x":468,"y":555.5},{"x":584,"y":554.5},{"x":583,"y":562.5},{"x":575,"y":567.5},{"x":537,"y":567.5},{"x":501,"y":567.5},{"x":474,"y":567.5},{"x":470,"y":563.5},{"x":468,"y":558.5}],
				top:274,
				left:205,
				fill: fill,
				opacity: opacity
			});
		}

//แขนเสื้อด้านขวา
		if(side === 'right'){
			this.createMainShirtPolygon(this.__canvas,{point:[{"x":380,"y":444.5},{"x":380,"y":413.5},{"x":380,"y":387.5},{"x":381,"y":359.5},{"x":383,"y":346.5},{"x":385,"y":336.5},{"x":388,"y":327.5},{"x":392,"y":320.5},{"x":397,"y":314.5},{"x":402,"y":308.5},{"x":407,"y":304.5},{"x":413,"y":300.5},{"x":419,"y":297.5},{"x":427,"y":294.5},{"x":434,"y":292.5},{"x":441,"y":291.5},{"x":448,"y":291.5},{"x":458,"y":293.5},{"x":464,"y":296.5},{"x":469,"y":299.5},{"x":474,"y":303.5},{"x":478,"y":308.5},{"x":482,"y":314.5},{"x":485,"y":322.5},{"x":488,"y":331.5},{"x":490,"y":339.5},{"x":491,"y":350.5},{"x":492,"y":360.5},{"x":492,"y":372.5},{"x":492,"y":383.5},{"x":492,"y":415.5},{"x":492,"y":439.5},{"x":492,"y":462.5},{"x":492,"y":469.5},{"x":491,"y":474.5},{"x":489,"y":476.5},{"x":485,"y":479.5},{"x":462,"y":479.5},{"x":421,"y":481.5},{"x":388,"y":482.5},{"x":384,"y":480.5},{"x":380,"y":476.5},{"x":380,"y":471.5}],
				top:98,
				left:162,
				opacity: 0.1,
				fill: 'blue'
			});
			this.createMainShirtPolygon(this.__canvas,{point:[{"x":388,"y":570.5},{"x":384,"y":568.5},{"x":380,"y":565.5},{"x":380,"y":561.5},{"x":380,"y":557.5},{"x":492,"y":555.5},{"x":492,"y":559.5},{"x":490,"y":564.5},{"x":485,"y":568.5},{"x":452,"y":569.5},{"x":411,"y":570.5}],
				top:274,
				left:162,
				opacity: 0.1,
				fill: 'green'
			});
		}
	}

	createMainShirtPolygon(canvas, points, options) {
		if(!points){return null;}
		let _polygon = this.createPolygonFormPoint(points.point,
			{
				stroke: '#333333',
				strokeWidth: 0,
				fill: points.fill || "#47e221",
				opacity: points.opacity || 1,
				hasBorders: false,
				hasControls: false,
				top: points.top,
				left: points.left,
				cacheKey: 'added_polygon',
				draggable: false,
				lockMovementY: true,
				lockRotation: true,
				lockMovementX: true,
				lockScalingX: true,
				lockScalingY: true,
				lockSkewingX: true,
				lockSkewingY: true,
				lockUniScaling: true,
				selectable: false,
				evented: false,
				custom_unique_key: options && options.add_point ? "added_polygon" : "polygon_fixed"
			});
		this.addCanvas(_polygon, {send_to_back: true});
		canvas.getObjects().forEach((obj, obj_i) => {
			if (obj.custom_unique_key === 'added_polygon') {
				canvas.remove(obj)
			}
		});
		if (options && options.add_point) {
			let polygonCenter = _polygon.getCenterPoint();
			_polygon.points.forEach((_point) => {
				this.Polygon().polygon.addPoint({
					x: _point.x - polygonCenter.x,
					y: _point.y - polygonCenter.y,
					top: options.top,
					left: options.left,
					opacity: 0.3,
					add_by_point: true
				})
			})
		}
		canvas.getObjects().forEach((obj) => {
			if (obj.cacheKey && obj.cacheKey === 'default') {
				canvas.sendToBack(obj);
			}
		});
	}

	createPolygonFormPoint(points, options) {
		return new window.fabric.Polygon(points, options);
	}


	Polygon() {
		let min = 99;
		let max = 999999;
		let polygonMode = true;
		let pointArray = [];
		let lineArray = [];
		let activeLine;
		let activeShape = false;
		let canvasActiveObj = false;
		let canvas = this.__canvas;
		let prototypefabric = new function () {
			this.initCanvas = () => {
				window.$('.success-create-polygon').click(() => {
					let _confirm = window.confirm("Confirm GeneratePolygon");
					if (!_confirm) {
						return false;
					}
					prototypefabric.polygon.generatePolygon(pointArray);
					prototypefabric.polygon.setPointDefault()
				});
				canvas.on('mouse:down', function (options) {
					if (!polygonMode && canvas.getActiveObject()) {
						prototypefabric.polygon.setPointDefault();
						canvas.getActiveObject().set("fill", "blue");
						// canvas.getActiveObject().set("radius", 3);
						canvas.getActiveObject().set("opacity", 1);
						canvasActiveObj = canvas.getActiveObject();
					}

					if (polygonMode && options.target && options.target.id && pointArray.length > 0 && options.target.id === pointArray[0].id) {
						prototypefabric.polygon.generatePolygon(pointArray);
					}
					// options.target
					if (polygonMode) {
						prototypefabric.polygon.addPoint(options);
					}
				});
				canvas.on('mouse:up', function (options) {
					if (!polygonMode) {
						canvasActiveObj = false;
						prototypefabric.polygon.setPointDefault()
					}
				});

				canvas.on('mouse:move', function (options) {
					if (!polygonMode && canvasActiveObj && canvas.getActiveObject()) {
						canvasActiveObj.set("left", (options.e.layerX / canvas.getZoom()));
						canvasActiveObj.set("top", (options.e.layerY / canvas.getZoom()));
						let points_a = [];
						canvas.getObjects().forEach((obj) => {
							if (obj.custom_unique_key === 'polygon') {
								canvas.remove(obj);
							}
							if (obj.custom_unique_key === 'point') {
								points_a.push(obj);
							}
						});
						prototypefabric.polygon.generatePolygon(points_a);
					}
					if (activeLine && activeLine.class === "line") {
						let pointer = canvas.getPointer(options.e);
						activeLine.set({x2: pointer.x, y2: pointer.y, custom_unique_key: 'preview-line'});
						let points = [];
						if (activeShape) {
							points = activeShape.get("points");
							points[pointArray.length] = {
								x: pointer.x,
								y: pointer.y
							};
							activeShape.set({
								points: points
							});
						}

						canvas.renderAll();
					}

					canvas.renderAll();
				});
				window.document.onkeydown = function (e) {
					var evtobj = window.event ? window.event : e;
					if (evtobj.keyCode === 88 && !evtobj.ctrlKey) {
						canvas.getObjects().forEach((obj) => {
							if (obj.custom_unique_key === 'point' || obj.custom_unique_key === 'line') {
								obj.set("opacity", 0);
							}
						});
					}
					if (evtobj.keyCode === 67 && !evtobj.ctrlKey) {
						canvas.getObjects().forEach((obj) => {
							if (obj.custom_unique_key === 'point' || obj.custom_unique_key === 'line') {
								obj.set("opacity", 1);
							}
						});
					}
					if (evtobj.keyCode === 90 && evtobj.ctrlKey) {
						pointArray.pop();
						lineArray.pop();
						if (activeShape) {
							activeShape.points.pop();
						}
						activeLine = lineArray[lineArray.length - 1];
						if (canvas.getObjects().length > 1) {
							let get_point = [];
							let preview_line = [];
							canvas.getObjects().forEach((obj, obj_i) => {
								if (obj.custom_unique_key === 'preview-line') {
									preview_line.push(obj);
								}
								if (obj.custom_unique_key === 'point') {
									get_point.push(obj);
								}
							});
							canvas.remove(get_point[get_point.length - 1]);
							canvas.remove(preview_line[preview_line.length - 1]);
						}
						// canvas.renderAll();

					}
					;
				};
			};
		}();
		let _this = this;

		prototypefabric.polygon = {
			drawPolygon: function () {
				polygonMode = true;
				pointArray = [];
				lineArray = [];
				activeLine = false;
			},
			addPoint: function (options) {
				let left = 0;
				let top = 0;
				let custom_options = {};
				if (options && options.add_by_point) {
					left = options.x;
					top = options.y;
					custom_options.left = left;
					custom_options.top = top;
				}
				if (options && !options.add_by_point) {
					left = (options.e.layerX / canvas.getZoom());
					top = (options.e.layerY / canvas.getZoom());
					custom_options.left = left;
					custom_options.top = top;
				}

				let random = Math.floor(Math.random() * (max - min + 1)) + min;
				let id = new Date().getTime() + random;
				let circle = new window.fabric.Circle({
					...custom_options,
					radius: options && options.add_by_point ? 1 : 2,
					fill: '#ffffff',
					stroke: '#333333',
					strokeWidth: 0.5,
					opacity: 0.3,
					left: left,
					top: top,
					// selectable: false,
					hasBorders: false,
					hasControls: false,
					originX: 'center',
					originY: 'center',
					id: id,
					objectCaching: false,
					cursorStyle: 'default',
					custom_unique_key: 'point'
				});
				if (pointArray.length === 0) {
					circle.set({
						cursorStyle: 'pointer',
						fill: 'red',
						opacity: 0.3,
					})
				}
				let points = [(left / canvas.getZoom()), (top / canvas.getZoom()), (left / canvas.getZoom()), (top / canvas.getZoom())];
				let line = new window.fabric.Line(points, {
					strokeWidth: 1,
					fill: 'back',
					stroke: 'back',
					class: 'line',
					opacity: 0.5,
					originX: 'center',
					originY: 'center',
					selectable: false,
					hasBorders: false,
					hasControls: false,
					evented: false,
					objectCaching: false,
					custom_unique_key: 'selected-line'
				});
				if (activeShape) {
					let pos = canvas.getPointer(options.e);
					let points = activeShape.get("points");
					points.push({
						x: pos.x,
						y: pos.y
					});
					let polygon = new window.fabric.Polygon(points, {
						stroke: '#333333',
						strokeWidth: 1,
						fill: '#cccccc',
						opacity: 0.1,
						selectable: false,
						hasBorders: false,
						hasControls: false,
						evented: false,
						objectCaching: false
					});
					canvas.remove(activeShape);
					canvas.add(polygon);
					activeShape = polygon;
					canvas.renderAll();
				} else {
					let polyPoint = [{
						x: (left / canvas.getZoom()),
						y: (top / canvas.getZoom())
					}];
					let polygon = new window.fabric.Polygon(polyPoint, {
						stroke: '#333333',
						strokeWidth: 1,
						fill: '#cccccc',
						opacity: 0.3,
						selectable: false,
						hasBorders: false,
						hasControls: false,
						evented: false,
						objectCaching: false
					});
					activeShape = polygon;
					canvas.add(polygon);
				}
				activeLine = line;

				pointArray.push(circle);
				lineArray.push(line);

				canvas.add(line);
				canvas.add(circle);
				canvas.selection = false;
				if (options.add_by_point) {
					let points_a = [];
					canvas.getObjects().forEach((obj) => {
						if (obj.custom_unique_key === 'polygon') {
							canvas.remove(obj);
						}
						if (obj.custom_unique_key === 'point') {
							points_a.push(obj);
						}
					});

					prototypefabric.polygon.generatePolygon(points_a, {opacity: options.opacity});
				}
			},
			generatePolygon: function (pointArray, options) {
				let points = [];
				window.$.each(pointArray, function (index, point) {
					points.push({
						x: point.left,
						y: point.top,
						cacheKey: 'polygon_base',
					});
					// canvas.remove(point);
				});
				window.$.each(lineArray, function (index, line) {
					canvas.remove(line);
				});
				canvas.remove(activeShape).remove(activeLine);
				let polygon = new window.fabric.Polygon(points, {
					custom_unique_key: 'polygon',
					stroke: '#333333',
					strokeWidth: 0,
					fill: 'red',
					opacity: options && options.opacity ? options.opacity : 0.3,
					hasBorders: false,
					hasControls: false,
					draggable: false,
					lockMovementY: true,
					lockRotation: true,
					lockMovementX: true,
					lockScalingX: true,
					lockScalingY: true,
					lockSkewingX: true,
					lockSkewingY: true,
					lockUniScaling: true,
					selectable: false,
					evented: false,
				});
				canvas.add(polygon);


				let polygonCenter = polygon.getCenterPoint();

				let translatedPoints = polygon.get('points').map(function (p) {
					return {
						x: polygonCenter.x + p.x,
						y: polygonCenter.y + p.y
					};
				});
				_this.setState({
					top: polygon.top,
					left: polygon.left,
					translatedPoints: JSON.stringify(translatedPoints),
					is_create_polygon: false,
				});

				activeLine = null;
				activeShape = null;
				polygonMode = false;
				canvas.selection = true;
			},
			setPointDefault: () => {
				canvas.getObjects().forEach((obj) => {
					if (obj.custom_unique_key === 'point') {
						obj.set("fill", "#555");
						obj.set("opacity", 1);
						obj.bringToFront();
					}
				});
			}
		};
		prototypefabric.initCanvas();
		prototypefabric.polygon.drawPolygon();
		return prototypefabric;
	}

	addCanvas(object, options) {
		this.__canvas.add(object);
		if (options && options.active_object) {
			this.__canvas.setActiveObject(object);
		}
		if (options && options.send_to_back) {
			this.__canvas.sendToBack(object);
		}
		if (options && options.bring_to_front) {
			this.__canvas.bringToFront(object);
		}
	}

	componentDidMount() {
		this.CanvasRender();
		// this.Polygon();
		window.$(window).load(() => {
			window.$('.create-polygon').click(() => {
				this.setState({
					is_create_polygon: true
				}, () => {
					this.__canvas.getObjects().forEach((obj) => {
						if (obj.custom_unique_key === 'polygon' || obj.custom_unique_key === 'point') {
							this.__canvas.remove(obj);
						}
					});
					console.log(this.__canvas.getObjects());
					this.Polygon();
				})

			});
		});
	}

	render() {
		return (
			<>
				<div style={{paddingTop: '1rem'}}>
					<div className="ui grid">
						<div className={"column eight wide"}>
							<div className={"ui segment preview-image"}
							     style={{
								     display: 'flex',
								     justifyContent: 'center',
								     alignItems: 'center',
								     // backgroundColor: 'blue',
								     overflow: 'auto',
								     paddingTop: 200
							     }}>
								<canvas id="c" width={this.state.canvas_width} height={this.state.canvas_height}/>
							</div>
						</div>

						<div className={"column eight wide"}>
							<div>
								<button className={"ui button blue create-polygon mini"}>
									Start Create Polygon
								</button>
								<button className={"ui button green success-create-polygon mini"}>
									Generate Polygon
								</button>
							</div>
							{"{"}
							{this.state.translatedPoints ? 'point:' + this.state.translatedPoints + ',' : null}
							<div>
								{this.state.top ? 'top:' + this.state.top + ',' : null}
							</div>
							<div>
								{this.state.left ? 'left:' + this.state.left + ',' : null}
							</div>
							{"}"}
						</div>
					</div>
				</div>
			</>
		);
	}
}