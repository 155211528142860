export const collar_data = [
	{
		"id": 1,
		"title": "รูปแบบ 1",
		"image": {
			url: "./images/shirt_type/collar_style/1.png"
		},
		"type": [
			{
				"id": 1,
				"title": "ปกเสื้อ",
				"image": {
					"url": "./images/shirt_type/collar_style/1.png"
				},
				"canvas_obj": {
					"front": [
						{point:[{"x":600.875,"y":151.375},{"x":570.875,"y":145.375},{"x":546.875,"y":137.375},{"x":535.625,"y":132.125},{"x":531.875,"y":128.375},{"x":539.875,"y":117.375},{"x":545.875,"y":107.375},{"x":453.875,"y":107.375},{"x":460.875,"y":118.375},{"x":468.625,"y":129.125},{"x":449.625,"y":140.125},{"x":427.625,"y":148.125},{"x":401.625,"y":155.125},{"x":406.625,"y":135.125},{"x":408.625,"y":126.125},{"x":411.625,"y":117.125},{"x":416.625,"y":113.125},{"x":428.875,"y":109.375},{"x":435.625,"y":104.125},{"x":439.875,"y":97.375},{"x":441.625,"y":91.125},{"x":443.625,"y":86.125},{"x":447.625,"y":81.125},{"x":453.875,"y":78.375},{"x":462.875,"y":76.375},{"x":467.875,"y":75.375},{"x":475.875,"y":74.375},{"x":483.875,"y":73.375},{"x":489.875,"y":73.375},{"x":496.875,"y":73.375},{"x":502.875,"y":73.375},{"x":510.875,"y":73.375},{"x":522.625,"y":74.125},{"x":527.625,"y":74.125},{"x":534.625,"y":75.125},{"x":541.625,"y":76.125},{"x":547.625,"y":78.125},{"x":552.625,"y":80.125},{"x":557.625,"y":84.125},{"x":560.625,"y":88.125},{"x":562.625,"y":92.125},{"x":564.625,"y":97.125},{"x":568.625,"y":100.125},{"x":573.625,"y":103.125},{"x":579.875,"y":106.375},{"x":583.875,"y":109.375},{"x":590.875,"y":123.375},{"x":596.875,"y":138.375}],
							top:16.25,
							left:151,
						}
					],
					"back": [
						{point:[{"x":409.875,"y":91.375},{"x":415.875,"y":84.375},{"x":421.875,"y":77.375},{"x":425.875,"y":69.375},{"x":428.625,"y":63.125},{"x":430.625,"y":60.125},{"x":433.625,"y":57.125},{"x":437.625,"y":55.125},{"x":442.875,"y":53.375},{"x":528.875,"y":52.375},{"x":543.625,"y":54.125},{"x":548.625,"y":56.125},{"x":552.875,"y":60.375},{"x":555.625,"y":64.125},{"x":558.875,"y":72.375},{"x":561.625,"y":79.125},{"x":564.625,"y":83.125},{"x":568.875,"y":87.375},{"x":572.625,"y":92.125},{"x":562.625,"y":88.125},{"x":552.625,"y":86.125},{"x":542.625,"y":85.125},{"x":439.875,"y":85.375},{"x":429.875,"y":86.375},{"x":421.875,"y":87.375},{"x":415.875,"y":89.375}],
							top:16.25,
							left:164.25,
						}
					],
					"left": [
						{point:[{"x":394.5,"y":176},{"x":405.5,"y":171},{"x":417.5,"y":163},{"x":424.5,"y":157},{"x":434.5,"y":149},{"x":441.5,"y":144},{"x":455.5,"y":135},{"x":469.5,"y":126},{"x":479.5,"y":121},{"x":490.5,"y":115},{"x":497.5,"y":111},{"x":497.5,"y":108},{"x":492.5,"y":99},{"x":491.5,"y":97},{"x":491.5,"y":93},{"x":491.5,"y":89},{"x":489.5,"y":85},{"x":486.5,"y":84},{"x":481.5,"y":85},{"x":472.5,"y":88},{"x":451.5,"y":99},{"x":435.5,"y":110},{"x":408.5,"y":131},{"x":391.5,"y":150},{"x":389.5,"y":154},{"x":388.5,"y":159}],
							top:18.75,
							left:166.75,
						}
					],
					"right": [
						{point:[{"x":563.5,"y":168},{"x":545.5,"y":157},{"x":527.5,"y":144},{"x":505.5,"y":129},{"x":480.5,"y":115},{"x":468.5,"y":107},{"x":468.5,"y":104},{"x":473.5,"y":95},{"x":474.5,"y":87},{"x":475.5,"y":83},{"x":477.5,"y":81},{"x":481.5,"y":80},{"x":484.5,"y":81},{"x":498.5,"y":87},{"x":519.5,"y":100},{"x":536.5,"y":111},{"x":554.5,"y":126},{"x":563.5,"y":135},{"x":573.5,"y":147},{"x":576.5,"y":152},{"x":577.5,"y":157}],
							top:17.75,
							left:206.75,
						}
					]
				}
			}
		]
	},

]