export const main_sleeve = {
	"front": [
		{point:[{"x":140.25,"y":281.625},{"x":141.25,"y":298.625},{"x":142.25,"y":309.625},{"x":144.25,"y":320.625},{"x":147.25,"y":333.625},{"x":151.25,"y":347.625},{"x":155.25,"y":361.625},{"x":159.25,"y":377.625},{"x":163.5,"y":392.875},{"x":166.5,"y":406.875},{"x":170.5,"y":433.875},{"x":172.5,"y":451.875},{"x":174.5,"y":462.875},{"x":176.5,"y":476.875},{"x":168.5,"y":472.875},{"x":160.5,"y":471.875},{"x":149.5,"y":470.875},{"x":137.5,"y":469.875},{"x":128.5,"y":467.875},{"x":119.5,"y":465.875},{"x":111.5,"y":462.875},{"x":101.5,"y":459.875},{"x":93.5,"y":455.875},{"x":83.5,"y":451.875},{"x":75.5,"y":447.875},{"x":68.5,"y":443.875},{"x":68.5,"y":441.875},{"x":76.5,"y":422.875},{"x":88.5,"y":378.875},{"x":95.5,"y":352.875},{"x":103.5,"y":328.875},{"x":109.5,"y":314.875},{"x":115.5,"y":303.875},{"x":124.5,"y":291.875}],
			top:92,
			left:7.25,
		},
		{point:[{"x":863.75,"y":264.125},{"x":861.75,"y":277.125},{"x":859.75,"y":287.125},{"x":857.75,"y":295.125},{"x":855.75,"y":303.125},{"x":852.75,"y":314.125},{"x":848.75,"y":326.125},{"x":845.75,"y":338.125},{"x":841.75,"y":351.125},{"x":837.75,"y":368.125},{"x":835,"y":382.375},{"x":833,"y":456.375},{"x":838,"y":454.375},{"x":842,"y":453.375},{"x":847,"y":453.375},{"x":854,"y":452.375},{"x":859,"y":452.375},{"x":868,"y":451.375},{"x":876,"y":450.375},{"x":883,"y":448.375},{"x":894,"y":445.375},{"x":901,"y":442.375},{"x":908,"y":439.375},{"x":914,"y":436.375},{"x":923,"y":432.375},{"x":930,"y":428.375},{"x":934,"y":425.375},{"x":929,"y":414.375},{"x":924,"y":400.375},{"x":919,"y":381.375},{"x":912,"y":353.375},{"x":908,"y":339.375},{"x":902,"y":321.375},{"x":897,"y":307.375},{"x":892,"y":295.375},{"x":887,"y":286.375},{"x":881,"y":278.375}],
			top:84,
			left:391.25,
		}
	],
	"back": [
		{point:[{"x":65.5,"y":442},{"x":70.5,"y":429},{"x":74.5,"y":420},{"x":83.5,"y":395},{"x":98.5,"y":344},{"x":109.5,"y":314},{"x":121.5,"y":291},{"x":126.5,"y":284},{"x":131.5,"y":279},{"x":139.5,"y":273},{"x":143.5,"y":290},{"x":148.5,"y":310},{"x":152.5,"y":326},{"x":157.5,"y":342},{"x":163.5,"y":361},{"x":165.5,"y":380},{"x":169.5,"y":418},{"x":172.5,"y":444},{"x":176.5,"y":466},{"x":171.5,"y":471},{"x":165.5,"y":475},{"x":159.5,"y":478},{"x":153.5,"y":479},{"x":145.5,"y":479},{"x":138.5,"y":478},{"x":130.5,"y":476},{"x":120.5,"y":472},{"x":108.5,"y":468},{"x":94.5,"y":461},{"x":79.5,"y":452}],
			top:85,
			left:5,
		},
		{point:[{"x":929.5,"y":450.5},{"x":924.5,"y":436.5},{"x":920.5,"y":423.5},{"x":906.5,"y":382.5},{"x":894.5,"y":345.5},{"x":884.5,"y":317.5},{"x":875.5,"y":301.5},{"x":865.5,"y":289.5},{"x":855.5,"y":282.5},{"x":851.5,"y":298.5},{"x":846.5,"y":314.5},{"x":841.5,"y":330.5},{"x":836.5,"y":345.5},{"x":831.5,"y":360.5},{"x":828.5,"y":378.5},{"x":825.5,"y":405.5},{"x":824.5,"y":424.5},{"x":821.5,"y":452.5},{"x":820.5,"y":473.5},{"x":824.5,"y":478.5},{"x":830.5,"y":482.5},{"x":836.5,"y":485.5},{"x":843.5,"y":487.5},{"x":849.5,"y":487.5},{"x":857.5,"y":486.5},{"x":864.5,"y":484.5},{"x":874.5,"y":480.5},{"x":890.5,"y":474.5},{"x":904.5,"y":466.5},{"x":919.5,"y":457.5}],
			top:90,
			left:383,
		}
	],
	"left": [
		{point:[{"x":468,"y":423},{"x":468,"y":394},{"x":468,"y":367},{"x":469,"y":345},{"x":472,"y":331},{"x":476,"y":321},{"x":480,"y":313},{"x":484,"y":307},{"x":490,"y":302},{"x":496,"y":298},{"x":503,"y":295},{"x":510,"y":293},{"x":515,"y":292},{"x":521,"y":292},{"x":526,"y":293},{"x":533,"y":295},{"x":540,"y":297},{"x":545,"y":299},{"x":551,"y":303},{"x":557,"y":307},{"x":563,"y":313},{"x":568,"y":320},{"x":572,"y":326},{"x":575,"y":333},{"x":578,"y":340},{"x":581,"y":349},{"x":583,"y":358},{"x":584,"y":369},{"x":584,"y":386},{"x":584,"y":399},{"x":584,"y":411},{"x":584,"y":421},{"x":584,"y":432},{"x":584,"y":443},{"x":584,"y":452},{"x":584,"y":466},{"x":583,"y":472},{"x":582,"y":475},{"x":578,"y":478},{"x":575,"y":480},{"x":569,"y":480},{"x":545,"y":480},{"x":514,"y":480},{"x":490,"y":480},{"x":474,"y":479},{"x":470,"y":476},{"x":468,"y":470},{"x":468,"y":452}],
			top:99,
			left:205,
		}
	],
	"right": [
		{point:[{"x":380,"y":444.5},{"x":380,"y":413.5},{"x":380,"y":387.5},{"x":381,"y":359.5},{"x":383,"y":346.5},{"x":385,"y":336.5},{"x":388,"y":327.5},{"x":392,"y":320.5},{"x":397,"y":314.5},{"x":402,"y":308.5},{"x":407,"y":304.5},{"x":413,"y":300.5},{"x":419,"y":297.5},{"x":427,"y":294.5},{"x":434,"y":292.5},{"x":441,"y":291.5},{"x":448,"y":291.5},{"x":458,"y":293.5},{"x":464,"y":296.5},{"x":469,"y":299.5},{"x":474,"y":303.5},{"x":478,"y":308.5},{"x":482,"y":314.5},{"x":485,"y":322.5},{"x":488,"y":331.5},{"x":490,"y":339.5},{"x":491,"y":350.5},{"x":492,"y":360.5},{"x":492,"y":372.5},{"x":492,"y":383.5},{"x":492,"y":415.5},{"x":492,"y":439.5},{"x":492,"y":462.5},{"x":492,"y":469.5},{"x":491,"y":474.5},{"x":489,"y":476.5},{"x":485,"y":479.5},{"x":462,"y":479.5},{"x":421,"y":481.5},{"x":388,"y":482.5},{"x":384,"y":480.5},{"x":380,"y":476.5},{"x":380,"y":471.5}],
			top:98,
			left:162,
		}
	]
}