import {main_sleeve} from './main_sleeve_polygon_point_prototype'
export const sleeve_data = [
	{
		"id": 1,
		"title": "รูปแบบ 1",
		"image": {
			"url": "./images/shirt_type/sleeve_style/1.png"
		},
		"type": [
			{
				"id": '1-1',
				"title": "สาบเสื้อ รูปแบบ 1 ลายที่ 1",
				"image": {
					"url": "./images/shirt_type/sleeve_style/1-1.png"
				},
				"canvas_obj": {
					"front": [
						{point:[{"x":40.5,"y":497},{"x":54.5,"y":504},{"x":66.5,"y":510},{"x":78.5,"y":515},{"x":72.5,"y":527},{"x":59.5,"y":522},{"x":50.5,"y":518},{"x":41.5,"y":513},{"x":35.5,"y":509}],
							top:240.75,
							left:6.75,
						},
						{point:[{"x":955.5,"y":482},{"x":945.5,"y":489},{"x":930.5,"y":496},{"x":917.5,"y":501},{"x":921.5,"y":514},{"x":936.5,"y":507},{"x":951.5,"y":500},{"x":960.5,"y":494}],
							top:232.75,
							left:447.75,
						}
					],
					"back": [
						{point:[{"x":28.5,"y":511},{"x":33.5,"y":500},{"x":45.5,"y":509},{"x":55.5,"y":515},{"x":65.5,"y":521},{"x":59.5,"y":532},{"x":47.5,"y":524},{"x":36.5,"y":517}],
							top:242,
							left:5,
						},
						{point:[{"x":962,"y":507},{"x":967,"y":518},{"x":961,"y":523},{"x":952,"y":529},{"x":938,"y":537},{"x":933,"y":527},{"x":945,"y":520},{"x":954,"y":514}],
							top:246,
							left:458,
						}
					],
					"left": [
						{point:[{"x":468,"y":555.5},{"x":584,"y":554.5},{"x":583,"y":562.5},{"x":575,"y":567.5},{"x":537,"y":567.5},{"x":501,"y":567.5},{"x":474,"y":567.5},{"x":470,"y":563.5},{"x":468,"y":558.5}],
							top:274,
							left:205,
						}
					],
					"right": [
						{point:[{"x":388,"y":570.5},{"x":384,"y":568.5},{"x":380,"y":565.5},{"x":380,"y":561.5},{"x":380,"y":557.5},{"x":492,"y":555.5},{"x":492,"y":559.5},{"x":490,"y":564.5},{"x":485,"y":568.5},{"x":452,"y":569.5},{"x":411,"y":570.5}],
							top:274,
							left:162,
						}
					]
				}
			},
			{
				"id": '1-2',
				"title": "สาบเสื้อ รูปแบบ 1 ลายที่ 2",
				"image": {
					"url": "./images/shirt_type/sleeve_style/1-2.png"
				},
				"canvas_obj": main_sleeve
			}

		]
	},
	{
		"id": 2,
		"title": "รูปแบบ 2",
		"image": {
			"url": "./images/shirt_type/sleeve_style/2.png"
		},
		"type": [
			{
				"id":'2-1',
				"title": "สาบเสื้อ รูปแบบ 2 ลาย 1",
				"image": {
					"url": "./images/shirt_type/sleeve_style/2-1.png"
				},
				"canvas_obj": {
					"front": [
						{point:[{"x":76.5,"y":491},{"x":74.5,"y":495},{"x":82.5,"y":500},{"x":95.5,"y":506},{"x":106.5,"y":510},{"x":119.5,"y":515},{"x":133.5,"y":519},{"x":146.5,"y":522},{"x":157.5,"y":524},{"x":166.5,"y":525},{"x":175.5,"y":525},{"x":174.5,"y":521},{"x":167.5,"y":521},{"x":157.5,"y":520},{"x":147.5,"y":518},{"x":135.5,"y":515},{"x":122.5,"y":511},{"x":108.5,"y":506},{"x":98.5,"y":502},{"x":85.5,"y":496}],
							top:237,
							left:12,
						},
						{point:[{"x":926.5,"y":477},{"x":924.5,"y":473},{"x":913.5,"y":479},{"x":901.5,"y":485},{"x":884.5,"y":491},{"x":866.5,"y":496},{"x":849.5,"y":500},{"x":831.5,"y":502},{"x":831.5,"y":507},{"x":851.5,"y":505},{"x":868.5,"y":501},{"x":886.5,"y":495},{"x":904.5,"y":489},{"x":915.5,"y":483}],
							top:228,
							left:392,
						}
					],
					"back": [
						{point:[{"x":922,"y":502},{"x":920,"y":499},{"x":906,"y":509},{"x":895,"y":516},{"x":882,"y":523},{"x":869,"y":528},{"x":855,"y":532},{"x":845,"y":533},{"x":834,"y":533},{"x":824,"y":532},{"x":818,"y":530},{"x":818,"y":534},{"x":824,"y":536},{"x":833,"y":537},{"x":843,"y":537},{"x":856,"y":536},{"x":870,"y":532},{"x":885,"y":526},{"x":897,"y":519},{"x":909,"y":512}],
							top:240,
							left:383,
						},
						{point:[{"x":72.5,"y":499},{"x":73.5,"y":495},{"x":87.5,"y":505},{"x":98.5,"y":512},{"x":108.5,"y":517},{"x":122.5,"y":523},{"x":136.5,"y":527},{"x":150.5,"y":529},{"x":165.5,"y":529},{"x":176.5,"y":527},{"x":177.5,"y":531},{"x":164.5,"y":533},{"x":149.5,"y":533},{"x":134.5,"y":531},{"x":120.5,"y":527},{"x":106.5,"y":521},{"x":95.5,"y":515},{"x":83.5,"y":507}],
							top:238,
							left:10,
						}
					],
					"left": [
						{point:[{"x":584,"y":545.5},{"x":584,"y":539.5},{"x":468,"y":539.5},{"x":468,"y":546.5}],
							top:268,
							left:205,
						}
					],
					"right": [
						{point:[{"x":380,"y":548},{"x":380,"y":542},{"x":492,"y":540},{"x":492,"y":546}],
							top:268,
							left:162,
						}
					]
				}
			},
			{
				"id": '2-2',
				"title": "สาบเสื้อ รูปแบบ 2 ลายที่ 2",
				"image": {
					"url": "./images/shirt_type/sleeve_style/1-2.png"
				},
				"canvas_obj": main_sleeve
			}
		]
	},
	{
		"id": 3,
		"title": "รูปแบบ 3",
		"image": {
			"url": "./images/shirt_type/sleeve_style/3.png"
		},
		"type": [
			{
				"id": '3-1',
				"title": "สาบเสื้อ รูปแบบ 3 ลาย 1",
				"image": {
					"url": "./images/shirt_type/sleeve_style/3-1.png"
				},
				"canvas_obj": {
					"front": [
						{point:[{"x":79,"y":528.5},{"x":68,"y":521.5},{"x":68,"y":519.5},{"x":70,"y":515.5},{"x":82,"y":522.5},{"x":102,"y":531.5},{"x":122,"y":538.5},{"x":145,"y":542.5},{"x":166,"y":546.5},{"x":175,"y":548.5},{"x":176,"y":554.5},{"x":168,"y":552.5},{"x":144,"y":548.5},{"x":118,"y":543.5},{"x":101,"y":537.5}],
							top:248,
							left:7,
						},
						{point:[{"x":933.5,"y":505},{"x":931.5,"y":500},{"x":913.5,"y":509},{"x":894.5,"y":518},{"x":874.5,"y":524},{"x":857.5,"y":527},{"x":846.5,"y":529},{"x":832.5,"y":531},{"x":832.5,"y":536},{"x":846.5,"y":534},{"x":858.5,"y":532},{"x":876.5,"y":529},{"x":897.5,"y":523},{"x":918.5,"y":513}],
							top:241,
							left:391,
						}
					],
					"back": [
						{point:[{"x":64.5,"y":524.5},{"x":81.5,"y":535.5},{"x":99.5,"y":546.5},{"x":114.5,"y":553.5},{"x":131.5,"y":558.5},{"x":143.5,"y":561.5},{"x":151.5,"y":561.5},{"x":157.5,"y":560.5},{"x":163.5,"y":558.5},{"x":170.5,"y":556.5},{"x":177.5,"y":553.5},{"x":176.5,"y":548.5},{"x":168.5,"y":551.5},{"x":162.5,"y":553.5},{"x":155.5,"y":555.5},{"x":149.5,"y":556.5},{"x":142.5,"y":556.5},{"x":132.5,"y":554.5},{"x":116.5,"y":549.5},{"x":100.5,"y":541.5},{"x":85.5,"y":532.5},{"x":66.5,"y":520.5}],
							top:250,
							left:4,
						},
						{point:[{"x":929.5,"y":533},{"x":912.5,"y":544},{"x":898.5,"y":553},{"x":885.5,"y":559},{"x":868.5,"y":565},{"x":853.5,"y":568},{"x":844.5,"y":568},{"x":836.5,"y":567},{"x":828.5,"y":565},{"x":821.5,"y":562},{"x":820.5,"y":555},{"x":827.5,"y":559},{"x":837.5,"y":561},{"x":844.5,"y":562},{"x":852.5,"y":562},{"x":863.5,"y":560},{"x":881.5,"y":555},{"x":895.5,"y":549},{"x":908.5,"y":541},{"x":928.5,"y":528}],
							top:254,
							left:383,
						}
					],
					"left": [
						{point:[{"x":473,"y":570.5},{"x":470,"y":567.5},{"x":469,"y":563.5},{"x":583,"y":563.5},{"x":580,"y":567.5},{"x":575,"y":570.5}],
							top:280,
							left:206,
						}
					],
					"right": [
						{point:[{"x":387,"y":573},{"x":383,"y":571},{"x":380,"y":567},{"x":380,"y":565},{"x":492,"y":563},{"x":489,"y":568},{"x":485,"y":571}],
							top:279,
							left:162,
						}
					]
				}
			},
			{
				"id": '3-2',
				"title": "สาบเสื้อ รูปแบบ 3 ลายที่ 2",
				"image": {
					"url": "./images/shirt_type/sleeve_style/1-2.png"
				},
				"canvas_obj": main_sleeve
			}
		]
	},
	{
		"id": 4,
		"title": "รูปแบบ 4",
		"image": {
			"url": "./images/shirt_type/sleeve_style/4.png"
		},
		"type": [
			{
				"id": '4-1',
				"title": "สาบเสื้อ รูปแบบ 4 ลาย 1",
				"image": {
					"url": "./images/shirt_type/sleeve_style/3-1.png"
				},
				"canvas_obj": {
					"front": [
						{point:[{"x":79,"y":528.5},{"x":68,"y":521.5},{"x":68,"y":519.5},{"x":70,"y":515.5},{"x":82,"y":522.5},{"x":102,"y":531.5},{"x":122,"y":538.5},{"x":145,"y":542.5},{"x":166,"y":546.5},{"x":175,"y":548.5},{"x":176,"y":554.5},{"x":168,"y":552.5},{"x":144,"y":548.5},{"x":118,"y":543.5},{"x":101,"y":537.5}],
							top:248,
							left:7,
						},
						{point:[{"x":933.5,"y":505},{"x":931.5,"y":500},{"x":913.5,"y":509},{"x":894.5,"y":518},{"x":874.5,"y":524},{"x":857.5,"y":527},{"x":846.5,"y":529},{"x":832.5,"y":531},{"x":832.5,"y":536},{"x":846.5,"y":534},{"x":858.5,"y":532},{"x":876.5,"y":529},{"x":897.5,"y":523},{"x":918.5,"y":513}],
							top:241,
							left:391,
						}
					],
					"back": [
						{point:[{"x":64.5,"y":524.5},{"x":81.5,"y":535.5},{"x":99.5,"y":546.5},{"x":114.5,"y":553.5},{"x":131.5,"y":558.5},{"x":143.5,"y":561.5},{"x":151.5,"y":561.5},{"x":157.5,"y":560.5},{"x":163.5,"y":558.5},{"x":170.5,"y":556.5},{"x":177.5,"y":553.5},{"x":176.5,"y":548.5},{"x":168.5,"y":551.5},{"x":162.5,"y":553.5},{"x":155.5,"y":555.5},{"x":149.5,"y":556.5},{"x":142.5,"y":556.5},{"x":132.5,"y":554.5},{"x":116.5,"y":549.5},{"x":100.5,"y":541.5},{"x":85.5,"y":532.5},{"x":66.5,"y":520.5}],
							top:250,
							left:4,
						},
						{point:[{"x":929.5,"y":533},{"x":912.5,"y":544},{"x":898.5,"y":553},{"x":885.5,"y":559},{"x":868.5,"y":565},{"x":853.5,"y":568},{"x":844.5,"y":568},{"x":836.5,"y":567},{"x":828.5,"y":565},{"x":821.5,"y":562},{"x":820.5,"y":555},{"x":827.5,"y":559},{"x":837.5,"y":561},{"x":844.5,"y":562},{"x":852.5,"y":562},{"x":863.5,"y":560},{"x":881.5,"y":555},{"x":895.5,"y":549},{"x":908.5,"y":541},{"x":928.5,"y":528}],
							top:254,
							left:383,
						}
					],
					"left": [
						{point:[{"x":473,"y":570.5},{"x":470,"y":567.5},{"x":469,"y":563.5},{"x":583,"y":563.5},{"x":580,"y":567.5},{"x":575,"y":570.5}],
							top:280,
							left:206,
						}
					],
					"right": [
						{point:[{"x":387,"y":573},{"x":383,"y":571},{"x":380,"y":567},{"x":380,"y":565},{"x":492,"y":563},{"x":489,"y":568},{"x":485,"y":571}],
							top:279,
							left:162,
						}
					]
				}
			},
			{
				"id": '4-2',
				"title": "สาบเสื้อ รูปแบบ 4 ลาย 2",
				"image": {
					"url": "./images/shirt_type/sleeve_style/4-2.png"
				},
				"canvas_obj": {
					"front": [
						{point:[{"x":820.5,"y":348},{"x":823.5,"y":331},{"x":829.5,"y":307},{"x":834.5,"y":290},{"x":841.5,"y":267},{"x":845.5,"y":252},{"x":848.5,"y":235},{"x":849.5,"y":228},{"x":858.5,"y":235},{"x":866.5,"y":242},{"x":871.5,"y":248},{"x":877.5,"y":259},{"x":884.5,"y":276},{"x":889.5,"y":290},{"x":869.5,"y":306},{"x":842.5,"y":328}],
							top:84,
							left:393,
						},
						{point:[{"x":149.5,"y":247.5},{"x":150.5,"y":268.5},{"x":152.5,"y":283.5},{"x":160.5,"y":313.5},{"x":171.5,"y":353.5},{"x":176.5,"y":374.5},{"x":162.5,"y":361.5},{"x":129.5,"y":331.5},{"x":105.5,"y":315.5},{"x":112.5,"y":294.5},{"x":119.5,"y":279.5},{"x":125.5,"y":268.5},{"x":133.5,"y":257.5}],
							top:92,
							left:35,
						}
					],
					"back": [
						{point:[{"x":150,"y":228},{"x":154,"y":245},{"x":163,"y":282},{"x":174,"y":315},{"x":175,"y":327},{"x":177,"y":344},{"x":158,"y":330},{"x":131,"y":313},{"x":107,"y":304},{"x":116,"y":279},{"x":121,"y":267},{"x":131,"y":247},{"x":140,"y":235}],
							top:85,
							left:36,
						},
						{point:[{"x":812.5,"y":352.5},{"x":814.5,"y":333.5},{"x":817.5,"y":315.5},{"x":823.5,"y":297.5},{"x":829.5,"y":278.5},{"x":835.5,"y":260.5},{"x":838.5,"y":249.5},{"x":841.5,"y":237.5},{"x":851.5,"y":244.5},{"x":861.5,"y":256.5},{"x":869.5,"y":270.5},{"x":873.5,"y":280.5},{"x":876.5,"y":289.5},{"x":881.5,"y":302.5},{"x":865.5,"y":310.5},{"x":838.5,"y":331.5}],
							top:90,
							left:389,
						}
					],
					"left": [
						{point:[{"x":468,"y":349.5},{"x":473,"y":342.5},{"x":477,"y":336.5},{"x":482,"y":330.5},{"x":486,"y":325.5},{"x":492,"y":319.5},{"x":497,"y":314.5},{"x":504,"y":308.5},{"x":511,"y":303.5},{"x":517,"y":299.5},{"x":525,"y":295.5},{"x":535,"y":301.5},{"x":540,"y":305.5},{"x":547,"y":311.5},{"x":553,"y":317.5},{"x":558,"y":322.5},{"x":563,"y":327.5},{"x":568,"y":332.5},{"x":573,"y":337.5},{"x":578,"y":342.5},{"x":584,"y":347.5},{"x":584,"y":337.5},{"x":584,"y":325.5},{"x":582,"y":307.5},{"x":579,"y":300.5},{"x":575,"y":288.5},{"x":569,"y":277.5},{"x":563,"y":269.5},{"x":556,"y":262.5},{"x":545,"y":255.5},{"x":536,"y":251.5},{"x":524,"y":248.5},{"x":515,"y":248.5},{"x":502,"y":251.5},{"x":491,"y":257.5},{"x":484,"y":263.5},{"x":477,"y":275.5},{"x":472,"y":286.5},{"x":470,"y":296.5},{"x":469,"y":304.5},{"x":468,"y":316.5},{"x":468,"y":334.5}],
							top:99,
							left:205,
						}
					],
					"right": [
						{point:[{"x":380,"y":350.5},{"x":380,"y":336.5},{"x":381,"y":321.5},{"x":382,"y":308.5},{"x":385,"y":294.5},{"x":388,"y":282.5},{"x":392,"y":275.5},{"x":402,"y":264.5},{"x":413,"y":256.5},{"x":427,"y":250.5},{"x":436,"y":247.5},{"x":446,"y":247.5},{"x":458,"y":249.5},{"x":469,"y":255.5},{"x":476,"y":260.5},{"x":482,"y":270.5},{"x":486,"y":280.5},{"x":490,"y":294.5},{"x":491,"y":304.5},{"x":492,"y":318.5},{"x":492,"y":329.5},{"x":492,"y":339.5},{"x":492,"y":348.5},{"x":483,"y":339.5},{"x":477,"y":332.5},{"x":469,"y":323.5},{"x":463,"y":316.5},{"x":454,"y":307.5},{"x":445,"y":300.5},{"x":436,"y":293.5},{"x":428,"y":299.5},{"x":420,"y":305.5},{"x":413,"y":312.5},{"x":402,"y":323.5},{"x":394,"y":332.5},{"x":387,"y":341.5}],
							top:98,
							left:162,
						}
					]
				}
			},
			{
				"id": '4-3',
				"title": "สาบเสื้อ รูปแบบ 4 ลายที่ 3",
				"image": {
					"url": "./images/shirt_type/sleeve_style/1-2.png"
				},
				"canvas_obj": main_sleeve
			}
		]
	},
	{
		"id":5,
		"title": "รูปแบบ 5",
		"image": {
			"url": "./images/shirt_type/sleeve_style/5.png"
		},
		"type": [
			{
				"id": '5-1',
				"title": "สาบเสื้อ รูปแบบ 5 ลาย 1",
				"image": {
					"url": "./images/shirt_type/sleeve_style/5-1.png"
				},
				"canvas_obj": {
					"front": [
						{point:[{"x":923.5,"y":464},{"x":917.5,"y":468},{"x":909.5,"y":473},{"x":902.5,"y":477},{"x":893.5,"y":481},{"x":883.5,"y":485},{"x":874.5,"y":488},{"x":865.5,"y":490},{"x":855.5,"y":491},{"x":846.5,"y":492},{"x":836.5,"y":492},{"x":830.5,"y":492},{"x":830.5,"y":484},{"x":836.5,"y":484},{"x":844.5,"y":484},{"x":855.5,"y":483},{"x":865.5,"y":482},{"x":872.5,"y":480},{"x":882.5,"y":477},{"x":890.5,"y":473},{"x":899.5,"y":469},{"x":906.5,"y":465},{"x":913.5,"y":461},{"x":920.5,"y":456}],
							top:219,
							left:392,
						},
						{point:[{"x":828,"y":460.5},{"x":843,"y":460.5},{"x":861,"y":458.5},{"x":876,"y":454.5},{"x":887,"y":449.5},{"x":896,"y":444.5},{"x":904,"y":439.5},{"x":911,"y":434.5},{"x":916,"y":430.5},{"x":913,"y":421.5},{"x":907,"y":427.5},{"x":901,"y":432.5},{"x":893,"y":437.5},{"x":885,"y":441.5},{"x":873,"y":447.5},{"x":861,"y":450.5},{"x":844,"y":452.5},{"x":828,"y":452.5}],
							top:201,
							left:392,
						}
					],
					"back": [
						{point:[{"x":73.5,"y":489},{"x":79.5,"y":493},{"x":86.5,"y":498},{"x":92.5,"y":502},{"x":100.5,"y":507},{"x":107.5,"y":511},{"x":115.5,"y":515},{"x":124.5,"y":518},{"x":133.5,"y":520},{"x":145.5,"y":522},{"x":154.5,"y":523},{"x":161.5,"y":523},{"x":169.5,"y":522},{"x":176.5,"y":521},{"x":175.5,"y":513},{"x":167.5,"y":514},{"x":160.5,"y":515},{"x":152.5,"y":515},{"x":143.5,"y":514},{"x":134.5,"y":512},{"x":126.5,"y":510},{"x":119.5,"y":507},{"x":113.5,"y":504},{"x":105.5,"y":500},{"x":98.5,"y":496},{"x":92.5,"y":492},{"x":85.5,"y":487},{"x":77.5,"y":481}],
							top:230,
							left:11,
						},
						{point:[{"x":177,"y":490},{"x":168,"y":490},{"x":159,"y":490},{"x":149,"y":489},{"x":139,"y":487},{"x":130,"y":484},{"x":123,"y":481},{"x":116,"y":477},{"x":109,"y":473},{"x":103,"y":469},{"x":96,"y":465},{"x":90,"y":461},{"x":83,"y":456},{"x":86,"y":446},{"x":93,"y":452},{"x":100,"y":457},{"x":108,"y":462},{"x":114,"y":466},{"x":121,"y":470},{"x":128,"y":474},{"x":134,"y":477},{"x":142,"y":479},{"x":150,"y":481},{"x":159,"y":482},{"x":167,"y":482},{"x":176,"y":482}],
							top:212,
							left:18,
						}
					],
					"left": [
						{point:[{"x":468,"y":545},{"x":468,"y":536},{"x":584,"y":535},{"x":584,"y":544}],
							top:265,
							left:205,
						},
						{point:[{"x":468,"y":509},{"x":468,"y":500},{"x":584,"y":499},{"x":584,"y":508}],
							top:247,
							left:205,
						}
					],
					"right": []
				}
			},
			{
				"id": '4-3',
				"title": "สาบเสื้อ รูปแบบ 3 ลายที่ 2",
				"image": {
					"url": "./images/shirt_type/sleeve_style/1-2.png"
				},
				"canvas_obj": main_sleeve
			}
		]
	},
]