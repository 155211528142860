import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ColorBox from './ColorBox';

export default class Shirt extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
			choose_color: !!this.props.selected.customization.shirt_style.shirt_style_id,
			choose_style: this.props.selected.customization.shirt_style.shirt_style_id && this.props.selected.customization.shirt_style.type.length > 1,
		}
	}

	selectedStyle() {
		let style = this.state.product.shirt_style.filter((_style) => {
			return _style.id === this.state.selected.customization.shirt_style.shirt_style_id;
		});
		return style[0];
	}

	selectedEditType() {
		let _type = false;
		let shirt_type = this.state.selected.customization.shirt_style.type.filter((type) => {
			return type.selected === true
		});
		if (shirt_type.length > 0) {
			_type = shirt_type[0]
		}
		return _type;
	}

	shirtStyleSelect(shirt_style) {
		let selected = {...this.state.selected};
		let default_color_id = this.state.product.color[0].id;
		let default_color = this.state.product.color[0];
		if (this.state.selected.customization.shirt_style.type[0]) {
			default_color_id = this.state.selected.customization.shirt_style.type[0].color_id;
			default_color = this.state.selected.customization.shirt_style.type[0].color;
		}
		if (shirt_style === 'default') {
			selected.customization.shirt_style.shirt_style_id = 'default';
			selected.customization.shirt_style.shirt_style_title = false;
			selected.customization.shirt_style.type = [];
			selected.customization.shirt_style.type.push({
				type_id: 'default',
				color_id: default_color_id,
				color: default_color,
				selected: true,
			});
		} else {
			if (this.state.selected.customization.shirt_style.shirt_style_id !== shirt_style.id) {
				selected.customization.shirt_style.shirt_style_id = shirt_style.id;
				selected.customization.shirt_style.shirt_style_title = shirt_style.title;
				selected.customization.shirt_style.type = [];
				selected.customization.shirt_style.type.push({
					type_id: 'default',
					color_id: default_color_id,
					color: default_color,
					selected: true,
				});
				shirt_style.type.map((type) => {
					return selected.customization.shirt_style.type.push({
						type_id: type.id,
						color_id: this.state.product.color[0].id,
						color: this.state.product.color[0],
						url: type.image.url,
						canvas_obj: type.canvas_obj,
						selected: false,
					});
				});
			}
		}
		let choose_style = selected.customization.shirt_style.type.length > 1;
		this.setState({
			selected: selected,
			choose_color: true,
			choose_style: choose_style,
		}, () => {
			this.props.onUpdateSelected(this.state.selected);
		})
	}

	shirtStyleSelectType(_type) {
		let selected = {...this.state.selected};
		if (_type === 'default') {
			this.state.selected.customization.shirt_style.type.map((type, type_i) => {
				return selected.customization.shirt_style.type[type_i].selected = type.type_id === 'default';
			});
		} else {
			this.state.selected.customization.shirt_style.type.map((type, type_i) => {
				return selected.customization.shirt_style.type[type_i].selected = type.type_id === _type;
			});
		}
		this.setState({
			selected: selected,
			choose_color: true,
		}, () => {
			this.props.onUpdateSelected(this.state.selected);
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.selected.shirt_side !== this.props.selected.shirt_side) {
			this.setState({
				...this.props,
			})
		}
	}

	render() {
		return (
			<>
				{
					this.state.product
					&& !this.state.choose_style
					&& this.state.product.shirt_style.length > 0 ?
						<Slider {...this.props.slide_settings}>
							<div className={"shirt_slide_list"}>
								<div
									className={this.state.selected.customization.shirt_style.shirt_style_id === 'default' ?
										"ui centered link card active" : "ui centered link card"
									}
									onClick={() => {
										this.shirtStyleSelect('default');
									}}
								>
									<div className="image">
										<img src={this.state.product.base_shirt.shirt_url}
										     alt={"LANNA POLO"}/>
									</div>
									<div className="content">
										<div className="description centered">
											ค่าเริ่มต้น
										</div>
									</div>
								</div>
							</div>
							{
								this.state.product.shirt_style.map((shirt_style, shirt_style_i) => {
									return (
										<div key={shirt_style_i} className={"shirt_slide_list"}>
											<div
												className={this.state.selected.customization.shirt_style.shirt_style_id === shirt_style.id ?
													"ui centered link card active" : "ui centered link card"
												}
												onClick={() => {
													this.shirtStyleSelect(shirt_style);
												}}
											>
												<div className="image">
													<img src={shirt_style.image.url} alt={"LANNA POLO"}/>
												</div>
												<div className="content">
													<div className="description centered">
														{shirt_style.title}
													</div>
												</div>
											</div>
										</div>
									);
								})
							}
						</Slider>
						: null
				}
				{
					this.selectedStyle()
					&& this.state.choose_style ?
						<>
							<div className="field">
								<button className="ui button red"
								        type="button"
								        onClick={() => {
									        this.setState({
										        choose_style: false,
										        choose_color: false,
									        })
								        }}
								>
									<i className="arrow left icon"/>
									กลับ
								</button>
							</div>
							<Slider {...this.props.slide_settings}>
								<div className={"shirt_slide_list"}>
									<div
										className={this.selectedEditType().type_id === 'default' ? "ui centered link card active" : "ui centered link card"}
										onClick={() => {
											let selected = {...this.state.selected};
											this.state.selected.customization.shirt_style.type.map((type, type_i) => {
												return selected.customization.shirt_style.type[type_i].selected = type.type_id === 'default';
											});
											this.setState({
												selected: selected,
												choose_color: true,
											})
										}}
									>
										<div className="image">
											<img src={this.state.product.base_shirt.shirt_url}
											     alt={"LANNA POLO"}/>
										</div>
										<div className="content">
											<div className="description centered">
												{this.state.product.base_shirt.title}
											</div>
										</div>
									</div>
								</div>
								{
									this.selectedStyle().type.map((shirt_style, shirt_style_i) => {
										return (
											<div key={shirt_style_i} className={"shirt_slide_list"}>
												<div
													className={this.selectedEditType().type_id === shirt_style.id ? "ui centered link card active" : "ui centered link card"}
													onClick={() => {
														let selected = {...this.state.selected};
														this.state.selected.customization.shirt_style.type.map((type, type_i) => {
															return selected.customization.shirt_style.type[type_i].selected = type.type_id === shirt_style.id;
														});
														this.setState({
															selected: selected,
															choose_color: true,
														})
													}}
												>
													<div className="image">
														<img src={shirt_style.image.url}
														     alt={"LANNA POLO"}/>
													</div>
													<div className="content">
														<div className="description centered">
															{shirt_style.title}
														</div>
													</div>
												</div>
											</div>
										);
									})
								}
							</Slider>
						</>
						: null
				}
				{
					this.state.choose_color ?
						<ColorBox
							product={this.state.product}
							selected={this.state.selected}
							selected_type={this.selectedEditType()}
							onUpdateSelected={(color) => {
								let selected = {...this.state.selected};
								this.state.selected.customization.shirt_style.type.map((type, type_index) => {
									if (type.selected === true) {
										selected.customization.shirt_style.type[type_index].color_id = color.id;
										selected.customization.shirt_style.type[type_index].color = color;
									}
									return false;
								});
								this.setState({
									selected: selected,
								}, () => {
									this.props.onUpdateSelected(this.state.selected);
								})
							}}

						/>
						:
						null
				}
			</>
		);
	}
}

