import React from 'react';

export default class Login extends React.Component {
	render() {
		return (
			<>
				<div className="login-bg">
					<div className="ui middle aligned grid">
						<div className="column">
							<h2 className="ui teal image header" style={{textAlign: 'center', width: '100%'}}>
								<div className="content">
									<img src={'./logo192.png'}
									     alt="Lanna Polo Gallery"
									     style={{width: 100, margin: 'auto'}}
									/>
									Log-in to your account
								</div>
							</h2>
							<form className="ui large form"
							      ref={(ref) => {
								      window.$(ref).form2('setup');
								      window.$(ref).form2('submit', {
									      method: 'POST',
									      url: process.env.REACT_APP_DOMAIN + '/api/login',
									      // submitButton: $(ref).find('.approve.button'),
									      loadingButton: window.$(ref).find('.submit'),
									      beforeSent: (data) => {
										      // console.log(data);
										      return data;
									      },
									      onSuccess: (response) => {
										      console.log(response);
										      window.Global.messageTop({
											      type: 'positive',
											      message: 'เข้าสู่ระบบสำเร็จ'
										      });
									      }
								      });
							      }}
							>
								<div className="ui stacked segment">
									<div className="required field">
										<label>Username</label>
										<div className="ui left icon input">
											<i className="key icon"/>
											<input type="text" name="username"/>
										</div>
									</div>
									<div className="required field">
										<label>Password</label>
										<div className="ui left icon input">
											<i className="lock icon"/>
											<input type="password" name="password"/>
										</div>
									</div>
									<div className="ui error message"/>
									<button
										className="ui fluid large teal submit button"
										type={"submit"}
									>
										เข้าสู่ระบบ
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</>
		);
	}
}
