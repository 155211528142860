
export const pocket_data = [
	{
		"id":1,
		"title": "รูปแบบ 1",
		"image": {
			"url": "./images/POCKET_1.png"
		},
		"type": [
			{
				"id": '1-1',
				"title": "กระเป๋าเสื้อ รูปแบบ 1 ลาย 1",
				"image": {
					"url": "./images/POCKET_1.png"
				},
				"canvas_obj": {
					"front": [
						{point:[{"x":630.5,"y":360.5},{"x":699.5,"y":360.5},{"x":699.5,"y":429.5},{"x":665.5,"y":445.5},{"x":630.5,"y":429.5}],
							top:159,
							left:298,
						}
					],
					"back": [],
					"left": [
						{point:[{"x":309,"y":369},{"x":327,"y":370},{"x":324,"y":435},{"x":313,"y":455},{"x":305,"y":432}],
							top:163,
							left:147,
						}
					],
					"right": [

					]
				}
			}
		]
	},
];