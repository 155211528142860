import React from 'react';
import Slider from "react-slick";
export default class Menu extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			menu: [
				{title: 'หน้าแรก'},
				{title: 'เกี่ยวกับเรา'},
				{title: 'ข่าวสาร'},
				{title: 'บทความ'},
				{title: 'สินค้า'},
				{title: 'ดีไซน์เสื้อ'},
				{title: 'ติดต่อเรา'},
				{title: 'ยูทูป'},
				{title: 'กูเกิ้ล'},
				{title: 'เฟสบุ๊ค'},
				{title: 'ดูหนัง'},
				{title: 'ดูซีรี่'},
				{title: 'ฟุตบอล'},
				{title: 'บาสเก็ตบอล'},
				{title: 'อเมริกันฟุตบอล'},
				{title: 'กูเกิ้ล'},
				{title: 'เฟสบุ๊ค'},
				{title: 'ดูหนัง'},
				{title: 'ดูซีรี่'},
				{title: 'ฟุตบอล'},
				{title: 'บาสเก็ตบอล'},
				{title: 'อเมริกันฟุตบอล'},
			]
		}
	}


	render() {

		const settings = {
			dots: false,
			infinite: false,
			centerMode: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
		};
		return (
			<>
				<div className={"ui container"}>
					<div className={"menu-slider"}>
						<Slider {...settings}>
							{
								this.state.menu.map((menu, menu_i)=> {
									return (
										<div key={menu_i}>
											<a href={window.location.origin}
											   className={!window.Global.getAllQueryStrings().page && menu_i === 0 ? 'active' : null}
											>
												{menu.title}
												</a>
										</div>
									);
								})
							}
						</Slider>
					</div>
				</div>
			</>
		);
	}
}