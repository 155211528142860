import React from 'react';
import mediumZoom from 'medium-zoom';

export default class Gallery extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page_component: {
				...props.page_component,
			},
			before_page_component: {...props.page_component}
		}
	}


	UNSAFE_componentWillReceiveProps(props) {
		if (props.scroll) {
			props.scroll(this.view_parent);
		}
	}

	componentDidMount() {
		if (this.props.scroll) {
			this.props.scroll(this.view_parent);
		}
		this.setUpUploadFile();
		const zoom = mediumZoom('[data-zoomable]', {background: 'rgba(0,0,0,0.5)'});
		zoom.on('closed', () => {
			zoom.close();
		});
	}

	setUpUploadFile = () => {
		window.$(this.$parent).find('form').form2('setup');
	};

	renderView = () => {
		return (
			<>
				<div ref={(ref) => {
					this.view_parent = ref;
				}}
				     className="content-padded"
				     style={this.props.is_component_selected && this.props.page_component.mode !== 'add' && this.props.page_component.mode !== 'edit' ? {opacity: 0.3} : null}
				>
					<div className="ui container">
						<div className="ui grid">
							<div className="ui grid">
								{
									this.props.page_component.images
									&& this.props.page_component.images.length ?
										this.props.page_component.images.map((image, image_index) => {
											return (
												<div className="four wide column">
													<div className="image_frame">
														<img src={image.url}
														     alt="Lanna Polo Gallery"
														     key={image_index}
														     data-zoomable={true}
														/>
													</div>
												</div>
											);
										}) : null
								}
							</div>
						</div>

					</div>
				</div>
			</>
		);
	};

	render() {
		if (this.props.mode === 'view') {
			return this.renderView();
		}
		return (
			<>
				<div className="sidebar-padded" ref={(ref) => {
					this.$parent = ref;
				}}>
					<form className="ui form">
						<div className="field" style={{display: 'flex', flexDirection: 'row'}}>
							<label style={{flexShrink: 1}}>อัพโหลดรูปภาพ</label>
						</div>
						<div className="field">
							<div className="ui grid">
								{
									this.state.page_component.images
									&& this.state.page_component.images.length ?
										this.state.page_component.images.map((image, image_index) => {
											return (
												<div className="four wide column">
													<div className="image_frame"
													     onClick={() => {
														     let page_component = {...this.state.page_component};
														     page_component.images[image_index].checked = !this.state.page_component.images[image_index].checked;
														     this.setState({
															     page_component: page_component,
														     }, () => {
															     this.props.onChange(this.state.page_component);
														     })
													     }}
													>
														<div className="close_btn_pos">
															{
																image.checked ?
																	<i className="check square outline icon"/>
																	:
																	<i className="square outline icon"/>
															}
														</div>
														<img src={image.url}
														     alt="Lanna Polo Gallery"
														     key={image_index}
														/>
													</div>
												</div>
											);
										}) : null
								}
								<div className="four wide column">
									<div data-form2_setup="true"
									     data-form2_type="upload"
									     data-upload_type="single_image"
									     data-input_name="thumbnail_filename"
									     data-value={this.state.page_component.thumbnail_filename ? this.state.page_component.thumbnail_filename : null}
									     data-width="100%"
									     data-height="72px"
									     data-url="https://api-frontend-website.b49consultancy.com/api/file/upload">&nbsp;</div>
								</div>
							</div>
						</div>
						<div className="field row" style={{textAlign: 'right'}}>
							{
								this.state.page_component.images &&
								this.state.page_component.images.filter((image) => {
									return image.checked === true
								}).length > 0 ?
									<button className="ui button basic icon active"
									        type="button"
									        onClick={() => {
										        let confirm = window.confirm("ต้องการลบรูปภาพนี้ใช่หรือไม่ ?");
										        if (confirm) {
											        let page_component = {...this.state.page_component};
											        page_component.images = this.state.page_component.images.filter((image) => {
												        return image.checked === false
											        });
											        this.setState({
												        page_component: page_component
											        }, () => {
												        this.props.onChange(this.state.page_component);
											        })
										        }
										        return false;
									        }}
									>
										<i className="trash alternate icon"/>
									</button>
									: null
							}
							<div style={{marginLeft: 'auto'}}>
								<button className="ui button red"
								        type="button"
								        onClick={() => {
									        if (this.props.page_component.mode === 'add') {
										        this.props.removePageComponent();
										        return;
									        }
									        let page_component = {...this.state.before_page_component};
									        page_component.mode = 'card';
									        this.props.onChange(page_component);
									        this.props.showForm();
								        }}
								>
									ยกเลิก
								</button>
								<button className="ui button blue"
								        type="button"
								        onClick={() => {
									        let page_component = {...this.state.page_component};
									        page_component.mode = 'card';
									        this.props.onChange(page_component);
									        this.props.showForm();
								        }}
								>
									บันทึก
								</button>
							</div>
						</div>
					</form>
				</div>
			</>
		);
	}
}