import React from 'react';

export default class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page_component: {...props.page_component},
			before_page_component: {...props.page_component}
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.scroll) {
			props.scroll(this.view_parent);
		}
	}

	componentDidMount() {
		if (this.props.scroll) {
			this.props.scroll(this.view_parent);
		}
	}


	onChangeSelect(event) {
		let page_component = {...this.state.page_component};
		page_component[event.target.name] = event.target.value;
		this.setState({
			page_component: page_component
		}, () => {
			this.props.onChange(this.state.page_component);
		})

	}

	renderView = () => {
		console.log(this.props);
		return (
			<>
				<div className="content-padded"
				     ref={(ref) => {
					     this.view_parent = ref;
				     }}
				     style={this.props.is_component_selected && this.props.page_component.mode !== 'add' && this.props.page_component.mode !== 'edit' ? {opacity: 0.3} : null}
				>
					<div className="ui container">
						{
							this.props.page_component.font_type
							&& this.props.page_component.font_type === 'h1' ?
								<h1 style={{textAlign: this.props.page_component.alignment}}>
									{this.props.page_component.title}
								</h1>
								: null
						}
						{
							this.props.page_component.font_type
							&& this.props.page_component.font_type === 'h2' ?
								<h2 style={{textAlign: this.props.page_component.alignment}}>
									{this.props.page_component.title}
								</h2>
								: null
						}
						{
							this.props.page_component.font_type
							&& this.props.page_component.font_type === 'h3' ?
								<h3 style={{textAlign: this.props.page_component.alignment}}>
									{this.props.page_component.title}
								</h3>
								: null
						}
					</div>
				</div>
			</>
		);
	};

	render() {
		if (this.props.mode === 'view') {
			return this.renderView();
		}
		return (
			<>
				<div className="sidebar-padded" >
					<form className="ui form">
						<div className="fields">
							<div className="field" style={{width: '100%', flexShrink: 1}}>
								<label>ขนาดตัวหนังสือ</label>
								<select className="ui fluid dropdown"
								        name="font_type"
								        ref={(ref) => {
									        window.$(ref).dropdown();
								        }}
								        onChange={(e) => {
									        this.onChangeSelect(e);
								        }}
								        defaultValue={this.state.page_component.font_type || 'h1'}
								>
									<option value="h1">H1</option>
									<option value="h2">H2</option>
									<option value="h3">H3</option>
								</select>
							</div>
							<div className="field">
								<label>การจัดเรียง</label>
								<div className="ui basic icon buttons" style={{backgroundColor: 'white'}}>
									<button type="button"
									        ref={(ref) => {
										        window.$(ref).popup();
									        }}
									        data-content="ชิดซ้าย"
									        data-inverted=""
									        className={this.state.page_component.alignment === 'left' ? "ui button active" : "ui button"}
									        value="left"
									        onClick={() => {
										        this.onChangeSelect({target: {name: 'alignment', value: 'left'}});
									        }}
									>
										<i className="align left icon"/>
									</button>
									<button type="button"
									        ref={(ref) => {
										        window.$(ref).popup();
									        }}
									        className={this.state.page_component.alignment === 'center' ? "ui button active" : "ui button"}
									        value="center"
									        data-content="กลาง"
									        data-inverted=""
									        onClick={() => {
										        this.onChangeSelect({target: {name: 'alignment', value: 'center'}});
									        }}
									>
										<i className="align center icon"/>
									</button>
									<button type="button"
									        ref={(ref) => {
										        window.$(ref).popup();
									        }}
									        className={this.state.page_component.alignment === 'right' ? "ui button active" : "ui button"}
									        value="right"
									        data-content="ชิดขวา"
									        data-inverted=""
									        onClick={() => {
										        this.onChangeSelect({target: {name: 'alignment', value: 'right'}});
									        }}
									>
										<i className="align right icon"/>
									</button>
								</div>
								{/*<select className="ui fluid dropdown"*/}
								{/*name="alignment"*/}
								{/*ref={(ref) => {*/}
								{/*window.$(ref).dropdown();*/}
								{/*}}*/}
								{/*onChange={(e) => {*/}
								{/*this.onChangeSelect(e);*/}
								{/*}}*/}
								{/*defaultValue={this.state.page_component.alignment || 'left'}*/}
								{/*>*/}
								{/*<option value="">ประเภทตัวหนังสือ</option>*/}
								{/*<option value="left">*/}
								{/*ชิดซ้าย*/}
								{/*</option>*/}
								{/*<option value="center">*/}
								{/*กลาง*/}
								{/*</option>*/}
								{/*<option value="right">*/}
								{/*ชิดขวา*/}
								{/*</option>*/}
								{/*</select>*/}
							</div>
						</div>
						<div className="field">
							<label>ข้อความ</label>
							<textarea name="title" rows={3} defaultValue={this.state.page_component.title || 'หัวข้อ'}
							          onChange={(e) => {
								          this.onChangeSelect(e);
							          }}/>
						</div>
						<div className="field" style={{textAlign: 'right'}}>
							<button className="ui button red"
							        type="button"
							        onClick={() => {
								        if (this.props.page_component.mode === 'add') {
									        this.props.removePageComponent();
									        return;
								        }
								        let page_component = {...this.state.before_page_component};
								        page_component.mode = 'card';
								        this.props.onChange(page_component);
								        this.props.showForm();
							        }}
							>
								ยกเลิก
							</button>
							<button className="ui button blue"
							        type="button"
							        onClick={() => {
								        let page_component = {...this.state.page_component};
								        page_component.mode = 'card';
								        this.props.onChange(page_component);
								        this.props.showForm();
							        }}
							>
								{this.props.page_component.mode === 'add' ? "เพิ่ม" : null}
								{this.props.page_component.mode === 'edit' ? "แก้ไข" : null}
								{this.props.page_component.mode !== 'edit' && this.props.page_component.mode !== 'add' ? "บันทึก" : null}
							</button>
						</div>
					</form>
				</div>
			</>
		);
	}
}