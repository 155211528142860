import React from 'react';

export default class UploadImage extends React.Component {
	render() {
		return (
			<>
				<div className="field">
					<div
						ref={(ref) => {
							window.Global.makeUploadButton({
								uploadFile: {
									css: {
										width: "100%",
										height: '230px'
									},
									input: {
										name: 'upload_image',
										// url: '',
										// value: ''
									},
									textOnButton: "อัพโหลดรูป",
									isGallery: false,
								},
								element: window.$(ref),
								url: process.env.REACT_APP_DOMAIN + "/api/file/upload",
								onUploadDone: (data)=> {
									let file = data._response.result.files[0];
									file.shirt_side = this.props.selected.shirt_side;
									let selected = {...this.props.selected};
									selected.upload_images.push(file);
									this.props.onUpdateSelected(selected);
								}
							})
						}}
					>&nbsp;</div>
				</div>
				<div className="field">
					<span>ประเภทโลโก : </span><strong>PNG file only maximum 1MB.</strong>
				</div>
			</>
		);
	}
}