import React from 'react';

export default class List extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page_component: {
				...props.page_component,
				list: [
					{
						image: {
							thumbnail: './logo192.png',
							url: './logo512.png',
						},
						title: 'The 2020s Will Be the Decade of the Bioeconomy',
						description: '',
						date: 'Jan 17',
					},
					{
						image: {
							thumbnail: './logo192.png',
							url: './logo512.png',
						},
						title: 'The 2020s Will Be the Decade of the Bioeconomy',
						description: '',
						date: 'Jan 17',
					},
					{
						image: {
							thumbnail: './logo192.png',
							url: './logo512.png',
						},
						title: 'The 2020s Will Be the Decade of the Bioeconomy',
						description: '',
						date: 'Jan 17',
					},
					{
						image: {
							thumbnail: './logo192.png',
							url: './logo512.png',
						},
						title: 'The 2020s Will Be the Decade of the Bioeconomy',
						description: '',
						date: 'Jan 17',
					},
				]
			},
			before_page_component: {...props.page_component}
		}
	}


	UNSAFE_componentWillReceiveProps(props) {
		if (props.scroll) {
			props.scroll(this.view_parent);
		}
	}

	componentDidMount() {
		if (this.props.scroll) {
			this.props.scroll(this.view_parent);
		}
	}

	renderView = () => {
		return (
			<>
				<div className="content-padded"
				     style={this.props.is_component_selected && this.props.page_component.mode !== 'add' && this.props.page_component.mode !== 'edit' ? {opacity: 0.3} : null}
				>
					<div className="ui container">
						<div className="flex row wrap padded list_card">
							{
								this.state.page_component.list.map((list, list_index) => {
									return (
										<div className="col-xs-16 col-lg-5-3 column padded" key={list_index}>
											<div className="flex row">
												<div className="col-lg-12 list_details">
													<div className="title">
														{list.title}
													</div>
													<div className="date">
														{list.date}
													</div>
												</div>
												<div className="col-lg-4">
													<img src={list.image.thumbnail}
													     className={"list_img"}
													     alt="Lanna Polo List"
													/>
												</div>
											</div>
										</div>
									);
								})
							}
						</div>

					</div>
				</div>
			</>
		);
	};

	render() {
		if (this.props.mode === 'view') {
			return this.renderView();
		}
		return (
			<>
				<div className="edit-title flex row middle-xs">
					<div className="right floated">
						<button className={"ui button mini"}
						        onClick={() => {
							        let _confirm = window.confirm("ยืนยันการลบข้อมูล");
							        if (_confirm) {
								        let page_component = {...this.state.before_page_component};
								        page_component.mode = 'card';
								        this.props.onChange(page_component);
								        this.props.removePageComponent();
								        return true;
							        }
							        return false
						        }}
						>
							ลบ
						</button>
					</div>
				</div>
				<div className="padded" ref={(ref) => {
					this.$parent = ref;
				}}>
					<form className="ui form">
						<div className="field row" style={{textAlign: 'right'}}>
							{
								this.state.page_component.images &&
								this.state.page_component.images.filter((image) => {
									return image.checked === true
								}).length > 0 ?
									<button className="ui button basic icon active"
									        type="button"
									        onClick={() => {
										        let confirm = window.confirm("ต้องการลบรูปภาพนี้ใช่หรือไม่ ?");
										        if (confirm) {
											        let page_component = {...this.state.page_component};
											        page_component.images = this.state.page_component.images.filter((image) => {
												        return image.checked === false
											        });
											        this.setState({
												        page_component: page_component
											        }, () => {
												        this.props.onChange(this.state.page_component);
											        })
										        }
										        return false;
									        }}
									>
										<i className="trash alternate icon"/>
									</button>
									: null
							}
							<div style={{marginLeft: 'auto'}}>
								<button className="ui button red"
								        type="button"
								        onClick={() => {
									        let page_component = {...this.state.before_page_component};
									        page_component.mode = 'card';
									        this.props.onChange(page_component);
									        this.props.showForm();
								        }}
								>
									ยกเลิก
								</button>
								<button className="ui button blue"
								        type="button"
								        onClick={() => {
									        let page_component = {...this.state.page_component};
									        page_component.mode = 'card';
									        this.props.onChange(page_component);
									        this.props.showForm();
								        }}
								>
									บันทึก
								</button>
							</div>
						</div>
					</form>
				</div>
			</>
		);
	}
}