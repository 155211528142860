import React from 'react';

export default class ColorBox extends React.Component {
	render() {
		return (
			<div className="color-box">
				{
					this.props.product.color
					&& this.props.product.color.length > 0 ?
						this.props.product.color.map((color, color_i) => {
							return (
								<div style={{backgroundColor: color.hex_code}}
								     className={this.props.selected_type.color_id === color.id ? 'selected' : null}
								     key={color_i}
								     onClick={() => {
									     this.props.onUpdateSelected(color);
								     }}
								/>
							);
						})
						: null
				}
			</div>
		);
	}
}