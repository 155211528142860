import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ColorBox from "./ColorBox";

export default class Collar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
			choose_color: true,
			choose_style: this.props.selected.customization.placket_style.placket_style_id,
			edit_shirt: false,
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.selected.shirt_side !== this.props.selected.shirt_side) {
			this.setState({
				...this.props,
			})
		}
	}

	selectedStyle() {
		let style = this.state.product.placket_style.filter((_style) => {
			return _style.id === this.state.selected.customization.placket_style.placket_style_id;
		});
		return style[0];
	}

	selectedEditType() {
		let _type = false;
		let shirt_type = false;
		if (this.state.edit_shirt) {
			shirt_type = this.state.selected.customization.shirt_style.type.filter((type) => {
				return type.type_id === 'default'
			});
		}
		if (!this.state.edit_shirt) {
			shirt_type = this.state.selected.customization.placket_style.type.filter((type) => {
				return type.selected === true
			});
		}
		if (shirt_type.length > 0) {
			_type = shirt_type[0]
		}
		return _type;
	}

	placketSelectedStyle(placket_style) {
		let selected = {...this.state.selected};
		this.state.selected.customization.placket_style.type.map((type, type_i) => {
			return selected.customization.placket_style.type[type_i].selected = type.type_id === placket_style.id;
		});
		this.setState({
			selected: selected,
			choose_color: true,
		})
	}

	render() {
		let settings = this.props.slide_settings;
		return (
			<>
				{
					this.state.product
					&& !this.state.choose_style
					&& this.state.product.placket_style.length > 0 ?
						<Slider {...settings}>
							<div className={"shirt_slide_list"}>
								<div
									className={!this.state.selected.customization.placket_style.placket_style_id ? "ui centered link card active" : "ui centered link card"}
									onClick={() => {
										let selected = {...this.state.selected};
										selected.customization.placket_style.placket_style_id = false;
										selected.customization.placket_style.placket_style_title = false;
										selected.customization.placket_style.type = [];
										this.setState({
											selected: selected,
											choose_color: true,
											edit_shirt: true,
										}, () => {
											this.props.onUpdateSelected(this.state.selected);
										})
									}}
								>
									<div className="image">
										<img src={this.state.product.base_shirt.placket_url}
										     alt={"LANNA POLO"}/>
									</div>
									<div className="content">
										<div className="description centered">
											{this.state.product.base_shirt.title}
										</div>
									</div>
								</div>
							</div>
							{
								this.state.product.placket_style.map((placket_style, placket_style_i) => {
									return (
										<div key={placket_style_i} className={"shirt_slide_list"}>
											<div
												className={this.state.selected.customization.placket_style.placket_style_id === placket_style.id ? "ui centered link card active" : "ui centered link card"}
												onClick={() => {
													let selected = {...this.state.selected};
													let default_color_id = this.state.product.color[0].id;
													let default_color = this.state.product.color[0];
													if (this.state.selected.customization.placket_style.placket_style_id !== placket_style.id) {
														selected.customization.placket_style.placket_style_id = placket_style.id;
														selected.customization.placket_style.placket_style_title = placket_style.title;
														selected.customization.placket_style.type = [];
														placket_style.type.map((type, type_index) => {
															return selected.customization.placket_style.type.push({
																type_id: type.id,
																color_id: default_color_id,
																color: default_color,
																selected: type_index === 0,
																canvas_obj: type.canvas_obj,
															});
														});
													}
													let choose_style = selected.customization.placket_style.type.length > 0;
													this.setState({
														selected: selected,
														choose_color: true,
														edit_shirt: false,
														choose_style: choose_style,
													}, () => {
														this.props.onUpdateSelected(this.state.selected);
													})
												}}
											>
												<div className="image">
													<img src={placket_style.image.url} alt={"LANNA POLO"}/>
												</div>
												<div className="content">
													<div className="description centered">
														{placket_style.title}
													</div>
												</div>
											</div>
										</div>
									);
								})
							}
						</Slider>
						: null
				}
				{
					this.selectedStyle()
					&& this.state.choose_style ?
						<>
							<div className="field">
								<button className="ui button red"
								        type="button"
								        onClick={() => {
									        this.setState({
										        choose_style: false,
										        choose_color: false,
									        })
								        }}
								>
									<i className="arrow left icon"/>
									กลับ
								</button>
							</div>
							<div className="field">
								<Slider {...settings}>
									{
										this.selectedStyle().type.map((placket_style, placket_style_i) => {
											return (
												<div key={placket_style_i} className={"shirt_slide_list column"}>
													<div
														className={this.selectedEditType().type_id === placket_style.id ? "ui centered link card active" : "ui centered link card"}
														onClick={() => {
															this.placketSelectedStyle(placket_style);
														}}
													>
														<div className="image">
															<img src={placket_style.image.url}
															     alt={"LANNA POLO"}/>
														</div>
														<div className="content">
															<div className="description centered">
																{placket_style.title}
															</div>
														</div>
													</div>
												</div>
											);
										})
									}
								</Slider>
							</div>

						</>
						: null
				}
				{
					this.state.choose_color ?
						<ColorBox
							product={this.state.product}
							selected={this.state.selected}
							selected_type={this.selectedEditType()}
							onUpdateSelected={(color) => {
								let selected = {...this.state.selected};
								if (this.state.edit_shirt) {
									if (selected.customization.shirt_style.type.length === 0) {
										selected.customization.shirt_style.type.push({
											type_id: 'default',
											color_id: color.id,
											color: color,
											selected: true,
										})
									}
									selected.customization.shirt_style.type[0].color_id = color.id;
									selected.customization.shirt_style.type[0].color = color;
								}
								if (!this.state.edit_shirt) {
									this.state.selected.customization.placket_style.type.map((type, type_index) => {
										if (type.selected === true) {
											selected.customization.placket_style.type[type_index].color_id = color.id;
											selected.customization.placket_style.type[type_index].color = color;
										}
										return null;
									});
								}
								this.setState({
									selected: selected,
								}, () => {
									this.props.onUpdateSelected(this.state.selected);
								})
							}}

						/>
						:
						null
				}
			</>
		);
	}
}

