import React from 'react';

export default class Content extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page_component: {...props.page_component},
			before_page_component: {...props.page_component}
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.scroll) {
			props.scroll(this.view_parent);
		}
	}

	componentDidMount() {
		if (this.state.page_component.image_type && this.state.page_component.image_type === 'image') {
			this.setUpUploadFile();
		}
		if (this.props.scroll) {
			this.props.scroll(this.view_parent);
		}
	}

	setUpUploadFile = () => {
		window.$(this.$parent).find('form').form2('setup');
	};

	onChangeSelect(event) {
		let page_component = {...this.state.page_component};
		page_component[event.target.name] = event.target.value;
		if (event.target.name === 'is_content' && event.target.value === 'show') {
			page_component.description = 'รายละเอียด'
		}
		// if(event.target.name === 'is_content' && event.target.value === 'none'){
		// 	page_component.description = ''
		// }
		this.setState({
			page_component: page_component
		}, () => {
			this.props.onChange(this.state.page_component);
			if (this.state.page_component.image_type && this.state.page_component.image_type === 'image') {
				this.setUpUploadFile();
			}
		})
	}

	renderView = () => {
		let order = 0;
		if (this.props.page_component.image_position === 'right') {
			order = 1;
		}
		return (
			<>
				<div ref={(ref) => {
					this.view_parent = ref;
				}}
				     className="content-padded"
				     style={this.props.is_component_selected && this.props.page_component.mode !== 'add' && this.props.page_component.mode !== 'edit' ? {opacity: 0.3} : null}
				>
					<div className="ui container">
						<div className="flex row center-xs">
							{
								this.props.page_component.image_type !== 'none' ?
									<div className="center-xs middle-xs"
									     style={{width: '100%', flexShrink: 1, display: 'flex', order: order}}>
										{
											this.props.page_component.image ?
												<div className="image_content_frame">
													<img src={this.props.page_component.image.url} alt={"Lanna Polo"}/>
												</div>
												: null
										}
									</div> :
									null
							}
							{
								this.props.page_component.is_content !== 'none' ?
									<div style={{width: '100%', flexShrink: 1, display: 'flex'}}>
										<div style={{textAlign: this.props.page_component.alignment, width: '100%'}}>
											{this.props.page_component.description}
										</div>
									</div> :
									null
							}
						</div>
					</div>
				</div>
			</>
		);
	};

	render() {
		if (this.props.mode === 'view') {
			return this.renderView();
		}
		return (
			<>
				<div className="sidebar-padded" ref={(ref) => {
					this.$parent = ref;
				}}>
					<form className="ui form">
						<h5 className="ui dividing header">
							รูปภาพ
						</h5>
						<div className="two fields">
							<div className="field" style={{width: '100%', flexShrink: 1}}>
								<label>รูปแบบ</label>
								<select className="ui fluid dropdown"
								        name="image_type"
								        ref={(ref) => {
									        window.$(ref).dropdown();
								        }}
								        onChange={(e) => {
									        this.onChangeSelect(e);
								        }}
								        defaultValue={this.state.page_component.image_type || 'none'}
								>
									<option value="none">ไม่มีรูป</option>
									<option value="image">มีรูป</option>
									<option value="video">มีวีดีโอ</option>
								</select>
							</div>
							{
								this.state.page_component.image_type
								&& this.state.page_component.image_type !== 'none'
								&& this.state.page_component.is_content
								&& this.state.page_component.is_content === 'show' ?
									<div className="field">
										<label>การจัดเรียง</label>
										<div className="ui basic icon buttons" style={{backgroundColor: 'white'}}>
											<button type="button"
											        ref={(ref) => {
												        window.$(ref).popup();
											        }}
											        data-content="ชิดซ้าย"
											        data-inverted=""
											        className={this.state.page_component.image_position === 'left' ? "ui button active" : "ui button"}
											        value="left"
											        onClick={() => {
												        this.onChangeSelect({
													        target: {
														        name: 'image_position',
														        value: 'left'
													        }
												        });
											        }}
											>
												<i className="align left icon"/>
											</button>
											<button type="button"
											        ref={(ref) => {
												        window.$(ref).popup();
											        }}
											        className={this.state.page_component.image_position === 'right' ? "ui button active" : "ui button"}
											        value="right"
											        data-content="ชิดขวา"
											        data-inverted=""
											        onClick={() => {
												        this.onChangeSelect({
													        target: {
														        name: 'image_position',
														        value: 'right'
													        }
												        });
											        }}
											>
												<i className="align right icon"/>
											</button>
										</div>
										{/*<select className="ui fluid dropdown"*/}
										{/*name="image_position"*/}
										{/*ref={(ref) => {*/}
										{/*window.$(ref).dropdown();*/}
										{/*}}*/}
										{/*onChange={(e) => {*/}
										{/*this.onChangeSelect(e);*/}
										{/*}}*/}
										{/*defaultValue={this.state.page_component.image_position || 'left'}*/}
										{/*>*/}
										{/*<option value="left">ชิดซ้าย</option>*/}
										{/*<option value="right">ชิดขวา</option>*/}
										{/*</select>*/}
									</div>
									: null
							}
						</div>
						{
							this.state.page_component.image_type
							&& this.state.page_component.image_type === 'image' ?
								<div className="field">
									<label>อัพโหลดรูปภาพ</label>
									<div style={{marginBottom: '10px'}}
									     data-form2_setup="true"
									     data-form2_type="upload"
									     data-upload_type="single_image"
									     data-input_name="thumbnail_filename"
									     data-value={this.state.page_component.thumbnail_filename ? this.state.page_component.thumbnail_filename : null}
									     data-width="100%"
									     data-height="250px"
									     data-url="https://api-frontend-website.b49consultancy.com/api/file/upload">&nbsp;</div>
									{/*<span>รูปภาพควรมีขนาด กว้าง 320/สูง 220 พิกเซล</span>*/}
								</div>
								: null
						}
						{
							this.state.page_component.image_type
							&& this.state.page_component.image_type === 'video' ?
								<div className="field">
									<label>Youtube Link</label>
									<textarea name="video" rows={3} defaultValue={this.state.page_component.video || ''}
									          onChange={(e) => {
										          this.onChangeSelect(e);
									          }}/>
								</div>
								: null
						}

						<h5 className="ui dividing header">
							เนื้อหา
						</h5>
						<div className="fields">
							<div className="field" style={{width: '100%', flexShrink: 1}}>
								<label>รูปแบบ</label>
								<select className="ui fluid dropdown"
								        name="is_content"
								        ref={(ref) => {
									        window.$(ref).dropdown();
								        }}
								        onChange={(e) => {
									        this.onChangeSelect(e);
								        }}
								        defaultValue={this.state.page_component.is_content || 'none'}
								>
									<option value='none'>ไม่มีเนื้อหา</option>
									<option value='show'>มีเนื้อหา</option>
								</select>
							</div>
							{
								this.state.page_component.is_content && this.state.page_component.is_content === 'show' ?
									<div className="field">
										<label>การจัดเรียง</label>
										<div className="ui basic icon buttons" style={{backgroundColor: 'white'}}>
											<button type="button"
											        ref={(ref) => {
												        window.$(ref).popup();
											        }}
											        data-content="ชิดซ้าย"
											        data-inverted=""
											        className={this.state.page_component.alignment === 'left' ? "ui button active" : "ui button"}
											        value="left"
											        onClick={() => {
												        this.onChangeSelect({
													        target: {
														        name: 'alignment',
														        value: 'left'
													        }
												        });
											        }}
											>
												<i className="align left icon"/>
											</button>
											<button type="button"
											        ref={(ref) => {
												        window.$(ref).popup();
											        }}
											        className={this.state.page_component.alignment === 'center' ? "ui button active" : "ui button"}
											        value="center"
											        data-content="กลาง"
											        data-inverted=""
											        onClick={() => {
												        this.onChangeSelect({
													        target: {
														        name: 'alignment',
														        value: 'center'
													        }
												        });
											        }}
											>
												<i className="align center icon"/>
											</button>
											<button type="button"
											        ref={(ref) => {
												        window.$(ref).popup();
											        }}
											        className={this.state.page_component.alignment === 'right' ? "ui button active" : "ui button"}
											        value="right"
											        data-content="ชิดขวา"
											        data-inverted=""
											        onClick={() => {
												        this.onChangeSelect({
													        target: {
														        name: 'alignment',
														        value: 'right'
													        }
												        });
											        }}
											>
												<i className="align right icon"/>
											</button>
										</div>
									</div>
									: null
							}
						</div>
						{
							this.state.page_component.is_content && this.state.page_component.is_content === 'show' ?
								<div className="field">
									<label>ข้อความ</label>
									<textarea name="description" rows={3}
									          defaultValue={this.state.page_component.description || ''}
									          onChange={(e) => {
										          this.onChangeSelect(e);
									          }}/>
								</div>
								: null
						}

						<div className="field" style={{textAlign: 'right'}}>
							<button className="ui button red"
							        type="button"
							        onClick={() => {
								        if (this.props.page_component.mode === 'add') {
									        this.props.removePageComponent();
									        return;
								        }
								        let page_component = {...this.state.before_page_component};
								        page_component.mode = 'card';
								        this.props.onChange(page_component);
								        this.props.showForm();
							        }}
							>
								ยกเลิก
							</button>
							<button className="ui button blue"
							        type="button"
							        onClick={() => {
								        let page_component = {...this.state.page_component};
								        page_component.mode = 'card';
								        this.props.onChange(page_component);
								        this.props.showForm();
							        }}
							>
								บันทึก
							</button>
						</div>
					</form>
				</div>
			</>

		);
	}

}