import React from 'react';
import {SketchPicker} from "react-color";

export default class FabricTextureColors extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fabric_texture_colors: props.fabric_texture_colors || [],
			is_show_color_picker: false,
			edit_color_index: false,
			edit_color: false,
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		console.log(this.props);
	}

	render() {
		return (
			<>
				<label>สี
					{this.state.is_show_color_picker && this.state.mode === 'edit' ? "แก้ไข" : null}
					{this.state.is_show_color_picker && this.state.mode === 'create' ? "เพิ่มสี" : null}
				</label>
				{
					!this.state.is_show_color_picker
					&& this.state.fabric_texture_colors ?
						<div className={"color-box"} style={{justifyContent: 'flex-start'}}>
							{
								this.state.fabric_texture_colors.map((color, color_i) => {
									if (!color.is_delete) {
										return (
											<div style={{
												backgroundColor: color.hex_code,
												border: '2px solid ' + color.hex_code
											}}
											     className={"ui dropdown pointing "}
											     key={color_i}
											     ref={(ref) => {
												     window.$(ref).dropdown()
											     }}
											>
												&nbsp;
												<div className="menu">
													<div className="item"
													     onClick={() => {
														     this.setState({
															     is_show_color_picker: true,
															     edit_color: color,
															     edit_color_index: color_i,
															     mode: 'edit'
														     }, () => {
															     this.props.hideSubmitButton();
														     })
													     }}
													>แก้ไข
													</div>
													<div className="item"
													     onClick={() => {
														     let fabric_texture_colors = [...this.state.fabric_texture_colors];
														     fabric_texture_colors[color_i].is_delete = 1;
														     this.setState({
															     fabric_texture_colors: fabric_texture_colors
														     })
													     }}
													>ลบ
													</div>
												</div>
												{
													color.id ?
														<input type={'hidden'} name={"fabric_texture_colors["+color_i+"][fabric_texture_color_id]"} defaultValue={color.id}/>
														: null
												}
												{
													color.name ?
														<input type={'hidden'} name={"fabric_texture_colors["+color_i+"][name]"} defaultValue={color.name}/>
														: null
												}
												{
													color.hex_code ?
														<input type={'hidden'} name={"fabric_texture_colors["+color_i+"][hex_code]"} defaultValue={color.hex_code}/>
														: null
												}
												{
													color.is_delete ?
														<input type={'hidden'} name={"fabric_texture_colors["+color_i+"][is_delete]"} defaultValue={color.is_delete}/>
														: null
												}
											</div>
										)
									}
									return (
										<>
											{
												color.id ?
													<input type={'hidden'} name={"fabric_texture_colors["+color_i+"][fabric_texture_color_id]"} defaultValue={color.id}/>
													: null
											}
											{
												color.name ?
													<input type={'hidden'} name={"fabric_texture_colors["+color_i+"][name]"} defaultValue={color.name}/>
													: null
											}
											{
												color.hex_code ?
													<input type={'hidden'} name={"fabric_texture_colors["+color_i+"][hex_code]"} defaultValue={color.hex_code}/>
													: null
											}
											{
												color.is_delete ?
													<input type={'hidden'} name={"fabric_texture_colors["+color_i+"][is_delete]"} defaultValue={color.is_delete}/>
													: null
											}
										</>
									);
								})
							}

							<div
								onClick={() => {
									this.setState({
										is_show_color_picker: true,
										edit_color: false,
										edit_color_index: false,
										mode: 'create'
									}, () => {
										this.props.hideSubmitButton();
									})
								}}
								ref={(ref) => {
									window.$(ref).popup();
								}}
								data-content={"เพิ่มสี"}
							>
								<i className={"plus icon"} style={{margin: 0, lineHeight: 1, opacity: 1}}/>
							</div>
						</div>
						: null
				}
				{
					this.state.is_show_color_picker ?
						<div style={{display: 'flex', flexDirection: 'row'}}>
							<div>
								<div style={{marginTop: 10}}>
									<SketchPicker
										color={this.state.edit_color ? this.state.edit_color.hex_code : '#000000'}
										onChangeComplete={(color) => {
											console.log(color);
											let edit_color = {...this.state.edit_color};
											edit_color.hex_code = color.hex;
											this.setState({
												edit_color: edit_color,
											})
										}}
									/>
								</div>
								<div style={{display: 'flex', flexDirection: 'row', marginTop: 10,}}>
									<div>
										<button type={"button"}
										        className={"ui button basic small"}
										        onClick={() => {
											        this.setState({
												        is_show_color_picker: false,
												        edit_color: false,
												        mode: false
											        }, () => {
												        this.props.showSubmitButton();
											        })
										        }}
										>
											ยกเลิก
										</button>
									</div>
									<div className={"right floated"}>
										<button className={"ui button basic small blue "}
											onClick={()=> {
												let fabric_texture_colors = [...this.state.fabric_texture_colors];
												if (this.state.mode === 'create') {
													fabric_texture_colors.push({hex_code: this.state.edit_color.hex_code});
												}
												if (this.state.mode === 'edit') {
													fabric_texture_colors[this.state.edit_color_index].hex_code =  this.state.edit_color.hex_code;
												}
												this.setState({
													fabric_texture_colors: fabric_texture_colors,
													is_show_color_picker: false
												}, () => {
													this.props.showSubmitButton(this.state.fabric_texture_colors);
												});
											}}
										>
											บันทึก
										</button>
									</div>
								</div>
							</div>
						</div>
						: null
				}
			</>
		);
	}


}