export const placket_data = [
	{
		"id": 1,
		"title": "รูปแบบ 1",
		"image": {
			"url": "./images/shirt_type/placket_style/1.png"
		},
		"type": [
			{
				"id": 1,
				"title": "สาบเสื้อ รูปแบบ 1 ลายที่ 1",
				"image": {
					"url": "./images/shirt_type/placket_style/1.png"
				},
				"canvas_obj": {
					"front": [
						{point:[{"x":446.125,"y":194.625},{"x":448.125,"y":193.625},{"x":450.375,"y":193.875},{"x":453.375,"y":195.875},{"x":457.375,"y":198.875},{"x":460.375,"y":201.875},{"x":464.375,"y":205.875},{"x":467.375,"y":208.875},{"x":470.375,"y":211.875},{"x":472.375,"y":214.875},{"x":474.375,"y":217.875},{"x":477.375,"y":221.875},{"x":478.375,"y":223.875},{"x":479.375,"y":225.875},{"x":478.375,"y":228.875},{"x":477.375,"y":232.875},{"x":476.375,"y":235.875},{"x":475.375,"y":238.875},{"x":474.375,"y":242.875},{"x":473.375,"y":246.875},{"x":473.375,"y":250.875},{"x":473.375,"y":254.875},{"x":473.375,"y":258.875},{"x":473.375,"y":262.875},{"x":473.375,"y":265.875},{"x":473.375,"y":270.875},{"x":473.375,"y":274.875},{"x":473.375,"y":278.875},{"x":473.375,"y":282.875},{"x":473.375,"y":286.875},{"x":473.375,"y":290.875},{"x":473.375,"y":294.875},{"x":473.375,"y":298.875},{"x":472.375,"y":300.875},{"x":470.375,"y":300.875},{"x":469.375,"y":299.875},{"x":469.375,"y":295.875},{"x":469.375,"y":290.875},{"x":469.375,"y":286.875},{"x":469.375,"y":282.875},{"x":469.375,"y":277.875},{"x":469.375,"y":274.875},{"x":469.375,"y":270.875},{"x":469.375,"y":268.875},{"x":469.375,"y":266.875},{"x":469.375,"y":262.875},{"x":467.375,"y":253.875},{"x":465.375,"y":246.875},{"x":461.375,"y":238.875},{"x":457.375,"y":231.875},{"x":451.375,"y":222.875},{"x":444.375,"y":215.875},{"x":436.375,"y":208.875},{"x":429.125,"y":204.625},{"x":433.125,"y":202.625},{"x":438.125,"y":199.625},{"x":441.125,"y":197.625}],
							top:70,
							left:202,
						},
						{point:[{"x":514,"y":245.5},{"x":515,"y":241.5},{"x":516.25,"y":235.75},{"x":517.25,"y":232.75},{"x":519.25,"y":227.75},{"x":521.25,"y":223.75},{"x":523.25,"y":219.75},{"x":525.25,"y":215.75},{"x":527.25,"y":211.75},{"x":529.25,"y":208.75},{"x":532.25,"y":203.75},{"x":534.25,"y":200.75},{"x":537.25,"y":196.75},{"x":540.25,"y":193.75},{"x":543.25,"y":190.75},{"x":548.25,"y":186.75},{"x":552,"y":184.5},{"x":558,"y":182.5},{"x":553,"y":180.5},{"x":549,"y":176.5},{"x":546,"y":178.5},{"x":543,"y":180.5},{"x":539,"y":183.5},{"x":536,"y":186.5},{"x":533,"y":189.5},{"x":530,"y":193.5},{"x":527,"y":197.5},{"x":524,"y":201.5},{"x":522,"y":204.5},{"x":520.25,"y":207.75},{"x":519,"y":211.5},{"x":517,"y":218.5},{"x":515,"y":224.5},{"x":514,"y":230.5},{"x":514,"y":234.5}],
							top:71,
							left:246,
						}
					],
					"back": [],
					"left":  [],
					"right":  []
				}
			}
		]
	},
]